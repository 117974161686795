import React from "react";
import { SvgProps } from "react-native-svg";
import { View, StyleSheet, ViewStyle } from "react-native";

interface SquareSvgProps extends SvgProps {
    size: number;
    Icon: React.ComponentType<SvgProps>;
}

const SquareSvg = ({ size, Icon, ...svgProps }: SquareSvgProps) => {
    return (
        <View style={
            [styles.container, dynamicStyles.container(size)]
        }>
            <Icon width={size} height={size} {...svgProps}></Icon>
        </View>
    );
};

const dynamicStyles = {
    container: (size: number): ViewStyle => ({
        height: size,
        width: size,
    })
};

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        justifyContent: "center",
    }
});

export default SquareSvg;