import {
  StatusBar,
  Text,
  StyleSheet,
  Dimensions,
  Pressable,
  Platform,
  ScrollView,
  View,
  Linking,
  TouchableOpacity,
  Alert,
  findNodeHandle,
} from "react-native";
import React, { useEffect, useState, useContext, useRef } from "react";
import { COLORS, FONTS } from "../../../utils";
import CommonHeader from "../../components/CommonHeader";
import { OrientationContext } from "../../components/OrientationContext";
import { t } from "i18next";
import { DataContext } from "../../components/DataContext";
const { width, height } = Dimensions.get("window");
const Home = () => {
  const { error } = useContext(DataContext);
  const scrollViewRef = useRef(null);
  const faqRef = useRef(null);
  const accountRef = useRef(null);
  const ebooksRef = useRef(null);
  const medalsRef = useRef(null);
  const criteriaRef = useRef(null);
  const blendedRef = useRef(null);
  const graphsRef = useRef(null);
  const scrollTo = (id) => {
    if (Platform.OS === "web") {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      const ref = {
        faq: faqRef,
        graphs: graphsRef,
        blended: blendedRef,
        criteria: criteriaRef,
        medals: medalsRef,
        ebooks: ebooksRef,
        account: accountRef,
      }[id];

      if (ref && ref.current) {
        ref.current.measureLayout(
          scrollViewRef.current,
          (x, y, width, height) => {
            scrollViewRef.current.scrollTo({ x: 0, y, animated: true });
          }
        );
      }
    }
  };
  const isLandscape = useContext(OrientationContext);
  const [removeFocusTrigger, setRemoveFocusTrigger] = useState(false);
  const removeDropdown = () => {
    setRemoveFocusTrigger((prev) => !prev);
  };
  const openGmail = () => {
    const email = "cs@nutrisearch.ca";
    const subject = "Support Request";
    const body = "";
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
      email
    )}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.open(gmailUrl, "_blank");
  };
  return (
    <View
      style={{
        flex: 1,
        height: height * 100,
        marginLeft: isLandscape ? 75 : 0,
      }}
    >
      <StatusBar
        animated={true}
        barStyle={"light-content"}
        backgroundColor={"transparent"}
        translucent={true}
      />
      <CommonHeader
        marginTop={140}
        title={t("Welcome to NutriSearch")}
        showFilter={false}
        onDataFilter={null}
        onRemoveFocus={removeFocusTrigger}
        // onRemoveFocus={removeDropdown}
      />
      <View style={styles.container}>
        <ScrollView
          onScroll={removeDropdown}
          ref={scrollViewRef}
          onPress={removeDropdown}
          style={{
            maxWidth: 1024,
            margin: "auto",
            flex: 1,
            padding: 20,
            borderWidth: 0,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Pressable onPress={removeDropdown}>
            <View style={styles.header}>
              <Text
                style={styles.paragraph}
               
              >
                {t(
                  "This app is the culmination of over 25 years of development which began from personal research on nutritional supplementation by Lyle MacWilliam, BSc MSc, for his own family. It quickly evolved from a small handout to an internationally successful, full-colour publication. At its height, the "
                )}
                <Text style={styles.emphasis}>
                  {t(
                    "NutriSearch Comparative Guide to Nutritional Supplements™"
                  )}
                </Text>{" "}
                {t(
                  "(the Guide) included well over 2,000 comparisons of multiple vitamin and mineral supplements."
                )}
              </Text>
              <Text style={styles.paragraph}>
                {t(
                  "For our first 25 years, NutriSearch Corporation (and its predecessor MacWilliam Communications Inc.) published the Guide in printed form. Through those years, we printed hundreds of thousands of copies of the guide through six editions in five languages. Each new edition updated the latest nutritional science and included product comparisons of multiple vitamin and mineral supplements from multiple countries to our Blended Standard and up to 18 Health Support Criteria (HSP)."
                )}
              </Text>
              <Text style={styles.paragraph}>
                {t(
                  "This app is our transition to a digital world and will allow us to continuously update our product database. The complete sixth edition text is included in this app on the Ebook tab."
                )}
              </Text>
            </View>
            <View style={styles.article}>
              <Text style={styles.articleTitle}>
                {t("What can you expect in this App?")}
              </Text>
              <View style={styles.list}>
                <TouchableOpacity onPress={() => scrollTo("graphs")}>
                  <Text style={styles.listItem}>{t("Graphs tab")}</Text>
                </TouchableOpacity>
                <View style={styles.subList}>
                  <TouchableOpacity onPress={() => scrollTo("blended")}>
                    <Text style={styles.subListItem}>
                      {t("What is the Blended Standard?")}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollTo("criteria")}>
                    <Text style={styles.subListItem}>
                      {t("What are the Health Support Criteria (HSP)?")}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollTo("medals")}>
                    <Text style={styles.subListItem}>
                      {t(
                        "What is the NutriSearch Medals of Achievement Program?"
                      )}
                    </Text>
                  </TouchableOpacity>
                </View>
                <TouchableOpacity onPress={() => scrollTo("ebooks")}>
                  <Text style={styles.listItem}>{t("Ebooks")}</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => scrollTo("faq")}>
                  <Text style={styles.listItem}>
                    {t("Frequently Asked Questions (FAQ)")}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => scrollTo("account")}>
                  <Text style={styles.listItem}>{t("Account")}</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View id="graphs" ref={graphsRef} style={styles.article}>
              <Text style={styles.articleTitle}>{t("Graphs")}</Text>
              <Text style={styles.paragraph}>
                {t(
                  "This is the central piece of the App. On the Graphs tab, you'll find over 1,700 multiple vitamin and mineral supplements listed from four countries: Canada, Colombia, México, and the United States. You'll also see the Star Rating of each product, from zero to five stars."
                )}
              </Text>
              <Text style={styles.paragraph}>
                {t(
                  "Clicking on the name of a product will open its graph page,which shows how each product rates on each of the sixteen Health Support Criteria (HSP), measured against the Blended Standard."
                )}
              </Text>
            </View>
            <View id="blended" ref={blendedRef} style={styles.article}>
              <Text style={styles.articleTitle}>
                {t("What is the Blended Standard?")}
              </Text>
              <Text style={styles.paragraph}>
                {t(
                  "The NutriSearch Blended Standard is a consumer's assurance that NutriSearch cannot influence the product comparisons in the Guide. Instead of comparing products to our own beliefs about daily nutrition intake, the Blended Standard is a set of recommended daily intakes of vitamins, minerals, and nutrients combined from the published recommendations for daily intake of 12 nutritional authorities. Comparative Guide author Lyle MacWilliam combined those recommendations, using the median value of each nutrient to create the Blended Standard."
                )}
              </Text>
              <Text style={styles.paragraph}>
                {t(
                  "Brief biographies of each authority, including their qualifications and publications used for the Blended Standard, are included in the "
                )}
                <Text style={styles.emphasis}>
                  {t(
                    "NutriSearch Comparative Guide to Nutritional Supplements™ for the Americas."
                  )}
                </Text>{" "}
                {t(
                  "Their individual recommendations and the combined Blended Standard amounts can be found in Table 8.1 in the Guide."
                )}
              </Text>
              <Text style={styles.paragraph}>
                {t("Full details can be found in")}
                <Text onPress={() => {}}>
                  {" "}
                  Chapter 8: {t("Comparing Supplements")}
                </Text>{" "}
                {t("in the Guide included on the Ebook tab of this app.")}
              </Text>
            </View>
            <View id="criteria" ref={criteriaRef} style={styles.article}>
              <Text style={styles.articleTitle}>
                {t("What are the Health Support Criteria?")}
              </Text>
              <Text style={styles.paragraph}>
                {t(
                  "The Health Support Criteria were developed based on current scientific knowledge at the time of writing. Each criterion looks at a particular area of health, such as"
                )}
              </Text>
              <Text style={styles.paragraph}>1. {t("Completeness")}</Text>
              <Text style={styles.paragraph}>2. {t("Potency")}</Text>
              <Text style={styles.paragraph}>3. {t("Vitamin E Forms")}</Text>
              <Text style={styles.paragraph}>4. {t("Immune Support")}</Text>
              <Text style={styles.paragraph}>5. {t("Bone Health")}</Text>
              <Text style={styles.paragraph}>6. {t("Heart Health")}</Text>
              <Text style={styles.paragraph}>
                7. {t("Liver Health (detoxification)")}
              </Text>
              <Text style={styles.paragraph}>
                8. t{t("Metabolic Health (glucose control)")}
              </Text>
              <Text style={styles.paragraph}>
                9. {t("Healthy Aging Support")}
              </Text>
              <Text style={styles.paragraph}>
                10. {t("Methylation Support")}
              </Text>
              <Text style={styles.paragraph}>
                11. {t("Lipotropic Factors")}
              </Text>
              <Text style={styles.paragraph}>
                12. {t("Inflammation Control")}
              </Text>
              <Text style={styles.paragraph}>13. {t("Glycation Control")}</Text>
              <Text style={styles.paragraph}>
                14. {t("LifeSpan Essentials")}
              </Text>
              <Text style={styles.paragraph}>
                15. {t("Common Antioxidant Profile (penalty score)")}
              </Text>
              <Text style={styles.paragraph}>
                16. {t("Potential Toxicities (penalty score)")}
              </Text>
              <Text style={styles.paragraph}>
                {t(
                  "Each criterion compares the level of particular nutrients in the product affecting that area of health to the Blended Standard levels."
                )}
              </Text>
              <Text style={styles.paragraph}>
                {t(
                  "Full details of each criterion and the nutrients included in evaluating that score can be found in"
                )}
                <Text onPress={() => {}}>
                  {" "}
                  Chapter 9: {t("Product Rating Criteria")}
                </Text>{" "}
                {t("in the Guide included on the Ebook tab of this app.")}
              </Text>
            </View>
            <View id="medals" ref={medalsRef} style={styles.article}>
              <Text style={styles.articleTitle}>
                {t("NutriSearch Medals of Achievement Program (NMAP)")}
              </Text>
              <Text style={styles.paragraph}>
                {t(
                  "NutriSearch has always believed that the quality of a supplement is just as important as the variety of nutrients in a supplement. Unfortunately, many national regulatory bodies do not put enough emphasis on laboratory testing of supplements before, during, and after manufacturing. Many studies have found that supplements do not contain the nutrients indicated on the label or, worse, contain toxic or dangerous ingredients."
                )}
              </Text>
              <Text style={styles.paragraph}>
                {t(
                  "To help consumers avoid these pitfalls, we created the NutriSearch Medals of Achievement Program™. Qualifying nutritional manufacturers are invited to submit notarized evidence of compliance with third-party testing protocols, safety for athletes, and other verification of quality manufacturing. Medals are awarded based on increasing levels of compliance, from simple food-model compliance to manufacturer in a certified pharmaceutical manufacturing facility."
                )}
              </Text>
              <Text style={styles.paragraph}>
                {t("Full details of NMAP can be found in")}
                <Text onPress={() => {}}>
                  {" "}
                  Chapter 10: {t("Medals of Achievement")}
                </Text>{" "}
                {t("in the Guide included on the Ebook tab of this app.")}
              </Text>
            </View>
            <View id="ebooks" ref={ebooksRef} style={styles.article}>
              <Text style={styles.articleTitle}>
                {t("What's on the Ebooks tab?")}
              </Text>
              <Text style={styles.paragraph}>
                {t(
                  "There are five files linked from the Ebooks tab. Each is an ePub file containing the main text of the sixth edition of the"
                )}{" "}
                <Text style={styles.emphasis}>
                  {t(
                    "NutriSearch Comparative Guide to Nutritional Supplements™ for the Americas"
                  )}
                </Text>{" "}
                {t(
                  "in the original English or a translation to traditional Chinese,French, Korean, or Spanish."
                )}
              </Text>
              <Text style={styles.paragraph}>{t("The guides include")}</Text>
              <Text style={styles.paragraph}>
                1. {t("A summary of recent nutritional science")}
              </Text>
              <Text style={styles.paragraph}>
                2.{" "}
                {t(
                  "Construction of the Blended Standard and brief biographies of the nutritional authorities whose works were cited"
                )}
              </Text>
              <Text style={styles.paragraph}>
                3.{" "}
                {t(
                  "The sixteen Health Support Criteria (HSP), and the nutrients used to create the graph scores"
                )}
              </Text>
              <Text style={styles.paragraph}>
                4. {t("NutriSearch Medals of Achievement Program")}
              </Text>
              <Text style={styles.paragraph}>
                5.{" "}
                {t(
                  "Complete bibliography with hundreds of scientific references."
                )}
              </Text>
            </View>
            <View id="faq" ref={faqRef} style={styles.article}>
              <Text style={styles.articleTitle}>
                {t("Frequently Asked Questions (FAQ)")}
              </Text>
              <Text style={styles.paragraph}>
                {t(
                  "Wondering how we choose our products? Why your favourite multiple vitamin and mineral supplement isn't included in our database? These and other questions can be found on the FAQ tab."
                )}
              </Text>
            </View>
            <View id="account" ref={accountRef} style={styles.article}>
              <Text style={styles.articleTitle}>{t("The Account tab")}</Text>
              <Text style={styles.paragraph}>
                {t(
                  "This tab allows you to review your user information, change your password, change the language in which you view the app, and logout."
                )}
              </Text>
            </View>
            <View style={styles.footer}>
              <Text style={styles.footerTitle}>{t("Contact us")}</Text>
              <Text
                style={[
                  styles.paragraph,
                  { marginBottom: Platform.OS == "web" ? 50 : 100 },
                ]}
              >
                {t(
                  "We hope you enjoy the NutriSearch App. If you have any questions, please contact our"
                )}
                <Text
                  style={styles.link}
                  onPress={() => {
                    Platform.OS == "web"
                      ? openGmail()
                      : Linking.openURL("mailto:cs@nutrisearch.ca");
                  }}
                >
                  {" "}
                  {t("Customer Service")}
                </Text>{" "}
                {t("department.")}
              </Text>
            </View>
          </Pressable>
        </ScrollView>
      </View>
    </View>
  );
};
export default Home;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  View1: {
    width: "100%",
    height: Platform.OS === "ios" ? 167 : 175,
    backgroundColor: COLORS.primary,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  headerView: {
    width: "100%",
    flexDirection: "row",
    marginTop: Platform.OS === "web" ? 50 : 60,
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    height: 24,
    width: 24,
    position: "absolute",
    left: 20,
    top: 7,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 22,
    color: COLORS.white,
    fontFamily: "Poppins-SemiBold",
    fontWeight: Platform.OS === "web" ? 0 : "600",
  },
  SearchBarView: {
    backgroundColor: "white",
    width: Platform.OS === "web" ? "100%" : width - 32,
    alignSelf: "center",
    marginTop: Platform.OS === "web" ? 10 : 0,
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 0,
    paddingLeft: 30,
  },
  txt1: {
    fontFamily: "Poppins-SemiBold",
    fontWeight: "600",
    fontSize: 17,
    color: COLORS.primary,
    paddingLeft: 20,
    paddingTop: 10,
    marginBottom: 10,
  },
  txt2: {
    fontFamily: "Poppins-SemiBold",
    fontWeight: Platform.OS === "web" ? 0 : "600",
    fontSize: 16,
    lineHeight: 24,
    color: COLORS.primary,
    letterSpacing: 0.3,
  },
  nestedContainer1: {
    backgroundColor: "white",
    width: Platform.OS === "web" ? "50%" : width - 32,
    maxWidth: Platform.OS === "web" ? 436 : "99.5%",
    minWidth: 325,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    alignSelf: "center",
    height: 310,
    position: "absolute",
    paddingLeft: 10,
    paddingTop: 15,
    shadowColor: "black",
    shadowOffset: {
      width: 0,
      height: 14,
    },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    elevation: 5,
  },
  nestedContainer5: {
    borderBottomWidth: 0,
    borderColor: COLORS.primarygraycolor,
    flexDirection: "row",
    marginBottom: 7,
  },
  nestedView: {
    height: 30,
    borderBottomWidth: 0,
    borderColor: COLORS.primarygraycolor,
    flexDirection: "row",
    marginBottom: 7,
  },
  searchBarContainer: {
    width: Platform.OS === "web" ? "50%" : width - 32,
    maxWidth: 436,
    minWidth: 325,
    alignSelf: "center",
    marginTop: 10,
    borderRadius: 6,
  },
  nestedViewContainer: {
    borderRadius: 15,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    padding: width > 750 ? 25 : 8,
    width: Platform.OS === "web" ? "80%" : width - 32,
    minWidth: 325,
    alignSelf: "center",
  },

  header: {
    marginBottom: 20,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
  },
  paragraph: {
    fontSize: 16,
    marginBottom: 10,
    lineHeight: 24,
    // fontFamily: FONTS.PoppinsMedium,
    fontFamily: "Poppins-Medium",
    color: "#1a1c1d",
  },
  emphasis: {
    fontStyle: "italic",
  },
  article: {
    marginBottom: 20,
  },
  articleTitle: {
    fontSize: 20,
    // fontWeight: 'bold',
    marginBottom: 10,
    fontFamily: "Poppins-SemiBold",
    color: COLORS.primary,
  },
  list: {
    paddingLeft: 20,
  },
  listItem: {
    fontSize: 15,
    marginBottom: 3,
    color: "#4D75B0",
    // fontWeight: 'bold',
    fontFamily: "Poppins-SemiBold",
    textDecorationLine: "underline",
  },
  subList: {
    paddingLeft: 20,
  },
  subListItem: {
    fontSize: 14,
    marginBottom: 5,
    color: "#4D75B0",
    // fontWeight: 'bold',
    fontFamily: "Poppins-SemiBold",
    fontSize: 16,
    marginBottom: 3,
    textDecorationLine: "underline",
    color: "#4D75B0",
  },
  footer: {
    marginTop: 20,
  },
  footerTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
    color: COLORS.primary,
  },
  link: {
    // color: '#4D75B0',
    textDecorationLine: "underline",
    textDecorationColor: "#007AFF", // blue color
    textDecorationStyle: "solid",
    color: "#007AFF",
  },
});
