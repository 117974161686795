import React from 'react';
import { View, Image } from 'react-native';

const StarRating = ({ rating, totalStars = 5, customStarImage, height, width }) => {
  const stars = [];

  for (let i = 1; i <= totalStars; i++) {
    const filled = i <= rating;

    stars.push(
      <View key={i}>
        <Image
          source={filled ? customStarImage.filled : customStarImage.empty}
          style={{ width: width, height: height,
             }}
        />
      </View>
    );
  }

  return <View style={{ flexDirection: 'row' }}>{stars}</View>;
};

export default StarRating;
