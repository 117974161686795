import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Alert,
  Pressable,
  StatusBar,
  ActivityIndicator,
  Platform,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTranslation } from "react-i18next";
import { useIsFocused } from "@react-navigation/native";
import { API, COLORS, FONTS } from "../../../utils";
import { Toast } from "react-native-toast-notifications";

export default function MySubscription({ navigation }) {
  const LeftArrow = require("../../assets/images/whiteleftarrow.png");
  const ClickIcon = require("../../assets/images/clickicon.png");
  const { t } = useTranslation();
  const [subscription, setSubscription] = useState("");
  const [daysToExpire, setDaysToExpire] = useState(null);
  const [isCanceling, setIsCanceling] = useState(false);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      fetchUserSubscription();
    }
  }, [isFocused]);

  function notifyMessage(msg) {
    if (Platform.OS === "ios") {
      Alert.alert(msg);
    } else {
      Toast.show(msg, {
        type: "normal",
        placement: "bottom",
        duration: 3000,
        offset: 0,
        animationType: "zoom-in",
      });
    }
  }
  const fetchUserSubscription = async () => {
    const id = await AsyncStorage.getItem("id");
    const sessionKey = await AsyncStorage.getItem("key");
    const headers = {
      id: id,
      session_key: sessionKey,
      Authorization: "Basic YWRtaW46MTIzNA==",
    };

    try {
      const response = await fetch(
        `${API.baseurl}${API.getsubscription}?id=${id}&session_key=${sessionKey}`,
        {
          method: "GET",
          headers,
        }
      );
      const result = await response.json();
      if (result.status === 200) {
        const data = result.data[0];
        setSubscription(data);
        if (
          result?.data?.length < 1 ||
          result?.data == "" ||
          result?.data == null
        ) {
          setSubscription(1);
        }
        setDaysToExpire(
          calculateDaysToExpire(
            result.data[0]?.StartDate,
            result.data[0]?.EndDate
          )
        );
      } else if (result.message.includes("Unauthorized")) {
        Alert.alert("Unauthorized");
      } else {
        setSubscription(1);
      }
    } catch (error) {
      // Alert.alert("Error fetching subscription");
      console.log(error);
    }
  };
  const cancelSubscription = async () => {
    setIsCanceling(true);
    const id = await AsyncStorage.getItem("id");
    const sessionKey = await AsyncStorage.getItem("key");
    const headers = {
      id: id,
      session_key: sessionKey,
      Authorization: "Basic YWRtaW46MTIzNA==",
    };
    const formData = new FormData();
    formData.append("subscriptionId", subscription.Subscription_id);

    try {
      const response = await fetch(`${API.baseurl}cancelsubscription`, {
        method: "POST",
        headers,
        body: formData,
      });

      if (response.status === 200) {
        notifyMessage("Subscription cancelled successfully");
        fetchUserSubscription();
      } else {
        notifyMessage("Error cancelling subscription");
      }
    } catch (error) {
      notifyMessage("Error cancelling subscription");
    } finally {
      setIsCanceling(false); // Stop showing loading indicator
    }
  };
  const calculateDaysToExpire = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return Math.round((end - start) / (1000 * 3600 * 24));
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "long",
    })} ${date.getFullYear()}`;
  };
  return (
    <View style={styles.container}>
      <StatusBar
        barStyle={"light-content"}
        backgroundColor={"transparent"}
        translucent
      />
      <View style={styles.header}>
        <Text allowFontScaling={false} style={styles.title}>
          {t("Subscription")}
        </Text>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <Image source={LeftArrow} style={styles.icon} />
        </TouchableOpacity>
      </View>
      {!subscription && <Text style={styles.loadingText}>Loading...</Text>}
      {subscription == 1 ? (
        <View style={styles.noSubscription}>
          <Text style={styles.noSubscriptionText} 
                  allowFontScaling={false}
          
          >
            {t("You Don't Have an Active Subscription")}
          </Text>
          <Pressable
            style={styles.subscriptionButton}
            onPress={() => navigation.navigate("Subscription")}
          >
            <Text style={styles.subscriptionButtonText} 
                  allowFontScaling={false}
            
            >
              {t("Buy Subscription")}
            </Text>
          </Pressable>
        </View>
      ) : (
        subscription && (
          <View style={styles.subscriptionCard}>
            <Text style={styles.cardTitle}>{t("Current Active Plan")}</Text>
            <View style={styles.cardRow}>
              <Text style={styles.cardLabel} allowFontScaling={false}>
                {t("Start Date")}
              </Text>
              <Text style={styles.cardValue} allowFontScaling={false}>
                {formatDate(subscription.StartDate)}
              </Text>
            </View>
            <View style={styles.cardRow} allowFontScaling={false}>
              <Text style={styles.cardLabel} allowFontScaling={false}>
                {t("End Date")}
              </Text>
              <Text style={styles.cardValue} allowFontScaling={false}>
                {formatDate(subscription.EndDate)}
              </Text>
            </View>
            <View style={styles.cardRow}>
              <Text style={styles.cardLabel} allowFontScaling={false}>
                {t("Paid Amount")}
              </Text>
              <Text
                // allowFontScaling={false}
                style={styles.cardValue}
              >{`${subscription.Amount} ${subscription.Currency}`}</Text>
            </View>
            <View style={styles.cardRow}>
              <Text style={styles.cardLabel} allowFontScaling={false}>
                {t("Expire in")}
              </Text>
              <Text
                style={styles.cardValue}
                
              >{`${daysToExpire} Days`}</Text>
            </View>
            <Image source={ClickIcon} style={styles.clickIcon} />
            <View style={styles.buttonsContainer}>
              <TouchableOpacity
                style={styles.button}
                onPress={() =>
                  navigation.navigate("UpgradeSubscription", {
                    Plan: subscription,
                  })
                }
              >
                <Text style={styles.buttonText}
                  // allowFontScaling={false}
                
                >
                  {t("Upgrade Subscription")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button}
                onPress={cancelSubscription}
                disabled={isCanceling}
              >
                {isCanceling ? (
                  <ActivityIndicator size="small" color={COLORS.primary} />
                ) : (
                  <Text style={styles.buttonText} 
                  // allowFontScaling={false}
                  
                  >
                    {t("Cancel Subscription")}
                  </Text>
                )}
              </TouchableOpacity>
            </View>
          </View>
        )
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.background,
  },
  header: {
    backgroundColor: COLORS.primary,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingHorizontal: 15,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: Platform.OS === "web" ? 78 : 94,
    backgroundColor: COLORS.primary,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    paddingTop: Platform.OS != "web" ? 47 : null,
  },

  backButton: {
    position: "absolute",
    left: 17,
    top: Platform.OS == "web" ? 30 : 55,
  },
  icon: {
    height: 24,
    width: 24,
  },
  title: {
    flex: 1,
    textAlign: "center",
    color: COLORS.white,
    fontSize: 22,
    fontFamily: FONTS.PoppinsSemiBold,
  },
  loadingText: {
    marginTop: 20,
    textAlign: "center",
    fontSize: 16,
    color: COLORS.primary,
  },
  noSubscription: {
    alignItems: "center",
    marginTop: 40,
  },
  noSubscriptionText: {
    fontSize: 25,
    fontFamily: FONTS.PoppinsSemiBold,
    color: COLORS.primary,
    textAlign: "center",
  },
  subscriptionButton: {
    marginTop: 20,
    backgroundColor: COLORS.primary,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
  },
  subscriptionButtonText: {
    color: "white",
    fontSize: 18,
    fontFamily: FONTS.PoppinsSemiBold,
  },
  subscriptionCard: {
    backgroundColor: COLORS.primary,
    borderRadius: 10,
    padding: 20,
    margin: 36,
    alignItems: "center",
    maxWidth: 500,
    marginHorizontal: "auto",
    width: "92%",
    alignSelf: "center",
  },
  cardTitle: {
    fontSize: 18,
    color: COLORS.white,
    fontFamily: FONTS.PoppinsSemiBold,
    marginBottom: 15,
  },
  cardRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  cardLabel: {
    color: COLORS.white,
    fontSize: 15,
    fontFamily: FONTS.PoppinsMedium,
  },
  cardValue: {
    color: COLORS.white,
    fontSize: 15,
    fontFamily: FONTS.PoppinsRegular,
  },
  clickIcon: {
    height: 30,
    width: 30,
    position: "absolute",
    right: 20,
    top: -15,
  },
  buttonsContainer: {
    marginTop: 20,
    width: "100%",
  },
  button: {
    backgroundColor: "white",
    borderRadius: 10,
    alignItems: "center",
    paddingVertical: 10,
    marginBottom: 10,
  },
  buttonText: {
    color: COLORS.primary,
    fontSize: 14,
    fontFamily: FONTS.PoppinsRegular,
  },
});
