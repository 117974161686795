import {
  ActivityIndicator, View, Text, Dimensions, Pressable, StyleSheet, Image,
  TextInput, FlatList, Platform, StatusBar, TouchableOpacity, Animated, Alert, ScrollView, Linking
} from 'react-native'
import React, { useEffect, useState, useCallback, useRef, useContext,useLayoutEffect } from 'react'
import { API, COLORS, FONTS } from '../../../utils'
import fill from '../../assets/images/fillstar.png'
import unfill from '../../assets/images/unfillstar.png'
import AsyncStorage from '@react-native-async-storage/async-storage';
import StarRating from '../../components/starrating'
import { useTranslation } from 'react-i18next';
import { FlatGrid } from 'react-native-super-grid';
import { BlurView } from 'expo-blur';
import { useNavigation } from '@react-navigation/native';
import { DataContext } from '../../components/DataContext';
import CommonHeader from '../../components/CommonHeader'
import { OrientationContext } from '../../components/OrientationContext'
const windowWidth = Dimensions.get('window').width;

export default function Ebook() {
  const HeaderRef = useRef(null);
  useLayoutEffect(() => {
    if (HeaderRef.current) {
      HeaderRef.current.focus();
    }
  }, [HeaderRef]);

  const isLandscape = useContext(OrientationContext);
  const { data2 } = useContext(DataContext);
  const navigation = useNavigation();
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [rating, setRating] = useState(5);
  const [booksData, setBooksData] = useState([]);
  const [ebookData, setEbookData] = useState([]);
  const [userId, setUserId] = useState('');
  const [userKey, setUserKey] = useState('');
  const [isVisible3, setIsVisible3] = useState(false);
  const fadeAnimm = useRef(new Animated.Value(0)).current;
  const crossCircle = require('../../assets/images/crosscircle.png')
  const [currentBookName, setCurrentBookName] = useState('')
  const [selectedItems, setSelectedItems] = useState([]);
  const [removeFocusTrigger, setRemoveFocusTrigger] = useState(false);
  const customStarImage = {
    filled: fill,
    empty: unfill
  };

  
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      yourFunctionToChangeState();
    });
    return unsubscribe;
  }, [navigation]);



  const removeDropdown = () => {
    setRemoveFocusTrigger(false);
};
  const yourFunctionToChangeState = () => {
    loadSelectedItems();
  };
  const loadSelectedItems = async () => {
    try {
      const storedItems = await AsyncStorage.getItem('selectedItems');
      if (storedItems) {
        setSelectedItems(JSON.parse(storedItems));
        setRecentSearch(JSON.parse(storedItems));
      }
    } catch (error) {
      console.error('Error loading selected items from AsyncStorage:', error);
    }
  };
  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('key');
      const id = await AsyncStorage.getItem('id');
      setUserKey(value);
      setUserId(id);

      if (value && id) {
        handlebook(value, id);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handlebook = (value, id) => {
    setIsActive(true);
    const myHeaders = new Headers();
    myHeaders.append("id", id);
    myHeaders.append("session_key", value);
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`${API.baseurl}${API.getEbook}?id=${id}&session_key=${value}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result.data);
        setBooksData(result?.data);
        setEbookData(result?.data);
        setIsActive(false);
      })
      .catch(error => console.log('Error fetching ebook data:', error));
  };
  const showPopup3 = () => {
    Animated.timing(fadeAnimm, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
    setIsVisible3(true);
  };
  const hidePopup3 = () => {
    Animated.timing(fadeAnimm, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => setIsVisible3(false));
  };
  const handlePdfGenerate = async (item) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("id", userId);
      myHeaders.append("session_key", userKey);
      myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      const response = await fetch(`${API.baseurl}${API.pdfGenrate}?id=${userId}&session_key=${userKey}&book_id=${item?.id}`, requestOptions);
      const result = await response.json();

      if (result?.status == 200) {
        // console.log("PDF is available");
        navigation.navigate('Pdfview', { bookname: currentBookName, item, pdf: result?.data });
      } else if (result?.status == 400) {
        setIsVisible3(true);
        showPopup3();
      } else {
        notifyMessage(result?.message);
      }
    } catch (error) {
      console.error('Error handling PDF generation:', error);
      notifyMessage(JSON.stringify(error));
    }
  };
  function RenderItem2({ item, index }) {
    const isLastItem = index === ebookData.length - 1;

    return (
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={() => { handlePdfGenerate(item), setCurrentBookName(item?.title) }}
        style={styles.container2}
      >
        <Image source={{ uri: item.image }} style={styles.image2} />
        <Text  style={styles.titleText2}>
          {item?.title}
        </Text>
        <View style={styles.ratingContainer}>
          <StarRating rating={rating} customStarImage={customStarImage} height={13} width={13} />
          <Text allowFontScaling={false} style={styles.ratingText}>4.3</Text>
        </View>
      </TouchableOpacity>
    );
  }
  const AnimatedPopup = ({ isVisible, hidePopup, fadeAnim, navigation }) => (
    isVisible && (
      <>
        <BlurView style={styles.blurBackground} blurType="light" intensity={20} reducedTransparencyFallbackColor="white" />
        <Animated.View 
  style={[
    styles.popup, 
    { 
      opacity: fadeAnim, 
      ...(Platform.OS !== "web" && { maxHeight: 260 }) 
    }
  ]}
>
            <TouchableOpacity activeOpacity={0.7} onPress={hidePopup} style={styles.closeButton}>
              <Image source={crossCircle} style={styles.closeIcon} />
            </TouchableOpacity>
            <Text allowFontScaling={false} style={styles.popupText}>
              {"Please Buy Subscription Plan First to Read A Book"}
            </Text>
            <Pressable onPress={() => navigation.navigate('Subscription')} style={styles.subscriptionButton}>
              <Text allowFontScaling={false} style={styles.subscriptionButtonText}>
                {"Go to Subscription"}
              </Text>
            </Pressable>
        </Animated.View>
      </>
    )
  );
  return (
    <Pressable onPress={removeDropdown} style={[styles.container, { marginLeft: isLandscape ? 75 : 0 }]}>
      <CommonHeader
       ref={HeaderRef}
       showFilter={false}
       title={`${t("NutriSearch Comparative Guide to Nutritional Supplements")}`}
        marginTop={174}
        onRemoveFocus={removeFocusTrigger}
      />
      <View style={styles.flexOne}>
        {isActive ? (
          <ActivityIndicator color={COLORS.primary} size='small' style={styles.flexOne} />
        ) : (
          Platform.OS === 'web' ? (
            <>
              <ScrollView showsVerticalScrollIndicator={false} style={styles.scrollView}>
                <FlatGrid
                keyboardShouldPersistTaps="handled"
                  showsVerticalScrollIndicator={false}
                  ListHeaderComponent={() => <Text style={styles.ebookTitle}>{t("Complete text of our sixth edition in Chinese, English, French, Korean, and Spanish")}</Text>}
                  itemDimension={265}
                  data={ebookData}
                  style={styles.gridView}
                  spacing={1}
                  renderItem={RenderItem2}
                />
              </ScrollView>
              <AnimatedPopup isVisible={isVisible3} hidePopup={hidePopup3} fadeAnim={fadeAnimm} navigation={navigation} />
            </>
          ) : (
            <>
              <FlatList
                style={styles.flexOne}
                contentContainerStyle={styles.listContent}
                ListHeaderComponent={() => <Text style={styles.listHeader}>{t("Complete text of our sixth edition in Chinese, English, French, Korean, and Spanish")}</Text>}
                bounces={false}
                showsVerticalScrollIndicator={false}
                data={ebookData}
                ListFooterComponent={() => <View style={{ height: 100 }} />}
                keyExtractor={item => item.id}
                renderItem={RenderItem2}
                numColumns={1}
              />
              <AnimatedPopup isVisible={isVisible3} hidePopup={hidePopup3} fadeAnim={fadeAnimm} navigation={navigation} />
            </>
          )
        )}
      </View>
    </Pressable>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%'
  },
  flexOne: {
    flex: 1,
  },
  scrollView: {
    width: "95%",
    height: '80%',
    alignSelf: 'center',
    paddingBottom: 100
  },
  ebookTitle: {
    marginTop: Platform.OS === 'web' ? 5 : 20,
    fontFamily: 'Poppins-SemiBold',
    // textAlign: 'center',
    color: COLORS.primary,
    fontWeight: Platform.OS === 'web' ? 0 : '600',
    fontSize: 17,
    paddingLeft: Platform.OS == 'web' ? 10 : 10,
    paddingBottom: Platform.OS == "web" ? 10:0,
    paddingTop: Platform.OS == "web" ? 5:0,
  },
  gridView: {
    flex: 1,
    maxWidth: 1200,
    margin: 'auto',
    width: '100%'
  },
  listContent: {
    marginRight: 20,
    marginLeft: 20
  },
  listHeader: {
    // marginLeft: -20,
    marginBottom: 2,
    marginTop: 10,
    fontFamily: FONTS.PoppinsSemiBold,
    fontWeight: '600',
    fontSize: 17,
    color: COLORS.primary,marginLeft:5,
    
  },
  blurBackground: {
    ...StyleSheet.absoluteFillObject,
  },
  popup: {
    position: 'absolute',
    backgroundColor: 'white',
    padding: 0,
    borderRadius: 12,
    elevation: 5,
    alignSelf: 'center',
    width: Platform.OS === 'web' ? '20%' : '70%',
    maxWidth: 400,
    minWidth: Platform.OS === 'web' ? 300 : undefined,
    marginTop: Platform.OS === 'web' ? 200 : '50%',
  },
  closeButton: {
    height: 35,
    width: 35,
    position: 'absolute',
    right: -10,
    top: -15,
  },
  closeIcon: {
    height: 35,
    width: 35,
    resizeMode: 'contain'
  },
  popupText: {
    fontFamily: FONTS.PoppinsSemiBold,
    color: COLORS.primary,
    fontSize: 25,
    alignSelf: 'center',
    margin: 25,
    textAlign: 'center',
    marginBottom: 10
  },
  subscriptionButton: {
    width: '90%',
    height: 50,
    backgroundColor: COLORS.primary,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    alignSelf: 'center',
    borderRadius: 10,
    shadowColor: COLORS.primary,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5
  },
  subscriptionButtonText: {
    fontWeight: '500',
    fontSize: 18,
    lineHeight: 22,
    color: 'white',
    fontFamily: FONTS.PoppinsSemiBold
  },
  container3: {
    backgroundColor: 'white',
    padding: 10,
    width: '48%',
    borderWidth: 1,
    // marginRight: 4,
    marginLeft: 0,
    marginTop: 12,
    borderColor: COLORS.primarybordercolor,
    borderRadius: 16,
    marginBottom: Platform.OS === 'android' ? 10 : 80,
  },
  bookImage: {
    width: 675 / 3.8,
    height: 871 / 5,
    borderRadius: 16,
    maxWidth: '100%',
    maxHeight: '100%',

  },
  titleText: {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: '600',
    fontFamily: FONTS.PoppinsSemiBold,
    color: COLORS.primary,
    marginTop: 5,
  },
  ratingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ratingText: {
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '500',
    fontFamily: FONTS.PoppinsMedium,
    marginLeft: 8,
    color: COLORS.primarygraycolor,
    marginBottom: -4,
  },
  container2: {
    backgroundColor: 'white',
    padding: 12,
    paddingBottom: 17,
    borderWidth: 1,
    marginRight: 8,
    marginLeft: 8,
    marginTop: 5,
    marginBottom: 5,
    borderColor: COLORS.primarybordercolor,
    borderRadius: 16,
  },
  image2: {
    width: '100%',
    height: 150,
    borderRadius: 16,
    resizeMode: 'stretch',
  },
  titleText2: {
    fontSize: 14,
    lineHeight: 18,
    color: COLORS.primary,
    marginTop: 5,
    fontFamily: 'Poppins-SemiBold',
    marginTop: 8,
    marginBottom: 8,
    fontWeight: Platform.OS === 'web' ? 0 : '600',
  },
}
)















