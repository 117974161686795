import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  StyleSheet,
  Pressable,
  Platform,
  Keyboard,
} from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  createStackNavigator,
  CardStyleInterpolators,
} from "@react-navigation/stack";
import Splash from "../Splash/splash";
import Signin from "../Signin/signin";
import SignUp from "../signup/signup";
import Subscription from "../subscription/subscription";
import Home from "../home/home";
import Graph from "../graphAndAchievments/graph";
import Ebook from "../ebook/ebook";
import Faq from "../Faq/faq";
import Account from "../account/account";
import ForgotPassword from "../forgotpassword/forgotpassword";
import ResetPassword from "../resetpassword/resetpassword";
import VerifyEmail from "../verifyemail/verifyemail";
import PasswordVerify from "../passwordverify/passwordverify";
import Pdfview from "../pdfviewer/pdfview";
import Bulksubscription from "../bulksubscription/bulksubscription";
import BlogScreen from "../blogscreen/blogscreen";
import Products from "../products/products";
import News from "../news/news";
import { COLORS, FONTS } from "../../../utils";
import MySubscripion from "../mySubscription copy/MySubscripion";
import UpgradeSubscription from "../mySubscription copy/UpgradeSubscription";
import UpgradeBulksubscription from "../mySubscription copy/UpgradeBulkSubscription";
import SquareSvg from "../../components/SquareSvg";
import { HomeIcon } from "../../assets/Icons/Tabs/HomeIcon";
import { AccountIcon } from "../../assets/Icons/Tabs/AccountIcon";
import { FAQIcon } from "../../assets/Icons/Tabs/FAQIcon";
import { ProductIcon } from "../../assets/Icons/Tabs/ProductIcon";
import { EBookIcon } from "../../assets/Icons/Tabs/EBookIcon";
import { NewsIcon } from "../../assets/Icons/Tabs/NewsIcon";
import { useTranslation } from "react-i18next";
import Notification from "../notification/notification";
import { DataContext } from "../../components/DataContext";
import servererror from "../../assets/images/servererror.png";

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const MyTabs = () => {
  const { t } = useTranslation();
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const updateOrientation = () => {
      const { width, height } = Dimensions.get("window");
      setIsLandscape(width > height && height < 500);
    };
    updateOrientation();
    Dimensions.addEventListener("change", updateOrientation);
    return () => {
      Dimensions.removeEventListener("change", updateOrientation);
    };
  }, []);

  return (
    <Tab.Navigator
      tabBar={(props) => (
        <MyTabBar {...props} isLandscape={isLandscape} t={t} />
      )}
      screenOptions={({ route }) => ({
        tabBarStyle: {
          maxWidth: Platform.OS == "web" ? 700 : "100%",
          padding: 10,
          // Increase padding for iOS
        },
      })}
    >
      <Tab.Screen
        name={"Home"}
        // name={t("Home")}
        component={Home}
        options={{ headerShown: false, tabBarLabel: t("Home") }}
      />
      <Tab.Screen
        name={"Products"}
        // name={t("Products")}
        component={Products}
        options={{ headerShown: false, tabBarLabel: t("Products") }}
      />
      <Tab.Screen
        name={"News"}
        // name={t("News")}
        component={News}
        options={{ headerShown: false, tabBarLabel: t("News") }}
      />
      <Tab.Screen
        name={"Ebook"}
        // name={t("Ebook")}
        component={Ebook}
        options={{ headerShown: false, tabBarLabel: t("Ebook") }}
      />
      <Tab.Screen
        name={"Faq"}
        // name={t("Faq")}
        component={Faq}
        options={{ headerShown: false, tabBarLabel: t("Faq") }}
      />
      <Tab.Screen
        name={"Account"}
        // name={t("Account")}
        component={Account}
        options={{ headerShown: false, tabBarLabel: t("Account") }}
      />
      <Stack.Screen
        name={t("Graph")}
        component={Graph}
        options={{ headerShown: false }}
      />
    </Tab.Navigator>
  );
};

const MyTabBar = ({ state, descriptors, navigation, isLandscape, t }) => {
  return (
    <View style={isLandscape ? styles.verticalTabBar : styles.horizontalTabBar}>
      {state.routes.map((route, index) => {
        if (route.name !== "Graph") {
          const { options } = descriptors[route.key];
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;

          const isFocused = state.index === index;

          const onPress = () => {
            Keyboard.dismiss();
            const event = navigation.emit({
              type: "tabPress",
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name);
            }
          };

          let TabIconComponent;
          switch (t(route.name)) {
            case t("Home"):
              TabIconComponent = HomeIcon;
              break;
            case t("Products"):
              TabIconComponent = ProductIcon;
              break;
            case t("News"):
              TabIconComponent = NewsIcon;
              break;
            case t("Ebook"):
              TabIconComponent = EBookIcon;
              break;
            case t("Faq"):
              TabIconComponent = FAQIcon;
              break;
            case t("Account"):
              TabIconComponent = AccountIcon;
              break;
            default:
              TabIconComponent = AccountIcon;
              break;
          }

          const color = isFocused ? COLORS.primary : "gray";
          return (
            <Pressable
              onPress={onPress}
              key={route.name}
              style={
                isLandscape ? styles.verticalTabItem : styles.horizontalTabItem
              }
            >
              <SquareSvg Icon={TabIconComponent} size={25} color={color} />
              <Text
                maxFontSizeMultiplier={1.04}
                style={{
                  color,
                  fontFamily: FONTS.PoppinsMedium,
                  fontSize: 12,
                  letterSpacing: 0.9,
                }}
              >
                {label}
              </Text>
            </Pressable>
          );
        }
      })}
    </View>
  );
};

const AppNavigator = () => {
  const { error } = useContext(DataContext);

  // if (error) {
  //   console.log("######. navi #############. error", error);
  //   return (
  //     <View
  //       style={{
  //         backgroundColor: "white",
  //         flex: 1,
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Image
  //         source={servererror}
  //         style={{ height: 60, width: 65, resizeMode: "contain" }}
  //       />

  //       <Text
  //         style={{
  //           color: COLORS.primary,
  //           fontSize: 28,
  //           fontFamily: FONTS.PoppinsSemiBold,
  //         }}
  //       >
  //         Server Related Issues
  //       </Text>
  //       <Text
  //         style={{
  //           color: "black",
  //           fontSize: 17,
  //           fontFamily: FONTS.PoppinsMedium,
  //         }}
  //       >
  //         Currenlty This Page isn't Working
  //       </Text>
  //     </View>
  //   );
  // }
  return (
    <Stack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <Stack.Screen
        name="Splash"
        component={Splash}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Signin"
        component={Signin}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="SignUp"
        component={SignUp}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Subscription"
        component={Subscription}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Home2"
        component={MyTabs}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Graph"
        component={Graph}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Ebook"
        component={Ebook}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Faq"
        component={Faq}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Account"
        component={Account}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="VerifyEmail"
        component={VerifyEmail}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ResetPassword"
        component={ResetPassword}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PasswordVerify"
        component={PasswordVerify}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Pdfview"
        component={Pdfview}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Bulksubscription"
        component={Bulksubscription}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="BlogScreen"
        component={BlogScreen}
        options={{
          headerShown: false,
          presentation: "transparentModal",
        }}
      />
      {/* <Stack.Screen name="Products" component={Products} options={{ headerShown: false }} /> */}
      <Stack.Screen
        name="News"
        component={News}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="MySubscripion"
        component={MySubscripion}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpgradeSubscription"
        component={UpgradeSubscription}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpgradeBulksubscription"
        component={UpgradeBulksubscription}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Notification"
        component={Notification}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

export default AppNavigator;

const styles = StyleSheet.create({
  horizontalTabBar: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#FCFCFC",
    padding: Platform.OS == "web" ? 8 : 5,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: Platform.OS === "ios" ? 75 : 60,
    zIndex: 10,
    maxWidth: 700,
    margin: "auto",
    paddingBottom: Platform.OS === "ios" ? 15 : 10,
  },
  verticalTabBar: {
    flexDirection: "column",
    justifyContent: "space-around",
    backgroundColor: "#FCFCFC",
    padding: 8,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    width: 75,
    zIndex: 10,
  },
  horizontalTabItem: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minWidth: "15.75%",
  },
  verticalTabItem: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
});
