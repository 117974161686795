// EnhancedSkeletonLoader.js

import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

const { width: screenWidth } = Dimensions.get('window');

const SkeletonLoader = ({ width, height, borderRadius = 4, marginBottom = 10 }) => {
    const [shimmerPosition, setShimmerPosition] = useState(-screenWidth);

    useEffect(() => {
        const interval = setInterval(() => {
            setShimmerPosition((prev) => (prev >= screenWidth ? -screenWidth : prev + 10));
        }, 16);

        return () => clearInterval(interval);
    }, [shimmerPosition]);

    return (
        <View style={[styles.skeletonContainer, { width, height, borderRadius, marginBottom }]}>
            <LinearGradient
                colors={['#a3b5c34a', '#c3ccd34a', '#e2e6e94a']}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                style={[styles.gradient, { width: screenWidth, transform: [{ translateX: shimmerPosition }] }]}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    skeletonContainer: {
        backgroundColor: '#a3b5c34a',
        overflow: 'hidden',
        position: 'relative',
    },
    gradient: {
        ...StyleSheet.absoluteFillObject,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
    },
});

export default SkeletonLoader;
