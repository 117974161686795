import {
  StatusBar,
  View,
  Text,
  StyleSheet,
  Dimensions,
  Image,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Alert,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { API, COLORS, FONTS } from "../../../utils";
import { TextInput } from "react-native";
import { Pressable } from "react-native";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import LeftArrow from "../../assets/images/arrow-left.png";
import PasswordEye from "../../assets/images/eye-outline.png";
import PasswordcloseEye from "../../assets/images/passwordcloseeye.png";
import logoimg from "../../assets/images/logoimg.png";
import { useTranslation } from "react-i18next";
import { Toast } from "react-native-toast-notifications";
import PolicyCheckBox from "../../components/PolicyCheckBox";
const { width, height } = Dimensions.get("screen");
import PhoneInput from "react-native-phone-number-input";
import DeviceInfo, {
  getUniqueId,
  getManufacturer,
} from "react-native-device-info";

export default function SignUp({ navigation }) {
  const [deviceUid, setDeviceUid] = useState("");
  const [manufacture, setManufacture] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const { t } = useTranslation();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passVisibility, setPassVisibility] = useState(true);
  const [confirmPassVisibility, setConfirmPassVisibility] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [policyChecked, setPolicyChecked] = useState(false);
  const [PolicyError, setPolicyError] = useState(false);
  const [value, setValue] = useState("");
  const [formattedValue, setFormattedValue] = useState("");
  const [valid, setValid] = useState(false);
  const phoneInput = useRef(null);
  const [countryCode, setCountryCode] = useState("US");
  const [, setCallingCode] = useState("1");
  const [DeviceToken, setDeviceToken] = useState("");
  const [timezone, setTimezone] = useState("");

  useEffect(() => {
    fetchToken();
  }, []);
  useEffect(() => {
    if (Platform.OS == "web") {
      getBrowser();
    } else {
      DeviceInfo.getUniqueId()
        .then((id) => {
          setDeviceUid(id);
        })
        .catch((error) => {
          console.error("Error getting Unique ID:", error);
        });
      DeviceInfo.getManufacturer()
        .then((name) => {
          setManufacture(name);
        })
        .catch((error) => {
          console.error("Error getting Manufacturer:", error);
        });
      DeviceInfo.getDeviceName()
        .then((name) => {
          setDeviceName(name);
        })
        .catch((error) => {
          console.error("Error getting Device Name:", error);
        });
    }
  }, []);
  useEffect(() => {
    const getTimezone = () => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setTimezone(timezone);
      console.log(timezone);
    };
    getTimezone();
  }, []);
  useEffect(() => {
    const checkValid = phoneInput.current?.isValidNumber(value);
    setValid(checkValid ? checkValid : false);
  }, [value]);
  function getBrowser() {
    const userAgent = navigator.userAgent;
    const vendor = navigator.vendor;
    let browserName = "";

    if (/Chrome/.test(userAgent) && /Google Inc/.test(vendor)) {
      browserName = "Chrome";
    } else if (/Safari/.test(userAgent) && /Apple Computer/.test(vendor)) {
      if (!/Chrome/.test(userAgent)) {
        browserName = "Safari";
      }
    } else if (/Firefox/.test(userAgent)) {
      browserName = "Firefox";
    } else if (/MSIE|Trident/.test(userAgent)) {
      browserName = "Internet Explorer";
    } else if (/Edge/.test(userAgent)) {
      browserName = "Edge";
    }
    setDeviceName(browserName);
    console.log(">>>", browserName);
  }
  const storeData = async (key, id) => {
    try {
      await AsyncStorage.setItem("key", key);
      await AsyncStorage.setItem("id", id);
    } catch (e) {
      console.log("error", e);
    }
  };
  const fetchToken = async () => {
    try {
      const value = await AsyncStorage.getItem("DeviceToken");
      if (value !== null) {
        setDeviceToken(value);
      }
    } catch (e) {
      console.log(JSON.stringify(e));
    }
  };
  const validateInputs = () => {
    let formIsValid = true;
    !userName
      ? (setUserNameError("Username is required"), (formIsValid = false))
      : setUserNameError("");
    !email
      ? (setEmailError("Enter email address"), (formIsValid = false))
      : !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)
      ? (setEmailError("Enter a valid email address"), (formIsValid = false))
      : setEmailError("");
    !password
      ? (setPasswordError("Enter your Password"), (formIsValid = false))
      : password.length < 8
      ? (setPasswordError("Password must be at least 8 characters long"),
        (formIsValid = false))
      : setPasswordError("");
    !confirmPassword
      ? (setconfirmPasswordError("Enter your Password"), (formIsValid = false))
      : confirmPassword !== password
      ? (setconfirmPasswordError("Passwords do not match"),
        (formIsValid = false))
      : setconfirmPasswordError("");
    !formattedValue
      ? (setPhoneError("Enter your number"), (formIsValid = false))
      : !valid
      ? (setPhoneError("Please enter a valid phone number"),
        (formIsValid = false))
      : setPhoneError("");
    !policyChecked
      ? (setPolicyError(true), (formIsValid = false))
      : setPolicyError(false);
    return formIsValid;
  };
  function notifyMessage(msg: any) {
    if (Platform.OS === "ios") {
      Alert.alert(msg);
    } else {
      Toast.show(msg, {
        type: "normal",
        placement: "bottom",
        duration: 1000,
        offset: 0,
        animationType: "zoom-in",
      });
    }
  }
  const handleSubmit = () => {
    if (!validateInputs()) {
      return;
    } else {
      setIsActive(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
      var formdata = new FormData();
      formdata.append("username", userName);
      formdata.append("name", userName);
      formdata.append("email", email);
      formdata.append("password", password);
      formdata.append("Confirm_password", confirmPassword);
      formdata.append("phone", value);
      formdata.append("country_code", countryCode);
      formdata.append(
        "device_type",
        Platform.OS == "ios" ? "1" : Platform.OS == "android" ? "2" : "3"
      );
      formdata.append(
        "device_name",
        Platform.OS === "web" ? deviceName : `${manufacture} ${deviceName}`
      );
      formdata.append("device_uuid", Platform.OS != "web" ? deviceUid : "null");
      formdata.append(
        "device_token",
        Platform.OS != "web" ? DeviceToken : "74893274"
      );
      formdata.append("created_at", timezone);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
      };

      fetch(API.baseurl + API.register, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.status === 200) {
            notifyMessage(result.message);
            storeData(result?.data?.session_id, result?.data?.id);
            setIsActive(false);
            navigation.replace("Subscription");
          } else if (result.status === 400) {
            notifyMessage(result.message);
            setIsActive(false);
          } else {
            console.log("err");
            setIsActive(false);
            notifyMessage(result.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
          notifyMessage(error);
          setIsActive(false);
        });
    }
  };
  return (
    <ScrollView
      keyboardShouldPersistTaps="handled"
      style={{ flex: 1, backgroundColor: COLORS.white }}
    >
      <StatusBar
        animated={true}
        barStyle={"light-content"}
        backgroundColor={"transparent"}
        translucent={true}
      />
      <View style={styles.container}>
        <View style={styles.headerView}>
          <Pressable style={styles.img} onPress={() => navigation.goBack()}>
            <Image style={styles.img} source={LeftArrow} />
          </Pressable>
          <Image source={logoimg} style={{ width: 66, height: 64 }} />
        </View>
        <Text style={styles.txt1}>{t("Hey Welcome!")}</Text>
        <Text style={styles.txt2}>
          {t("Please enter your details to sign up")}
        </Text>
        <TextInput
          style={[
            styles.inputView,
            {
              ...(Platform.OS === "web" && {
                outlineColor: "white",
                outlineStyle: "none",
              }),
            },
          ]}
          placeholder={t("Username")}
          value={userName}
          onChangeText={setUserName}
          placeholderTextColor={COLORS.primarygraycolor}
          fontFamily={FONTS.PoppinsMedium}
          color={COLORS.primarygraycolor}
        />
        <View style={styles.errView}>
          {userNameError ? (
            <Text allowFontScaling={false} style={styles.err}>
              {userNameError}
            </Text>
          ) : null}
        </View>
        <TextInput
          style={[
            styles.inputView,
            {
              ...(Platform.OS === "web" && {
                outlineColor: "white",
                outlineStyle: "none",
              }),
            },
          ]}
          placeholder={t("Email")}
          value={email}
          onChangeText={setEmail}
          placeholderTextColor={COLORS.primarygraycolor}
          fontFamily={FONTS.PoppinsMedium}
          color={COLORS.primarygraycolor}
        />
        <View style={styles.errView}>
          {emailError ? (
            <Text allowFontScaling={false} style={styles.err}>
              {emailError}
            </Text>
          ) : null}
        </View>
        <View
          style={{
            flexDirection: "row",
            width: Platform.OS === "web" ? "50%" : width - 92,
            maxWidth: 436,
            minWidth: 325,
          }}
        >
          <TextInput
            style={[
              styles.inputView2,
              {
                paddingRight: "13%",
                ...(Platform.OS === "web" && {
                  outlineColor: "white",
                  outlineStyle: "none",
                }),
              },
            ]}
            placeholder={t("Password")}
            value={password}
            onChangeText={setPassword}
            placeholderTextColor={COLORS.primarygraycolor}
            fontFamily={FONTS.PoppinsMedium}
            color={COLORS.primarygraycolor}
            secureTextEntry={passVisibility}
          />
          <TouchableOpacity
            onPress={() => setPassVisibility(!passVisibility)}
            style={{
              position: "absolute",
              height: 20,
              width: 20,
              right: "5%",
              top: "30%",
            }}
          >
            <Image
              style={{ height: 20, width: 20 }}
              source={!passVisibility ? PasswordEye : PasswordcloseEye}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.errView}>
          {passwordError ? (
            <Text allowFontScaling={false} style={styles.err}>
              {passwordError}
            </Text>
          ) : null}
        </View>
        <View
          style={{
            flexDirection: "row",
            width: Platform.OS === "web" ? "50%" : width - 92,
            maxWidth: 436,
            minWidth: 325,
          }}
        >
          <TextInput
            style={[
              styles.inputView2,
              {
                paddingRight: "13%",
                ...(Platform.OS === "web" && {
                  outlineColor: "white",
                  outlineStyle: "none",
                }),
              },
            ]}
            placeholder={t("Confirm Password")}
            value={confirmPassword}
            onChangeText={setConfirmPassword}
            placeholderTextColor={COLORS.primarygraycolor}
            fontFamily={FONTS.PoppinsMedium}
            color={COLORS.primarygraycolor}
            secureTextEntry={confirmPassVisibility}
          />
          <TouchableOpacity
            style={{
              position: "absolute",
              height: 20,
              width: 20,
              right: "5%",
              top: "30%",
            }}
            onPress={() => setConfirmPassVisibility(!confirmPassVisibility)}
          >
            <Image
              style={{ height: 20, width: 20 }}
              source={!confirmPassVisibility ? PasswordEye : PasswordcloseEye}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.errView}>
          {confirmPasswordError ? (
            <Text allowFontScaling={false} style={styles.err}>
              {confirmPasswordError}
            </Text>
          ) : null}
        </View>
        <View style={styles.containerP}>
          {Platform.OS != "web" ? (
            <PhoneInput
              textInputStyle={{
                fontSize: 14,
                height: 50,
                borderWidth: 0,
                width: "80%",
              }}
              textContainerStyle={{
                backgroundColor: "transparent",
                fontFamily: FONTS.PoppinsMedium,
              }}
              codeTextStyle={{
                fontSize: 15,
                marginLeft: -20,
                lineHeight: 24,
                height: 25,
                width: 0,
                // borderWidth: 3,
              }}
              containerStyle={{
                alignSelf: "center",
                backgroundColor: "white",
                height: 50,
                width: "100%",
                borderWidth: 1,
                borderRadius: 8,
                borderColor: COLORS.primarybordercolor,
              }}
              ref={phoneInput}
              defaultValue={value}
              defaultCode={countryCode}
              layout="first"
              onChangeCountry={(country) => {
                setCountryCode(country.cca2);
                console.log("Selected country code:", country.cca2);
              }}
              onChangeText={(text) => {
                console.log("ssss", text);
                setValue(text);
              }}
              onChangeFormattedText={(text) => {
                setFormattedValue(text);
              }}
              withDarkTheme={false}
              withShadow={false}
              autoFocus={false}
            />
          ) : (
            <PhoneInput
              withShadow={false}
              textInputStyle={{
                outlineColor: "white",
                outlineStyle: "none",
                fontFamily: FONTS.PoppinsMedium,
              }}
              countryPickerButtonStyle={{
                width: 50,
                borderWidth: 0,
                minWidth: 60,
                outlineColor: "white",
                outlineStyle: "none",
              }}
              textContainerStyle={{
                backgroundColor: "transparent",
                fontFamily: FONTS.PoppinsMedium,
                flex: 1,
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                borderWidth: 0,
                outlineColor: "white",
                outlineStyle: "none",
                borderWidth: 0,
                width: width,
                fontFamily: FONTS.PoppinsMedium,
              }}
              containerStyle={{
                width: Platform.OS === "web" ? "100%" : width - 92,
                maxWidth: 436,
                minWidth: 325,
                borderWidth: 1,
                borderRadius: 8,
                height: 50,
                paddingRight: 20,
                borderColor: COLORS.primarybordercolor,
                flexDirection: "row",
                alignItems: "center",
                padding: 10,
                outlineColor: "white",
                outlineStyle: "none",
              }}
              ref={phoneInput}
              defaultValue={value}
              defaultCode={countryCode}
              layout="first"
              onChangeCountry={(country) => {
                setCountryCode(country.cca2);
                console.log("Selected country code:", country.cca2);
              }}
              onChangeText={(text) => {
                console.log("ssss", text);
                setValue(text);
              }}
              onChangeFormattedText={(text) => {
                setFormattedValue(text);
              }}
              withDarkTheme
              autoFocus
            />
          )}
        </View>
        {phoneError && (
          <View style={styles.policyErrView}>
            <Text allowFontScaling={false} style={styles.err}>
              {phoneError}
            </Text>
          </View>
        )}

        {isActive === true && (
          <View style={{ height: 20 }}>
            <ActivityIndicator size="small" color={COLORS.primary} />
          </View>
        )}

        <PolicyCheckBox
          isChecked={policyChecked}
          onChange={() => setPolicyChecked(!policyChecked)}
        />
        {PolicyError && (
          <View style={styles.policyErrView}>
            <Text style={styles.err}>
              {"Please read and accept Privacy policy and Terms for Sign up"}
            </Text>
          </View>
        )}
        <TouchableOpacity
          onPress={() => handleSubmit()}
          activeOpacity={0.8}
          style={[styles.Signinbtn, { marginTop: 34 }]}
        >
          <Text style={styles.txt5}>{t("Sign up")}</Text>
        </TouchableOpacity>
        <Pressable
          onPress={() => navigation.navigate("Signin")}
          style={{ marginTop: Platform.OS === "web" ? 50 : "24%" }}
        >
          <Text>
            <Text style={[styles.txt8, { color: COLORS.primarygraycolor }]}>
              {t("Already have an account ? ")}
            </Text>
            <Text style={[styles.txt8, { color: COLORS.primary }]}>
              {t(" Login")}
            </Text>
          </Text>
        </Pressable>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  containerP: {
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    // borderWidth: 1,
    // borderRadius: 8,
    // height: 50,
    // paddingRight: 20,
    // borderColor: COLORS.primarybordercolor,
    // fontFamily: FONTS.PoppinsMedium,
    // flexDirection: 'row',
    // alignItems: 'center',
    // padding: 10,
  },
  CodePopupStyle: {
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderWidth: 1,
    borderRadius: 8,
    maxHeight: 700,
    paddingRight: 20,
    borderColor: COLORS.primarybordercolor,
    fontFamily: FONTS.PoppinsMedium,
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
  callingCode: {
    fontSize: 16,
    fontFamily: FONTS.PoppinsMedium,
    color: COLORS.primarygraycolor,
    marginRight: 10,
  },
  inputViewP: {
    flex: 1,
    height: 40,
    fontFamily: FONTS.PoppinsMedium,
    color: COLORS.primarygraycolor,
  },
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
    padding: 20,
    alignItems: "center",
  },
  headerView: {
    width: "100%",
    flexDirection: "row",
    marginTop: Platform.OS === "web" ? 0 : 30,
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    height: 24,
    width: 24,
    position: "absolute",
    left: 0,
    top: 7,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 22,
    fontWeight: "600",
    color: COLORS.primary,
    fontFamily: FONTS.PoppinsMedium,
  },
  txt1: {
    ontFamily: "Poppins-SemiBold",
    fontWeight: "600",
    fontSize: 15,
    letterSpacing: 0.84,
    color: COLORS.black,
    marginTop: Platform.OS === "web" ? 20 : 30,
  },
  txt2: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.black,
    marginBottom: 47,
  },
  inputView: {
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderWidth: 1,
    borderRadius: 8,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    borderColor: COLORS.primarybordercolor,
    fontFamily: FONTS.PoppinsMedium,
  },
  inputView2: {
    width: Platform.OS === "web" ? "100%" : "100%",
    borderWidth: 1,
    borderRadius: 8,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    borderColor: COLORS.primarybordercolor,
    fontFamily: FONTS.PoppinsMedium,
  },
  checkbox: {
    height: 16,
    width: 16,
    marginRight: "3%",
  },
  txt3: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "400",
    color: COLORS.primarygraycolor,
  },
  txt4: {
    marginTop: 33,
    textDecorationLine: "underline",
    fontFamily: FONTS.PoppinsMedium,
    fontWeight: "300",
    fontSize: 14,
    color: COLORS.primary,
  },
  Signinbtn: {
    marginTop: Platform.OS === "web" ? 20 : 85,
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderRadius: 46,
    height: 50,
    backgroundColor: COLORS.primary,
    justifyContent: "center",
    alignItems: "center",
  },
  txt5: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.white,
  },
  view2: {
    flexDirection: "row",
    width: Platform.OS === "web" ? 283 : width - 92,
    justifyContent: "space-between",
    height: 30,
    alignItems: "center",
    marginTop: 34,
  },
  txt6: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "300",
    color: COLORS.primarygraycolor,
  },
  googlebtn: {
    height: 50,
    width: Platform.OS === "web" ? 283 : width - 92,
    borderRadius: 46,
    borderWidth: 1,
    borderColor: COLORS.primarybordercolor,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 13,
  },
  txt7: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
  },
  txt8: {
    fontFamily: FONTS.PoppinsRegular,
    fontSize: 14,
    fontWeight: "500",
  },
  err: {
    fontSize: 10,
    color: "red",
    fontFamily: FONTS.PoppinsMedium,
  },
  errView: {
    fontSize: 10,
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    color: "red",
    fontFamily: FONTS.PoppinsMedium,
    borderWidth: 0,
    height: 17,
    borderWidth: 0,
  },
  policyErrView: {
    fontSize: 10,
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    color: "red",
    fontFamily: FONTS.PoppinsMedium,
    borderWidth: 0,
    height: 17,
    borderWidth: 0,
  },
});
