
import { ColorValue } from "react-native";
import Svg, { Defs, G, Path, Rect, Use } from "react-native-svg"
interface StyledSvgProps {
    color?: ColorValue
}
export const ProductIcon = (props: StyledSvgProps) => {
    const { color, ...otherProps } = props;
    return (
        <Svg width="120" height="120" viewBox="0 0 120 120" fill="none" {...otherProps}>
            <G clip-path="url(#clip0_247_2241)">
                <Path d="M59.7144 9.14258L60.5716 9.28544L65.2859 11.5711L68.7144 13.1426L73.8573 15.5711L77.7144 17.4283L81.7144 19.2854L85.5716 21.1426L89.5716 22.9997L93.4287 24.8569L97.4287 26.714L101.857 28.8569L104.286 29.9997L104.429 30.2854L101.714 31.714L99.7144 32.8569L97.143 34.2854L93.7144 36.1426L90.4287 37.9997L86.4287 40.1426L83.143 41.9997L80.143 43.5712L76.8573 45.4283L74.4287 46.714L71.143 48.5712L67.8573 50.2854L64.5716 52.1426L60.8573 54.1426L60.143 54.5712L59.4287 54.4283L56.143 52.714L53.0001 50.9997L48.2859 48.5712L45.143 46.8569L40.143 44.2854L37.0001 42.5712L32.5716 40.2854L29.4287 38.5712L25.0001 36.2854L21.8573 34.5712L17.7144 32.4283L15.4287 31.1426L15.8573 30.714L19.143 29.1426L22.5716 27.4283L25.8573 25.8569L33.8573 21.8569L38.5716 19.5712L43.143 17.2854L46.4287 15.714L50.7144 13.5711L54.0001 11.9997L58.2859 9.85686L59.7144 9.14258Z" fill={color || "#D7D7D7"} />
                <Path d="M11.2859 63.5713L12.1431 63.857L15.5716 65.857L18.1431 67.2856L21.5716 69.2856L24.1431 70.7141L27.5716 72.7141L31.1431 74.7141L34.5716 76.7141L38.1431 78.7141L41.5716 80.7141L42.8574 81.4284L43.4288 81.5713H44.8574L46.0002 81.1427L46.8574 80.4284L48.4288 78.4284L50.2859 76.1427L51.8574 74.1427L53.7145 71.857L55.5716 69.4284L56.2859 68.5713L56.5716 68.7141V110.857L55.8574 110.571L51.1431 108.143L48.0002 106.428L44.4288 104.571L41.2859 102.857L36.2859 100.286L33.1431 98.5713L28.7145 96.2856L25.5716 94.5713L20.8574 92.1427L17.7145 90.4284L14.1431 88.5713L11.5716 87.1427L11.2859 86.857L11.1431 63.7141L11.2859 63.5713Z" fill={color || "#D7D7D7"} />
                <Path d="M108.286 63.5713H108.714V85.857L107.571 86.5713L103.571 88.7141L100.286 90.5713L96.8571 92.4284L93.5714 94.2856L90.8571 95.7141L84.4285 99.2856L80.7142 101.286L77.5714 103L69.2856 107.571L65.2856 109.714L63.5714 110.714H63.4285L63.2856 109V68.5713L63.8571 68.7141L64.4285 69.5713L65.9999 71.5713L67.8571 73.857L69.4285 75.857L71.4285 78.2856L72.4285 79.5713L72.8571 80.1427V80.4284H73.1428L73.8571 81.1427L74.9999 81.5713H76.4285L77.8571 80.9999L80.2856 79.5713L83.5714 77.7141L86.9999 75.7141L90.5714 73.7141L94.2856 71.5713L106.286 64.7141L108.286 63.5713Z" fill={color || "#D7D7D7"} />
                <Path d="M110.714 34.5713L111.286 34.857L112.714 37.2856L114.286 39.7141L116.572 43.2856L117.714 45.1427L119.857 48.4284L120 49.1427L117.572 50.4284L114.143 52.4284L110.857 54.2856L107.429 56.2856L103.857 58.2856L101.429 59.7141L97.4288 61.9999L93.7145 64.1427L90.1431 66.1427L86.7145 68.1427L83.4288 69.9999L79.7145 72.1427L78.0002 73.1427L77.0002 73.7141L76.2859 73.5713L74.4288 71.2856L73.4288 69.9999L70.5716 66.4284L69.0002 64.4284L67.1431 62.1427L65.5716 60.1427L65.1431 59.5713L66.2859 58.857L69.0002 57.4284L72.1431 55.7141L74.4288 54.4284L77.8574 52.5713L81.1431 50.7141L85.1431 48.5713L89.2859 46.2856L92.5716 44.4284L95.0002 43.1427L98.1431 41.4284L101.572 39.5713L108 35.9999L110.714 34.5713Z" fill={color || "#D7D7D7"} />
                <Path d="M8.71429 35.5713L9.42857 35.7141L12.5714 37.2856L15.7143 38.9999L19.8571 41.1427L22.5714 42.5713L26.1429 44.4284L27.8571 45.4284L30.1429 46.5713L32.7143 47.9999L35 49.1427L38.2857 50.857L41.4286 52.5713L45.5714 54.7141L48.7143 56.4284L52.5714 58.4284L54.4286 59.4284V59.9999L51.2857 63.9999L50 65.5713L48.4286 67.5713L46.5714 69.857L45.1429 71.7141L43.7143 73.4284L43.4286 73.857L42.7143 73.5713L39.1429 71.5713L35.7143 69.5713L32.4286 67.7141L29 65.7141L25.4286 63.7141L22 61.7141L19.4286 60.2856L16 58.2856L12.4286 56.2856L2.28571 50.4284L0 49.1427V48.5713H0.285714L0.571429 47.857L1.57143 46.4284L6.42857 39.1427L8.57143 35.857L8.71429 35.5713Z" fill={color || "#D7D7D7"} />
            </G>
            <Defs>
                {/* <clipPath id="clip0_247_2241">
                    <Rect width="120" height="120" fill="white" />
                </clipPath> */}
            </Defs>
        </Svg>
    )
}
