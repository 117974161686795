import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
} from "react-native";
import { useTranslation } from "react-i18next";
import { API, COLORS, FONTS } from "../../../utils";
import SuppplementnewsWeb from "../../components/supplementnewsWeb";
import CommonHeader from "../../components/CommonHeader";
import { FlatGrid } from "react-native-super-grid";
import { OrientationContext } from "../../components/OrientationContext";
const News = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isLandscape = useContext(OrientationContext);
  const [removeFocusTrigger, setRemoveFocusTrigger] = useState(false);

  useEffect(() => {
    fetchBlogs();
  }, []);
  const fetchBlogs = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API.baseurl}blogsbytagname?tag=`, {
        method: "GET",
        headers: { Authorization: "Basic YWRtaW46MTIzNA==" },
      });
      const result = await response.json();
      if (result.status === 200) setProducts(result.data);
      else Alert.alert("Error", result.message);
    } catch (error) {
      console.error(error);
      Alert.alert("Error", error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const removeDropdown = () => {
    setRemoveFocusTrigger(false);
  };
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        removeDropdown();
        Keyboard.dismiss();
      }}
    >
      <View
        style={[styles.container, isLandscape && styles.landscapeContainer]}
      >
        <CommonHeader
          marginTop={140}
          // title={t("News")}
          title={t("Supplements in the news")}
          showFilter={false}
          onRemoveFocus={removeFocusTrigger}
          // onRemoveFocus={removeDropdown}
        />
        {isLoading ? (
          <ActivityIndicator
            size="large"
            color={COLORS.primary}
            style={{ marginTop: 30 }}
          />
        ) : (
          <View
            style={[styles.content, isLandscape && styles.landscapeContent]}
          >
            <FlatGrid
              showsVerticalScrollIndicator={true}
              keyExtractor={(item) => item.id.toString()}
              data={products}
              itemDimension={Platform.OS === "web" ? 380 : 900}
              ListFooterComponent={<View style={{ height: 100 }} />}
              renderItem={({ item }) => (
                <SuppplementnewsWeb item={item} marginBottom={5} />
              )}
            />
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default News;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  landscapeContainer: {
    marginLeft: 75,
  },
  content: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    maxWidth: 1200,
  },
  landscapeContent: {
    width: "100%",
  },
  headerText: {
    fontFamily: FONTS.PoppinsSemiBold,
    fontSize: 17,
    color: COLORS.primary,
    paddingLeft: Platform.OS === "web" ? 20 : 10,
    marginBottom: 6,
    paddingTop: 3,
    paddingBottom: 10,
  },
});
