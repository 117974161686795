import React, { useEffect, useState, useContext } from "react";
import {
  ActivityIndicator,
  StatusBar,
  View,
  Text,
  StyleSheet,
  Dimensions,
  Image,
  Platform,
  TouchableOpacity,
  ScrollView,
  Pressable,
  Alert,
} from "react-native";
import { API, COLORS, FONTS } from "../../../utils";
import fill from "../../assets/images/fillstar.png";
import unfill from "../../assets/images/unfillstar.png";
import StarRating from "../../components/starrating";
import { useTranslation } from "react-i18next";
import { FlatGrid } from "react-native-super-grid";
import { useNavigation, useRoute } from "@react-navigation/native";
import CommonHeader from "../../components/CommonHeader";
import SkeletonLoader from "../../components/SkeletonLoader";
import { OrientationContext } from "../../components/OrientationContext";
const { width, height } = Dimensions.get("window");
const Products = () => {
  const [removeFocusTrigger, setRemoveFocusTrigger] = useState(false);
  const route = useRoute();
  const isLandscape = useContext(OrientationContext);
  const Silver = require("../../assets/images/silver.png");
  const Gold = require("../../assets/images/gold.png");
  const dimond = require("../../assets/images/dimond.png");
  const Platinum = require("../../assets/images/platinum.png");
  const { t } = useTranslation();
  const [filteredData, setFilteredData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const navigation = useNavigation();
  const renderItem = ({ item }) => {
    const updatedDate = item.updated_at.split(" ")[0];
    return (
      <TouchableOpacity
        activeOpacity={0.6}
        onPress={() => {
          if (item?.countries_iso_code?.length > 0) {
            navigation.navigate("Graph", {
              item: item,
              previousRoute: route.name,
            });
          }
        }}
        style={styles.itemContainer}
      >
        <View style={styles.itemView}>
          <View
            style={[
              styles.itemContentView,
              item?.medal_status > 0 && item?.medal_status <= 4
                ? { width: "85%", minWidth: "85%" }
                : { width: "100%", minWidth: "100%" },
            ]}
          >
            <View style={[styles.itemNameView, { borderWidth: 0 }]}>
              <Text style={styles.combinedText}>
                {item?.company_name} {item?.product_name}
              </Text>
            </View>
            <View style={styles.countryNameView}>
              {item?.countries_name == null ? (
                <Text style={styles.countryName}></Text>
              ) : (
                <Text style={styles.countryName}>
                  {"(" + item?.countries_name + ")"}
                </Text>
              )}
            </View>
            <View style={styles.ratingView}>
              <StarRating
                rating={item?.star_rating}
                customStarImage={{ filled: fill, empty: unfill }}
                height={10}
                width={11}
              />
              <Text allowFontScaling={false} style={styles.rating}>
                {item?.star_rating}
              </Text>
            </View>
          </View>
          {item?.medal_status > 0 && item?.medal_status <= 4 && (
            <View style={styles.medalView}>
              <Image
                source={getMedalImage(item?.medal_status)}
                style={styles.medalImage}
              />
            </View>
          )}
        </View>

        {updatedDate !== "0000-00-00" && (
          <Text allowFontScaling={false} style={styles.date}>
            {t("last updated")} : {updatedDate}
          </Text>
        )}
      </TouchableOpacity>
    );
  };

  const getMedalImage = (medalStatus) => {
    const medalImages = {
      "1": Platinum,
      "2": dimond,
      "3": Gold,
      "4": Silver,
    };

    return medalImages[medalStatus] || null; // Return null if the medal status doesn't match any keys
  };
  
  const removeDropdown = () => {
    setRemoveFocusTrigger((prev) => !prev);
  };
  return (
    <Pressable
      onPress={removeDropdown}
      style={{
        flex: 1,
        height: Platform.OS == "web" ? height * 100 : height,
        marginLeft: isLandscape ? 75 : 0,
      }}
    >
      <CommonHeader
        marginTop={140}
        title={t("Products")}
        showFilter={true}
        onDataFilter={(data) => {
          setFilteredData(data);
          setIsActive(false);
        }}
        onRemoveFocus={removeFocusTrigger}
      />
      <View style={[styles.container, { width: "100%" }]}>
        <StatusBar
          animated={true}
          barStyle={"light-content"}
          backgroundColor={"transparent"}
          translucent={true}
        />
        {isActive ? (
          <>
          <Text style={styles.listHeaderText}>{t("Products")}</Text>
          <FlatGrid
            showsVerticalScrollIndicator={true}
            bounces={false}
            ListFooterComponent={() => <View style={{ height: 100 }}></View>}
            itemDimension={Platform.OS === "web" ? 380 : 900}
            data={[1, 1, 1, 1, 1, 1]}
            style={styles.gridView}
            renderItem={() => (
              <View
                style={[
                  styles.itemContainer,
                  {
                    padding: 13,
                    marginLeft: 0,
                    minWidth: "100%",
                    width: "100%",
                    height: 70,
                  },
                ]}
              >
                <SkeletonLoader width={"80%"} height={10} marginBottom={7} />
                <SkeletonLoader width={"30%"} height={10} marginBottom={7} />
                <SkeletonLoader width={"50%"} height={10} marginBottom={7} />
              </View>
            )}
          />
          </>
        
        ) : (
          <FlatGrid
            showsVerticalScrollIndicator={true}
            bounces={false}
            ListHeaderComponent={() => (
              <View>
                {filteredData == null ? (
                  <Text style={styles.noResultsText}>
                    {t(
                      "Oops! No results match your selected filters. Please try adjusting your search criteria for better results."
                    )}
                  </Text>
                ) : (
                  <Text style={styles.listHeaderText}>{t("Products")}</Text>
                )}
              </View>
            )}
            ListFooterComponent={() => <View style={{ height: 100 }}></View>}
            itemDimension={Platform.OS === "web" ? 380 : 900}
            data={filteredData || []}
            style={styles.gridView}
            renderItem={renderItem}
          />
        )}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 1200,
    margin: "auto",
  },
  loaderContainer: {
    flex: 1,
    padding: 10,
    alignItems: "center",
    width: 415,
  },
  listViewContainer: {
    width: Platform.OS === "web" ? (width <= 500 ? "100%" : "72%") : "100%",
    height: "100%",
    alignSelf: "center",
    borderWidth: 0,
    marginBottom: Platform.OS === "web" ? 224 : 0,
  },
  noResultsText: {
    fontFamily: FONTS.PoppinsSemiBold,
    fontWeight: Platform.OS === "web" ? 0 : "600",
    fontSize: 17,
    color: COLORS.primary,
    textAlign: "center",
    marginTop: 100,
    paddingLeft: 20,
    paddingRight:20,
  },

  gridView: {
    flex: 1,
    paddingBottom: 120,
  },
  listHeaderText: {
    fontFamily: FONTS.PoppinsSemiBold,
    fontWeight: Platform.OS === "web" ? 0 : "600",
    fontSize: 17,
    color: COLORS.primary,
    marginLeft: 20,
    paddingTop: Platform.OS == "web" ? 3 : 0,
    paddingBottom: Platform.OS == "web" ? 10 : Platform.OS == "ios" ? 10 : 0,
  },
  itemContainer: {
    marginBottom: 2,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: COLORS.primarybordercolor,
    marginRight: 10,
    marginLeft: 10,
    backgroundColor: "white",
    paddingBottom: 13,
    borderWidth: 1,
    padding: 5,
    width: "auto",
  },
  itemView: {
    flexDirection: "row",
  },
  itemContentView: {
    maxWidth: "100%",
    paddingTop: 8,
    paddingLeft: 10,
  },
  itemNameView: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  combinedText: {
    fontFamily: FONTS.PoppinsMedium,
    fontWeight: "500",
    fontSize: 14,
    color: COLORS.primary,
    flexShrink: 1,
  },
  companyName: {
    fontFamily: FONTS.PoppinsMedium,
    fontWeight: "700",
    fontSize: 14,
    color: COLORS.primary,
  },
  productName: {
    fontFamily: FONTS.PoppinsMedium,
    fontWeight: "700",
    fontSize: 14,
    color: COLORS.primary,
    marginLeft: 3,
    marginRight: 10,
  },
  countryNameView: {
    flexDirection: "row",
    alignItems: "center",
  },
  countryName: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 12,
    color: COLORS.primarygraycolor,
    marginVertical: 4,
  },
  ratingView: {
    flexDirection: "row",
    alignItems: "center",
    height: 13,
  },
  rating: {
    marginLeft: 10,
    color: COLORS.black,
    fontFamily: FONTS.PoppinsMedium,
    // fontWeight: "500",
    fontSize: 12,
  },
  medalView: {
    width: "14%",
    marginTop: -12,
    // borderColor: '#000',
    // borderWidth: 1,
    right: -10,
    position: 'absolute'
  },
  medalImage: {
    height: 35,
    width: 35,
  },
  date: {
    bottom: 5,
    fontFamily: FONTS.PoppinsRegular,
    fontSize: 12,
    fontWeight: "400",
    color: COLORS.primarygraycolor,
    lineHeight: 18,
    alignSelf: "flex-end",
    position: "absolute",
    right: 10,
  },
  activityIndicator: {
    flex: 1,
  },
});

export default Products;
