// import i18next from 'i18next';
// import {initReactI18next} from 'react-i18next';
// import en from '../locales/en.json';
// import ko from '../locales/ko.json'
// import ch from '../locales/ch.json'
// import fr from '../locales/fr.json'
// import sp from  '../locales/sp.json'

// export const languageResources = {
//   en: {translation: en},
//   ko: {translation: ko},
//   ch: {translation: ch},
//   fr: {translation: fr},
//   sp: {translation: sp},
// };

// i18next.use(initReactI18next).init({
//   compatibilityJSON: 'v3',
//   lng: 'en',
//   fallbackLng: 'en',
//   resources: languageResources,
// });

// export default i18next;



import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage'; 
import en from '../locales/en.json';
import ko from '../locales/ko.json';
import ch from '../locales/ch.json';
import fr from '../locales/fr.json';
import sp from '../locales/sp.json';

export const languageResources = {
  en: { translation: en },
  ko: { translation: ko },
  ch: { translation: ch },
  fr: { translation: fr },
  sp: { translation: sp },
};

const getLanguage = async () => {
  try {
    const value = await AsyncStorage.getItem('Language');
    if (value !== null) {
      return value; 
    }
  } catch (e) {
    console.error('Error reading value from AsyncStorage:', e);
  }
  return 'en'; 
};

const initI18next = async () => {
  const language = await getLanguage();

  i18next.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: language, 
    fallbackLng: 'en',
    resources: languageResources,
  });
};

initI18next(); 

export default i18next;
