

import React from 'react';
import { View, Text, StyleSheet, Dimensions, Image, TouchableOpacity, Platform } from 'react-native';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { COLORS, FONTS } from '../../utils';

// Define the type for the props
interface SupplementNewsWebProps {
  item: {
    title: string;
    tags: string;
    published_at: string;
    image?: {
      src: string;
    };
  };
  marginBottom?: number;

}

const SupplementNewsWeb: React.FC<SupplementNewsWebProps> = ({ item, marginBottom }) => {
  const navigation = useNavigation<NavigationProp<any>>();
  const dateOnly = new Date(item.published_at).toISOString().split('T')[0];

  return (

    <TouchableOpacity
      activeOpacity={0.7}
      onPress={() => navigation.navigate('BlogScreen', { item })}
      // style={[styles.container, { marginBottom },]}
      style={[
        styles.container,
        { marginBottom },
        Platform.OS === 'web' ? { minHeight: 120, height: 120 } : {}
      ]}
    >
      <View style={styles.imageContainer}>
        <Image
          style={styles.image}
          source={
            item.image?.src
              ? { uri: item.image.src }
              : require('../assets/images/logoimg.png')
          }
        />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.title} numberOfLines={2}>{item.title}</Text>
        <Text style={styles.tags} numberOfLines={2}>{item.tags}</Text>
        <Text style={styles.date}>{dateOnly}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default SupplementNewsWeb;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: COLORS.primarybordercolor,
    borderRadius: 16,
    padding: 3,
    marginHorizontal: 4,
    // minHeight: 120,
    // height: 120,
    marginBottom: 10
  },
  imageContainer: {
    width: '29%',
    height: '100%',
    maxHeight: 120,
    padding: 9,
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: 16,
    resizeMode: 'cover',
  },
  textContainer: {
    width: '71%',
    paddingVertical: 9,
    paddingRight: 14,
  },
  title: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 18,
    color: COLORS.primary,
    fontFamily: FONTS.PoppinsMedium,
    marginBottom: 5,
  },
  tags: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 18,
    color: 'black',
    fontFamily: FONTS.PoppinsMedium,
    marginBottom: 5,
  },
  date: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 18,
    color: COLORS.primarygraycolor,
    fontFamily: FONTS.PoppinsRegular,
  },
});
