
import React, { useState,useContext } from 'react';
import {
    View, Text, StyleSheet, Dimensions, Image, TouchableOpacity,
    StatusBar, ActivityIndicator, Alert, TextInput, Pressable, ScrollView
} from 'react-native';
import { Platform } from 'react-native';
import { API, COLORS, FONTS } from '../../../utils';
import { Toast } from 'react-native-toast-notifications';
import { useTranslation } from 'react-i18next';
import key from '../../assets/images/key.png';
import { OrientationContext } from '../../components/OrientationContext'
const { width, height } = Dimensions.get('screen');
const ForgotPassword: React.FC<{ navigation: any }> = ({ navigation }) => {
    const isLandscape = useContext(OrientationContext);
    const { t } = useTranslation();
    const [loader, setLoader] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [emailErr, setEmailErr] = useState<string>('');
    const LeftArrow = require('../../assets/images/arrow-left.png');
    const validateInputs = () => {
        let formIsValid: boolean = true;
        const emailRegex: RegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!email) {
            setEmailErr('Enter email address');
            formIsValid = false;
        } else if (!emailRegex.test(email)) {
            setEmailErr('Enter a valid email address');
            formIsValid = false;
        } else {
            setEmailErr('');
        }
        return formIsValid;
    };
    const notifyMessage = (msg: string) => {
        if (Platform.OS === 'ios') {
            Alert.alert(msg);
        }
        else {
            Toast.show(msg, {
                type: "normal",
                placement: "bottom",
                duration: 1200,
                offset: 30,
                animationType: "zoom-in",
            });
        }
    }
    const handleSubmit = () => {
        if (!validateInputs()) {
            console.log('Error', 'Please fill in all fields correctly');
            return;
        } else {
            handleRegister();
        }
    };
    const handleRegister = () => {
        setLoader(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
        var formdata = new FormData();
        formdata.append("email", email);
        formdata.append("type", "1");
        var requestOptions: any = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
            // mode: ' // mode: 'no- cors''
        };

        fetch(API.baseurl + API.forgetpassword, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.status === 400) {
                    notifyMessage(result?.message);
                    setLoader(false);
                } else if (result?.status === 200) {
                    notifyMessage(result?.message);
                    navigation.navigate('VerifyEmail', { email: email });
                    setLoader(false);
                } else {
                    notifyMessage(result?.message);
                }
            })
            .catch(error => {
                console.log('error', error), notifyMessage(error);
            });
    }
    return (
        <ScrollView style={{ flex: 1, backgroundColor: 'white' }}>
            <StatusBar
                animated={true}
                barStyle={'light-content'}
                backgroundColor={'transparent'}
                translucent={true}
            />
            <View style={styles.container}>
                <View style={styles.headerView}>
                    <Pressable
                        style={styles.img}
                        onPress={() => navigation.goBack()}>
                        <Image
                            style={styles.img}
                            source={LeftArrow}
                        />
                    </Pressable>
                    <Text allowFontScaling={false} style={styles.title}>NutriSearch</Text>
                </View>
                <Image
                    source={key}
                    style={{ width: 73, height: 62, marginTop: Platform.OS === 'web' ? '2%' : "12%" }}
                />
                <Text  style={[styles.txt6, { marginTop: 25, color: 'black', }]}>{t("Forgot Password")}</Text>
                <Text  style={styles.txt7}>{t("Don’t worry! It happens to all of us and ")}</Text>
                <Text  style={styles.txt7}>{t("we’re here to help! ")}</Text>
                <View style={{ height: 1, backgroundColor: 'black', width: Platform.OS === 'web' ? '50%' : width - 100, maxWidth: 436, minWidth: 325, marginVertical: 10 }} />
                <Text  style={styles.txt7}>{t("Please enter your email address then")}</Text>
                <Text  style={styles.txt7}>{t(" we will sent a verification code to you ")}</Text>
                <TextInput
                    
                    style={[styles.inputView, { ...(Platform.OS === 'web' && { outlineColor: 'white', outlineStyle: 'none', }) }]}
                    placeholder={t('Email')}
                    value={email}
                    onChangeText={setEmail}
                    fontFamily={FONTS.PoppinsMedium}
                    color={COLORS.primarygraycolor}
                    placeholderTextColor={COLORS.primarygraycolor}
                />
                {emailErr ? <Text allowFontScaling={false} style={styles.err}>{emailErr}</Text> : <Text allowFontScaling={false} style={[styles.err, { color: 'white' }]}>d</Text>}
                {loader === true ? <ActivityIndicator color={COLORS.primary} /> : <ActivityIndicator color={COLORS.white} />}
                <TouchableOpacity
                    onPress={() => handleSubmit()}
                    activeOpacity={0.8}
                    style={styles.Signinbtn}
                >
                    <Text  style={styles.txt5}>{t("Continue")}</Text>
                </TouchableOpacity>
                <Pressable
                    onPress={() => navigation.navigate('Signin')}
                    style={{ flexDirection: 'row', marginTop: Platform.OS === 'web' ? '5%' : height - 670 }}>
                    <Text  style={{ fontWeight: '400', fontSize: 14, lineHeight: 21, color: COLORS.primarygraycolor, fontFamily: FONTS.PoppinsRegular }}>{t("Back to ")}</Text>
                    <Text  style={{ fontWeight: '400', fontSize: 14, lineHeight: 21, color: COLORS.primary, fontFamily: FONTS.PoppinsRegular }}>{t(" Log in")}</Text>
                </Pressable>
            </View>
        </ScrollView>
    )
}

export default ForgotPassword;
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.white,
        padding: 20,
        alignItems: 'center',
    },
    headerView: {
        width: '100%',
        flexDirection: 'row',
        marginTop: Platform.OS === 'web' ? 0 : 35,
        alignItems: 'center',
        justifyContent: 'center',
    },
    img: {
        height: 24,
        width: 24,
        position: 'absolute',
        left: 0,
        top: 7,
    },
    title: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 22,
        fontWeight: '600',
        color: COLORS.white,
        fontFamily: FONTS.PoppinsMedium,
    },
    txt1: {
        fontFamily: FONTS.PoppinsMedium,
        fontWeight: '700',
        fontSize: 15,
        letterSpacing: 0.84,
        color: COLORS.black,
        marginTop: Platform.OS === 'web' ? 20 : 51,
    },
    txt2: {
        fontFamily: FONTS.PoppinsMedium,
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.black,
    },
    inputView: {
        width: Platform.OS === 'web' ? '50%' : width - 92,
        maxWidth: 436,
        minWidth: 325,
        borderWidth: 1,
        borderRadius: 8,
        height: 50,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 40,
        borderColor: COLORS.primarybordercolor,
        fontFamily: FONTS.PoppinsMedium,
    },
    checkbox: {
        height: 16,
        width: 16,
        marginRight: '3%',
    },
    txt3: {
        fontFamily: FONTS.PoppinsMedium,
        fontSize: 14,
        fontWeight: '400',
        color: COLORS.primarygraycolor,
    },
    txt4: {
        marginTop: 33,
        textDecorationLine: 'underline',
        fontFamily: FONTS.PoppinsMedium,
        fontWeight: '300',
        fontSize: 14,
        color: COLORS.primary,
    },
    Signinbtn: {
        marginTop: Platform.OS === 'web' ? 20 : 30,
        width: Platform.OS === 'web' ? '50%' : width - 92,
        maxWidth: 436,
        minWidth: 325,
        borderRadius: 46,
        height: 50,
        backgroundColor: COLORS.primary,
        justifyContent: 'center',
        alignItems: 'center',
    },
    txt5: {
        fontFamily: FONTS.PoppinsMedium,
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.white,
    },
    txt6: {
        // fontWeight: '700',
        fontSize: 18,
        lineHeight: 27,
        // fontFamily: FONTS.PoppinsMedium,
        letterSpacing: 1,
        fontFamily: FONTS.PoppinsMedium,
        fontWeight: Platform.OS === 'web' ? 0 : '600',

    },
    txt7: {
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 21,
        letterSpacing: 0.5,
        color: 'black',
        fontFamily: FONTS.PoppinsMedium,
    },
    err: {
        lineHeight: 16,
        fontSize: 10,
        width: Platform.OS === 'web' ? '50%' : width - 92,
        maxWidth: 436,
        minWidth: 325,
        color: 'red',
        fontFamily: FONTS.PoppinsMedium,
    },
});
