import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Modal, Platform, Image, Dimensions } from 'react-native';
import { WebView } from 'react-native-webview';
import Checkbox from 'expo-checkbox';
import { COLORS, FONTS } from '../../utils';
const { width, height } = Dimensions.get('screen')
const PolicyCheckBox = ({ isChecked, onChange }) => {
    const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
    const [termsModalVisible, setTermsModalVisible] = useState(false);
    const leftarrow = require('../assets/images/whiteleftarrow.png');
    const handlePrivacyLinkPress = () => {
        setPrivacyModalVisible(true);
    };

    const handleTermsLinkPress = () => {
        setTermsModalVisible(true);
    };

    const handleClosePrivacyModal = () => {
        setPrivacyModalVisible(false);
    };

    const handleCloseTermsModal = () => {
        setTermsModalVisible(false);
    };

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row', marginTop: -15, width: Platform.OS === 'web' ? '50%' : width - 92, maxWidth: 436, minWidth: 340, alignItems: 'center' }}>
                <Checkbox style={{ height: 20, width: 20, marginTop: 2, }} value={isChecked} onValueChange={onChange} tintColors={{ true: '#2C648E', false: COLORS.primarybordercolor }} color={isChecked ? COLORS.primary : undefined} />
                <Text style={[styles.txt3, { marginTop: 1.5 }]}>
                    I have read and agree to the{' '}
                    <Text style={styles.link} onPress={handlePrivacyLinkPress}>Privacy Policy</Text>
                    {' '}and{' '}
                    <Text style={styles.link} onPress={handleTermsLinkPress}>Terms and Conditions</Text></Text>
            </View>
            <View style={{ flexDirection: 'row', width: width - 92, alignItems: 'left' }}>
            </View>

            {/* Privacy Policy Modal */}
            <Modal
                animationType="slide"
                transparent={true}
                visible={privacyModalVisible}
                onRequestClose={handleClosePrivacyModal}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <View style={{
                            width: '100%', height: 70, zIndex: 1000,
                            backgroundColor: COLORS.primary,
                            paddingTop: 24, paddingLeft: 20, flexDirection: 'row',
                        }}>
                            <TouchableOpacity
                                onPress={handleClosePrivacyModal}
                            >
                                <Image
                                    source={leftarrow}
                                    defaultSource={leftarrow}
                                    style={{ height: 23, width: 23 }}
                                />
                            </TouchableOpacity>

                            <View style={{ width: Platform.OS === 'web' ? '90%' : '85%' }}>
                                <Text numberOfLines={2} style={{
                                    color: 'white', fontFamily: 'Poppins-SemiBold',
                                    fontWeight: Platform.OS === 'web' ? 0 : '600', fontSize: 14, marginLeft: 5, textAlign: 'center',
                                }}>Privacy Policy</Text>
                            </View>
                        </View>
                        <View style={{ marginTop: -350, flex: 1, zIndex: 1 }}>
                            {Platform.OS === 'web' ? (
                                <iframe
                                    src="https://nutrisearch.ca/privacy.html"
                                    style={styles.webView}
                                    title="Privacy Policy"
                                />
                            ) : (
                                <WebView source={{ uri: 'https://nutrisearch.ca/privacy.html' }} style={styles.webView} />
                            )}
                        </View>
                    </View>
                </View>
            </Modal>

            {/* Terms and Conditions Modal */}
            <Modal
                animationType="slide"
                transparent={true}
                visible={termsModalVisible}
                onRequestClose={handleCloseTermsModal}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <View style={{
                            width: '100%', height: 70, zIndex: 1000,
                            backgroundColor: COLORS.primary,
                            paddingTop: 24, paddingLeft: 20, flexDirection: 'row',
                        }}>
                            <TouchableOpacity
                                onPress={handleCloseTermsModal}
                            >
                                <Image
                                    source={leftarrow}
                                    defaultSource={leftarrow}
                                    style={{ height: 23, width: 23 }}
                                />
                            </TouchableOpacity>

                            <View style={{ width: Platform.OS === 'web' ? '90%' : '85%' }}>
                                <Text numberOfLines={2} style={{
                                    color: 'white', fontFamily: 'Poppins-SemiBold',
                                    fontWeight: Platform.OS === 'web' ? 0 : '600', fontSize: 14, marginLeft: 5, textAlign: 'center',
                                }}>Terms and Conditions</Text>
                            </View>
                        </View>
                        <View style={{ marginTop: -355, flex: 1, zIndex: 1 }}>
                            {Platform.OS === 'web' ? (
                                <iframe
                                    src="https://nutrisearch.ca/tcsa.html"
                                    style={styles.webView}
                                    title="Terms and Conditions"
                                />
                            ) : (
                                <WebView source={{ uri: 'https://nutrisearch.ca/tcsa.html' }} style={styles.webView} />
                            )}
                        </View>
                    </View>
                </View>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.white,
        padding: 20,
        marginTop: 8,
        alignItems: 'center',
    },
    checkbox: {
        width: 20,
        height: 20,
        borderWidth: 1.5,
        borderRadius: 4,
        borderColor: 'rgb(34, 35, 36)',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        marginTop: 10
    },
    checkedCheckbox: {
        backgroundColor: '#007BFF',
    },
    checkmark: {
        color: '#fff',
    },
    label: {
        flexShrink: 1,
        fontFamily: FONTS.PoppinsSemiBold,
        width: '67%'
    },
    link: {
        color: '#007BFF',
        textDecorationLine: 'underline',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: 'white',
        borderRadius: 10,
        overflow: 'hidden',
        height: '97%',
        marginTop: '3%'
    },
    webView: {
        flex: 1,
    },
    closeButton: {
        padding: 10,
        alignItems: 'center',
        backgroundColor: '#007BFF',
    },
    closeButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    txt3: {
        fontFamily: FONTS.PoppinsRegular,
        fontSize: 14,
        marginLeft: 10,
        fontWeight: '400',
        color: 'rgb(34, 35, 36)',
    },
});

export default PolicyCheckBox;
