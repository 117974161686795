import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Dimensions,
  TouchableOpacity,
  StatusBar,
  ActivityIndicator,
  Alert,
  useWindowDimensions,
  Animated,
  Button,
  Linking,
} from "react-native";
import React, {
  useState,
  useEffect,
  useContext,
  useSyncExternalStore,
  useRef,
} from "react";
import { API, COLORS, FONTS } from "../../../utils";
import { Pressable } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as WebBrowser from "expo-web-browser";
import { Toast } from "react-native-toast-notifications";
const { height, width } = Dimensions.get("window");
import { WebView } from "react-native-webview";
import { DataContext } from "../../components/DataContext";
import { useTranslation } from "react-i18next";
const LeftArrow = require("../../assets/images/whiteleftarrow.png");
const ClickIcon = require("../../assets/images/clickicon.png");

export default function Subscription({ navigation }) {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [userId, setUserId] = useState("");
  const [userKey, setUserKey] = useState("");
  const [planValue, setPlanValue] = useState(3);
  const [paymentCurrentStatus, setPaymentCurrentStatus] = useState(0);
  const [urlActive, setUrlActive] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState("");
  const [isToastVisible, setIsToastVisible] = useState(false);
  const { data3 } = useContext(DataContext);
  const [subsriptionData, setSubscriptionData] = useState([]);

  useEffect(() => {
    if (data3.status == "200") {
      setPlanValue(data3?.data[2]?.id);
      setSubscriptionData(data3?.data);
    } else {
      console.log("no data found");
    }
  }, [data3]);

  useEffect(() => {
    let intervalId;
    if (paymentCurrentStatus !== 1) {
      intervalId = setInterval(() => {
        console.log("Interval trigger for bulk screen screen");
        getData();
      }, 1500);
    }
    return () => clearInterval(intervalId);
  }, [paymentCurrentStatus]);

  const handleOpenInAppBrowser = async (url) => {
    const result = await WebBrowser.openBrowserAsync(url);
    if (result.type === "cancel") {
      console.log("Web browser closed or failed to open.");
    } else {
      console.log("Web browser opened successfully.");
    }
  };

  function notifyMessage(msg) {
    if (Platform.OS === "ios") {
      Alert.alert(msg);
    } else if (Platform.OS === "android") {
      if (!isToastVisible) {
        Toast.show(msg, {
          type: "normal",
          placement: "bottom",
          duration: 1000,
          offset: 0,
          animationType: "zoom-in",
          onHide: () => setIsToastVisible(false),
        });
        setIsToastVisible(true);
      }
    } else {
      if (!isToastVisible) {
        Toast.show(msg, {
          type: "normal",
          placement: "bottom",
          duration: 1000,
          offset: 0,
          animationType: "zoom-in",
          onHide: () => setIsToastVisible(false),
        });
        setIsToastVisible(true);
      }
    }
  }

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem("key");
      const id = await AsyncStorage.getItem("id");
      setUserKey(value);
      setUserId(id);
      if (value !== null || id !== null) {
        getprofile(value, id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  function getprofile(value, id) {
    var myHeaders = new Headers();
    myHeaders.append("id", id);
    myHeaders.append("session_key", value);
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
    myHeaders.append(
      "Cookie",
      "ci_session=2b19bf32b9a2f50974f1700c1479e593ec66dae2"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      API.baseurl + API.getprofile + "?id=" + id + "&session_key=" + value,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.message == "Client Error: Unauthorized") {
          navigation.replace("Signin");
        }
        if (result?.status == 200) {
          if (result?.data?.payment_status == null) {
          } else if (result?.data?.is_subscribe == "1") {
            setPaymentCurrentStatus(1);
            navigation.replace("Home2");
            setUrlActive(false);
            notifyMessage("payment sucessfully");
          } else if (result?.data?.is_subscribe == "0") {
            // setUrlActive(false);
          }
        } else if (result?.status == 400) {
          notifyMessage(result?.message);
        } else {
          notifyMessage(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const handleCheckout = (paymentUrl) => {
    console.log("🚀 ~ handleCheckout ~ paymentUrl:", paymentUrl);
    window.location.href = paymentUrl;
  };

  function handlesubscription(userId, userKey) {
    var myHeaders = new Headers();
    myHeaders.append("id", userId);
    myHeaders.append("session_key", userKey);
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");

    var formdata = new FormData();
    formdata.append("plan_id", planValue);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      API.baseurl +
        API.Subscription +
        "?id=" +
        userId +
        "&session_key=" +
        userKey,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result?.status == 200) {
          if (Platform.OS == "web") {
            let url = result?.data?.url;
            setPaymentUrl(url);
            handleCheckout(url);
          } else {
            let url = result?.data?.url;
            setPaymentUrl(url);
            setUrlActive(true);
          }
        } else {
          if (
            result?.message ==
            "Header Request :id,session_key field is required"
          ) {
          } else {
            notifyMessage(result?.message);
          }
        }
      })
      .catch((error) => {
        console.log("error", error), notifyMessage(error);
      });
  }

  return (
    <View style={{ flex: 1, backgroundColor: COLORS.primary }}>
      <StatusBar
        animated={true}
        barStyle={"light-content"}
        backgroundColor={"transparent"}
        translucent={true}
      />
      {urlActive === false ? (
        <>
          <View style={styles.headerView}>
            <Pressable
              onPress={() => navigation.replace("Home2")}
              style={styles.img}
            >
              <Image style={{ height: 24, width: 24 }} source={LeftArrow} />
            </Pressable>
            <Text style={styles.title} allowFontScaling={false}>
              {t("Get Subscription")}
            </Text>
          </View>
          <View style={styles.View2}>
            <View
              style={{
                width: Platform.OS === "web" ? "50%" : width - 40,
                maxWidth: 436,
                minWidth: 325,
                alignSelf: Platform.OS === "web" ? "center" : "center",
              }}
            >
              <Text style={styles.txt1} allowFontScaling={false}>
                {t(
                  "Explore a variety of plans designed for your unique needs. Subscribe today and let's craft a healthier future together"
                )}
              </Text>
            </View>
            <Pressable
              onPress={() => setPlanValue(subsriptionData[0]?.id)}
              style={[
                styles.View3,
                {
                  backgroundColor:
                    planValue === subsriptionData[0]?.id
                      ? COLORS.primaryLite
                      : null,
                  borderStyle:
                    planValue === subsriptionData[0]?.id ? null : "dashed",
                  borderWidth: planValue === subsriptionData[0]?.id ? null : 1,
                },
              ]}
            >
              <Text
                allowFontScaling={false}
                style={[
                  styles.txt2,
                  {
                    color:
                      planValue === subsriptionData[0]?.id
                        ? "white"
                        : COLORS.primary,
                  },
                ]}
              >
                {t("Monthly")}
              </Text>
              <View>
                <Text>
                  <Text
                    style={[
                      styles.txt3,
                      {
                        color:
                          planValue === subsriptionData[0]?.id
                            ? "white"
                            : COLORS.primary,
                      },
                    ]}
                  >
                    $ {subsriptionData[0]?.plan_price}/
                  </Text>
                  <Text
                    style={[
                      styles.txt4,
                      {
                        color:
                          planValue === subsriptionData[0]?.id
                            ? "white"
                            : COLORS.primarygraycolor,
                      },
                    ]}
                  >
                    {t("month")}
                  </Text>
                </Text>
              </View>
              {planValue === subsriptionData[0]?.id ? (
                <Image
                  source={ClickIcon}
                  style={{
                    height: 19,
                    width: 19,
                    position: "absolute",
                    right: 0,
                    top: -10,
                  }}
                />
              ) : null}
            </Pressable>
            {/* <Pressable
                            onPress={() => setPlanValue(subsriptionData[1]?.id)}
                            style={[styles.View3, { marginTop: 16, backgroundColor: planValue === subsriptionData[1]?.id ? COLORS.primaryLite : null, borderStyle: planValue === subsriptionData[1]?.id ? null : 'dashed', borderWidth: planValue === subsriptionData[1]?.id ? null : 1 }]}
                        >
                            <Text style={[styles.txt2, { color: planValue === subsriptionData[1]?.id ? 'white' : COLORS.primary }]}>{t("Quarterly")}</Text>
                            <View>
                                <Text>
                                    <Text style={[styles.txt3, { color: planValue === subsriptionData[1]?.id ? 'white' : COLORS.primary }]}>$ {subsriptionData[1]?.plan_price}/</Text>
                                    <Text style={[styles.txt4, { color: planValue === subsriptionData[1]?.id ? 'white' : COLORS.primarygraycolor }]}>{t("quarterly")}</Text>
                                </Text>
                            </View>
                            {planValue === subsriptionData[1]?.id ?
                                <Image
                                    source={ClickIcon}
                                    style={{ height: 19, width: 19, position: 'absolute', right: 0, top: -10 }}
                                /> : null}
                        </Pressable> */}
            <Pressable
              onPress={() => setPlanValue(subsriptionData[2]?.id)}
              style={[
                styles.View4,
                {
                  marginTop: 16,
                  backgroundColor:
                    planValue === subsriptionData[2]?.id
                      ? COLORS.primaryLite
                      : null,
                  borderStyle:
                    planValue === subsriptionData[2]?.id ? null : "dashed",
                  borderWidth: planValue === subsriptionData[2]?.id ? null : 1,
                  borderColor: COLORS.primary,
                },
              ]}
            >
              <Text
                allowFontScaling={false}
                style={[
                  styles.txt2,
                  {
                    color:
                      planValue === subsriptionData[2]?.id
                        ? "white"
                        : COLORS.primary,
                  },
                ]}
              >
                {t("Annual")}
              </Text>
              <View>
                <Text>
                  <Text
                    style={[
                      styles.txt3,
                      {
                        color:
                          planValue === subsriptionData[2]?.id
                            ? "white"
                            : COLORS.primary,
                      },
                    ]}
                  >
                    $ {subsriptionData[2]?.plan_price}/
                  </Text>
                  <Text
                    style={[
                      styles.txt4,
                      {
                        color:
                          planValue === subsriptionData[2]?.id
                            ? "white"
                            : COLORS.primarygraycolor,
                      },
                    ]}
                  >
                    {t("annual")}
                  </Text>
                </Text>
              </View>
              {planValue === subsriptionData[2]?.id ? (
                <Image
                  source={ClickIcon}
                  style={{
                    height: 19,
                    width: 19,
                    position: "absolute",
                    right: 0,
                    top: -10,
                  }}
                />
              ) : null}
            </Pressable>
            {isActive === true ? (
              <ActivityIndicator
                size={"large"}
                style={{ marginTop: 20 }}
                color={COLORS.primary}
              />
            ) : (
              <ActivityIndicator
                size={"large"}
                style={{ marginTop: 20 }}
                color={COLORS.white}
              />
            )}
            <TouchableOpacity
              onPress={() => handlesubscription(userId, userKey)}
              activeOpacity={0.8}
              style={[styles.Signinbtn, { marginTop: 50, alignSelf: "center" }]}
            >
              <Text style={styles.txt5}>{t("Subscribe Now")}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => navigation.navigate("Bulksubscription")}
              activeOpacity={0.8}
              style={[styles.Signinbtn, { marginTop: 7, alignSelf: "center" }]}
            >
              <Text style={styles.txt5}>{t("Bulk Subscription")}</Text>
            </TouchableOpacity>
            <Pressable onPress={() => handlesubscription(userId, userKey)}>
              <Text
                // allowFontScaling={false}

                style={styles.txt9}
              >
                {t("Get 15 days free trial")}
              </Text>
            </Pressable>
          </View>
        </>
      ) : (
        <WebView
          style={{ flex: 1, marginTop: 40 }}
          source={{ uri: paymentUrl }}
        />
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  animatedContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
    margin: 20,
    padding: 20,
  },
  innerContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  backButton: {
    padding: 10,
    backgroundColor: "#007AFF",
    alignSelf: "flex-start",
    borderRadius: 5,
    marginBottom: 10,
  },
  backButtonText: {
    color: "white",
    fontWeight: "bold",
  },
  infoText: {
    marginBottom: 20,
    fontSize: 16,
  },

  container: {
    flex: 1,
    backgroundColor: COLORS.white,
    padding: 20,
    alignItems: "center",
  },
  headerView: {
    width: "100%",
    flexDirection: "row",
    marginTop: Platform.OS === "web" ? 20 : 60,
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    height: 24,
    width: 24,
    position: "absolute",
    left: 20,
    top: 7,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 22,
    color: COLORS.white,
    fontFamily: "Poppins-SemiBold",
    fontWeight: Platform.OS === "web" ? 0 : "600",
  },
  View2: {
    height: height * 2,
    backgroundColor: "white",
    marginTop: 50,
    borderTopRightRadius: 32,
    borderTopLeftRadius: 32,
    paddingRight: 20,
    paddingLeft: 20,
  },
  txt1: {
    marginTop: 30,
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.black,
    textAlign: "center",
  },
  View3: {
    paddingLeft: 30,
    paddingRight: 30,
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: COLORS.primary,
    height: Platform.OS === "web" ? 50 : 50,
    borderRadius: 6,
    marginTop: 50,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    width: Platform.OS === "web" ? "50%" : width - 40,
    maxWidth: 436,
    minWidth: 325,
    alignSelf: Platform.OS === "web" ? "center" : "center",
  },
  txt2: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "400",
    color: COLORS.primary,
  },
  txt3: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "400",
    color: COLORS.primary,
  },
  txt4: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "400",
    color: COLORS.primarygraycolor,
  },
  View4: {
    paddingLeft: 30,
    paddingRight: 30,
    height: Platform.OS === "web" ? 50 : 50,

    borderRadius: 6,
    marginTop: 50,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: COLORS.primaryLite,
    width: Platform.OS === "web" ? "50%" : width - 40,
    maxWidth: 436,
    minWidth: 325,
    alignSelf: Platform.OS === "web" ? "center" : "center",
  },
  Signinbtn: {
    marginTop: Platform.OS === "web" ? 20 : 85,
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderRadius: 46,
    height: 50,
    backgroundColor: COLORS.primary,
    justifyContent: "center",
    alignItems: "center",
  },
  txt5: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.white,
  },
  txt9: {
    marginTop: 33,
    textDecorationLine: "underline",
    fontFamily: FONTS.PoppinsRegular,
    fontWeight: "300",
    fontSize: 14,
    color: COLORS.primary,
    textAlign: "center",
  },
});
