// OrientationContext.js
import React, { createContext, useState, useEffect } from 'react';
import { Dimensions } from 'react-native';

export const OrientationContext = createContext();

export const OrientationProvider = ({ children }) => {
    const [isLandscape, setIsLandscape] = useState(false);
    // useEffect(() => {
    //     const updateOrientation = () => {
    //         const { width, height } = Dimensions.get('window');
    //         setIsLandscape(width > height && height < 700);
    //     };
    //     updateOrientation();
    //     Dimensions.addEventListener('change', updateOrientation);
    //     return () => {
    //         Dimensions.removeEventListener('change', updateOrientation);
    //     };
    // }, []);
    
    useEffect(() => {
        const updateOrientation = () => {
            const { width, height } = Dimensions.get('window');
            setIsLandscape(width > height && height < 500);
        };
    
        updateOrientation();
    
        const subscription = Dimensions.addEventListener('change', updateOrientation);
    
        return () => {
            subscription?.remove();
        };
    }, []);
    return (
        <OrientationContext.Provider value={isLandscape}>
            {children}
        </OrientationContext.Provider>
    );
};
