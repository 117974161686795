
import { Keyboard, ActivityIndicator, StatusBar, View, Text, StyleSheet, Dimensions, Pressable, Image, TextInput, FlatList, Platform, useWindowDimensions, TouchableOpacity, Animated, PanResponder, ScrollView, Alert, SafeAreaView, Modal } from 'react-native'
import { Easing } from 'react-native';
import React, { useState, useEffect, useRef, useContext } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useTranslation } from 'react-i18next';
import { useNavigation, useNavigationState, useRoute } from '@react-navigation/native';
import LoadingDots from "react-native-loading-dots";
import i18next from 'i18next';
import { DataContext } from './DataContext';
import { API, COLORS, FONTS } from '../../utils';
import StarRating from './starrating';
import RecentSearch from './recentSearch';
import { OrientationContext } from './OrientationContext';
import SuppplementnewsWeb from './supplementnewsWeb';


const { width, height } = Dimensions.get('window')
const CommonHeader = ({ title, showFilter, onDataFilter, marginTop, onRemoveFocus }) => {
    const isLandscape = useContext(OrientationContext);
    let currentLanguage = i18next?.language;

    const DEMO = require('../assets/images/nutrilogo.png');

    const Search = require('../assets/images/magnify.png')
    const Silver = require('../assets/images/silver.png')
    const Gold = require('../assets/images/gold.png')
    const dimond = require('../assets/images/dimond.png')
    const Platinum = require('../assets/images/platinum.png')
    const Filter = require('../assets/images/filter.png')
    const filterFill = require('../assets/images/filterfill.png')
    const filterUnfill = require('../assets/images/filterunfill.png')
    const fill = require('../assets/images/fillstar.png')
    const unfill = require('../assets/images/unfillstar.png')

    const { t } = useTranslation();
    const [filteredData, setFilteredData] = useState([]);
    const [isActive, setIsActive] = useState(false)
    const [medalData, setMedalData] = useState([])
    const [ratingData, setRatingData] = useState([])
    const [countryData, setCountryData] = useState([])
    const [newsShow, setNewsShow] = useState(false)
    const [newsData, setNewsData] = useState([])
    const [filterData, setFilteerData] = useState([])
    const [newsData2, setNewsData2] = useState([])
    const [searchListShow, setSearchListShow] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData2, setFilterData2] = useState([filteredData])
    const [selectedItems, setSelectedItems] = useState([]);
    const [recentSearch, setRecentSearch] = useState([])
    const [manufacturerData, setManufactureData] = useState([])
    const [medal, setMedal] = useState([])
    const [country, setCountry] = useState([])
    const [ratings, setRatings] = useState([])
    const [manufacture, setManufacture] = useState([])
    const navigation = useNavigation();
    const [isToastVisible, setIsToastVisible] = useState(false);
    const { data2 } = useContext(DataContext)
    const [selectedCountries, setSelectedCountries] = useState([])
    const [selectedRatings, setSelectedRatings] = useState([])
    const [selectedMedals, setSelectedMedals] = useState([])
    const [selectedManufactures, setSelectedManufactures] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const openModal = () => { setIsModalVisible(true); };
    const closeModal = () => { setIsModalVisible(false); };



    function resetFilter() {
        setSelectedManufactures([])
        setSelectedRatings([])
        setSelectedCountries([])
        setSelectedMedals([])
        setManufacture([])
        setRatings([])
        setCountry([])
        setMedal([])
        filterProducts([], [], [], [])
    }
    useEffect(() => {
        if (Platform.OS === 'web') {
            const intervalId = setInterval(() => {
                getUserInfo();
            }, 3000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, []);
    const getUserInfo = async () => {
        try {
            const value = await AsyncStorage.getItem('key');
            const id = await AsyncStorage.getItem('id');
            // setUserKey(value)
            // setId(id)
            if (value !== null || id !== null) {
              
            }
        } catch (e) {
            console.log(e)
        }
    };

    const [notificationData, setNotificationData] = useState([])
    const [formatedDate, setForamtedDate] = useState("")
    const [isShowLoading, setIsShowLoading] = useState(false)

    const fetchFaqData = async (value: any, id: any) => {
        try {
            // Check if data is cached
            const cachedData = await AsyncStorage.getItem(`notification_${id}`);
            //   console.log("cached data ", cachedData)
            let cachedResponse = null;
            if (cachedData) {
                // Parse cached data
                cachedResponse = JSON.parse(cachedData);
            }

            // Make API call
            const response = await fetch(`${API.baseurl}notification`, {
                method: 'GET',
                headers: {
                    'id': id,
                    'session_key': value,
                    'Authorization': 'Basic YWRtaW46MTIzNA==',
                },
            });

            // Parse API response
            const result = await response.json();

            if (result.status === 200) {
                // console.log("API call successful", result);
                // console.log(result?.data[0])
                const data = result?.data[0];
                // Update state or handle the data
                // console.log("data >>", data)
                setNotificationData(data);

                // Format date
                const createdAt = new Date(data?.created_at);
                const year = createdAt.getFullYear();
                const month = createdAt.toLocaleString('default', { month: 'long' });
                const day = createdAt.getDate();
                const formattedDate = `${month} ${day}, ${year}`;
                // Update formatted date
                setForamtedDate(formattedDate);

                // Save data to AsyncStorage
                await AsyncStorage.setItem(`notification_${id}`, JSON.stringify(data));

                // Check if cached data exists and is different from current API data
                if (!cachedResponse || cachedResponse.id !== data.id) {
                    // Call notifun() only if data is new or updated
                    notifun();
                }
            } else {
                // Handle API error
                console.error('API response error:', result.message);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            // Optional: handle any final actions (e.g., set state)
            // setIsActive(false); // Uncomment if using state
        }
    };

    //   const fetchFaqData = async (value : any, id : any) => {
    //     try {
    //       const response = await fetch(`${API.baseurl}notification`, {
    //         method: 'GET',
    //         headers: {
    //           'id': id,
    //           'session_key': value,
    //           'Authorization': 'Basic YWRtaW46MTIzNA==',
    //         },
    //       });
    //       const result = await response.json();
    //       // Alert.alert(JSON.stringify(result))
    //       // console.log(result)
    //       if(result.status == 200){
    //         console.log(">>>>",result)
    //         console.log(result?.data[0])
    //     setNotificationData(result?.data[0])
    //     const createdAt = new Date(result?.data[0]?.created_at);
    //     const year = createdAt.getFullYear();
    //     const month = createdAt.toLocaleString('default', { month: 'long' });
    //     const day = createdAt.getDate();

    //     const formattedDate = `${month} ${day}, ${year}`;
    //     setForamtedDate(formattedDate)
    //         notifun()
    //       }
    //       else{
    //       }
    //       // setFaqData(result);
    //     } catch (error) {
    //       console.error('Error:', error);
    //     } finally {

    //       // setIsActive(false);
    //     }
    //   };





    const sendProductsData = (data) => {
       
        if (data.length === 0) {
            onDataFilter(null);
        } else {
            onDataFilter(data);
        }
    };

    useEffect(() => {
        setNewsShow(title === t('Supplements in the news') ? true : false)
        setSearchListShow(false);

    }, [onRemoveFocus]);

    useEffect(() => {
        const modifiedData = data2.results?.map(item => ({
            ...item,
            concatName: `${item.company_name} ${item.product_name}`
        }));
        setFilteredData(modifiedData);
        setFilterData2(modifiedData);
        setNewsData(data2.news);
        setNewsData2(data2.news);
    }, [data2]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const unsubscribe = navigation.addListener('blur', yourFunctionToChangeState);
        return unsubscribe;
    }, [navigation]);

    const yourFunctionToChangeState = () => {
        loadSelectedItems();
        setSearchQuery('');
        setSearchListShow(false);
        setNewsShow(title === t('Supplements in the news') ? true : false)
    };

    const filterListing = async (value, id) => {
       
        setIsShowLoading(true)
        const headers = {
            "id": id,
            "session_key": value,
            "Authorization": "Basic YWRtaW46MTIzNA==",
            "Cookie": "ci_session=d5fbe110867808406780232c0fc22e5c35f24315"
        };
        const requestOptions: any = { method: 'GET', headers: new Headers(headers), redirect: 'follow' };

        try {
            const response = await fetch(`${API.baseurl}/filterlist`, requestOptions);
            const result = await response.json();
            if (result.status === 200) {
                const [medal, country, rating, manufacture] = result.data;
                setMedalData(medal.list);
                setRatingData(rating.list);
                setCountryData(country.list);
                setManufactureData(manufacture.list);
                setIsShowLoading(false)
            } else {
                notifyMessage(result.message);
            }
        } catch (error) {
            console.error('Error:', error.message);
            notifyMessage(error.message);
        }
    };
    const notifun = () => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 3000); // hide notification after 3 seconds
    };

    const notifyMessage = (msg) => {
        if (Platform.OS === 'ios') {
            // Alert.alert(msg);
        } else if (Platform.OS === 'android' || Platform.OS === 'web') {
            if (!isToastVisible) {
                // Toast.show(msg, { duration: ToastAndroid.SHORT });
                setIsToastVisible(true);
                setTimeout(() => setIsToastVisible(false), 1000);
            }
        }
    };

    const getData = async () => {
        try {
            const [value, id] = await Promise.all([
                AsyncStorage.getItem('key'),
                AsyncStorage.getItem('id')
            ]);
            if (value && id) {
                filterListing(value, id);
                filterProducts("", "", "", "");
                fetchFaqData(value, id)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const [showNotification, setShowNotification] = useState(false);
    const [animationValue] = useState(new Animated.Value(0));

    useEffect(() => {
        if (showNotification) {
            Animated.timing(animationValue, {
                toValue: 1,
                duration: 500,
                easing: Easing.ease,
            }).start();
        } else {
            Animated.timing(animationValue, {
                toValue: 0,
                duration: 500,
                easing: Easing.ease,
            }).start();
        }
    }, [showNotification]);

    const onPress = () => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 3000); // hide notification after 3 seconds
    };
    const filterProducts = async (medal, ratings, country, manufacture) => {
        setIsShowLoading(true)
        // Alert.alert("ss"+JSON.stringify(medal)+JSON.stringify(ratings)+JSON.stringify( country)+  JSON.stringify(manufacture))
        const [value, id] = await Promise.all([
            AsyncStorage.getItem('key'),
            AsyncStorage.getItem('id')
        ]);
        setFilteerData(null);
        setIsActive(true);
        const headers = new Headers({
            "id": id,
            "session_key": value,
            "Authorization": "Basic YWRtaW46MTIzNA==",
            "Cookie": "ci_session=bedc6f65ad68e3f0c6f369cbfee6f96efbf43f41"
        });
        const queryString = `medals_id=${medal}&countries_id=${country}&rating=${ratings}&manufacturer=${manufacture}`;
        const apiUrl = `${API.baseurl}/filterdata?${queryString}`;
        const requestOptions: any = { method: 'GET', headers, redirect: 'follow' };

        fetch(apiUrl, requestOptions)
            .then(response => response.json())
            .then(result => {
              
                if (result?.status === 200) {
                    if (result?.data?.length > 0) {
                        const sortedData = result?.data.sort((a, b) => {
                            const compareNames = (name1, name2) => name1.localeCompare(name2);
                            const isNumeric = /^[0-9]/;
                            const isAlphabetic = /^[a-zA-Z]/;

                            if (isNumeric.test(a.company_name) && isNumeric.test(b.company_name)) return compareNames(a.company_name, b.company_name);
                            if (isAlphabetic.test(a.company_name) && isAlphabetic.test(b.company_name)) return compareNames(a.company_name, b.company_name);
                            if (isNumeric.test(a.company_name)) return -1;
                            if (isNumeric.test(b.company_name)) return 1;
                            if (isAlphabetic.test(a.company_name)) return -1;
                            if (isAlphabetic.test(b.company_name)) return 1;
                            return compareNames(a.company_name, b.company_name);
                          
                        });
                        setFilteerData(sortedData);
                        sendProductsData(sortedData);
                        setIsShowLoading(false)
                    } else {
                        setFilteerData(result?.data)
                        sendProductsData([])
                        setIsActive(false);
                        setIsShowLoading(false)
                    }
                } else {
                    notifyMessage(result?.message);
                }
            })
            .catch(error => notifyMessage(error.message))
            .finally(() => setIsActive(false));
    }

    function handleFocus() {
        // setNewsShow(title === t('News') ? true : false)
        setSearchListShow(true);
    }

    const handleSearch = text => {
        setSearchQuery(text);
        if (text.length <= 0) {
            setFilteredData(filteredData2);
            setNewsData(newsData2);
            setSearchListShow(false)
        } else {
            setSearchListShow(true);
            const words = text.trim().toLowerCase().split(/\s+/);
            const sanitizeString = str => str.toString().replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
            const filtered = filteredData2?.filter((item: any) =>
                words.every(word => sanitizeString(item?.star_rating ?? '').includes(sanitizeString(word)) ||
                    sanitizeString(item?.countries_name ?? '').includes(sanitizeString(word)) ||
                    sanitizeString(item?.medal ?? '').includes(sanitizeString(word)) ||
                    sanitizeString(item?.concatName ?? '').includes(sanitizeString(word))
                )
            );
            const filteredNews = newsData2
                ?.filter(item =>
                    words.some(word => sanitizeString(item?.title ?? '').includes(sanitizeString(word))) ||
                    words.some(word => sanitizeString(item?.tags ?? '').includes(sanitizeString(word)))
                )
                .sort((a, b) => {
                    const aTitleMatches = words.some(word => sanitizeString(a?.title ?? '').includes(sanitizeString(word)));
                    const bTitleMatches = words.some(word => sanitizeString(b?.title ?? '').includes(sanitizeString(word)));

                    if (aTitleMatches && !bTitleMatches) {
                        return -1;
                    } else if (!aTitleMatches && bTitleMatches) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            // const filteredNews = newsData2?.filter(item =>
            //     words.every(word => sanitizeString(item?.title ?? '').includes(sanitizeString(word))
            //         // sanitizeString(item?.tags ?? '').includes(sanitizeString(word))
            //     )
            // );
            setFilteredData(filtered);
            setNewsData(filteredNews);
        }
    };

    const handleCountryPress = (id) => {
        const isSelected = selectedCountries.includes(id);
        if (isSelected) {
            const updatedCountries = selectedCountries.filter(item => item !== id);
            setSelectedCountries(updatedCountries);
            setCountry([...updatedCountries]);
            filterProducts(medal, ratings, [...updatedCountries], manufacture);
        } else {
            const newSelectedCountries = [...selectedCountries, id];
            setSelectedCountries(newSelectedCountries);
            setCountry([...newSelectedCountries]);
            filterProducts(medal, ratings, [...newSelectedCountries], manufacture);
        }
    };

    const handleRatingPress = (id) => {
      
        if (selectedRatings.includes(id)) {
          
            const updatedRatings = selectedRatings.filter(item => item !== id);
            setSelectedRatings(updatedRatings);
            setRatings([...updatedRatings]);
            filterProducts(medal, updatedRatings.length > 0 ? updatedRatings : 0, country, manufacture);
        } else {
            const newRatings = [...selectedRatings, id];
            
            setSelectedRatings(newRatings);
            setRatings([...newRatings]);
            filterProducts(medal, newRatings, country, manufacture);
        }
    };
    const loadSelectedItems = async () => {
        try {
            const storedItems = await AsyncStorage.getItem('selectedItems');
            if (storedItems) {
                setSelectedItems(JSON.parse(storedItems));
                setRecentSearch(JSON.parse(storedItems))
            }
        } catch (error) {
            console.error('Error loading selected items from AsyncStorage:', error);
        }
    };

    const handleItemClick = (item) => {
        const isItemSelected = selectedItems.some((selectedItem) => selectedItem.id === item.id);
        let updatedItems;
        if (isItemSelected) {
            updatedItems = selectedItems;
        } else {
            updatedItems = [...selectedItems, item];
        }
        setSelectedItems(updatedItems);
        saveSelectedItems(updatedItems);
    };

    const saveSelectedItems = async (items) => {
        try {
            await AsyncStorage.setItem('selectedItems', JSON.stringify(items));
            setRecentSearch(items)

        } catch (error) {
            console.error('Error saving items to AsyncStorage:', error);
        }
    };

    const handleMedalPress = (id) => {
        const isSelected = selectedMedals.includes(id);

        if (isSelected) {
            const updatedMedals = selectedMedals.filter(item => item !== id);
            setSelectedMedals(updatedMedals);
            setMedal([...updatedMedals]);
            filterProducts([...updatedMedals], ratings, country, manufacture)
        } else {
            const newMedals = [...selectedMedals, id];
            setSelectedMedals(newMedals);
            setMedal([...newMedals]);
            filterProducts([...newMedals], ratings, country, manufacture)
        }
    };

    function handleManufacturPress(id) {
        if (selectedManufactures.includes(id)) {
            const updatedManufactures: any = selectedManufactures.filter(item => item !== id);
            setSelectedManufactures(updatedManufactures);
            setManufacture(updatedManufactures.length > 0 ? updatedManufactures : 0);
            filterProducts(medal, ratings, country, updatedManufactures.length > 0 ? updatedManufactures : 0);
        } else {
            const newManufactures = [...selectedManufactures, id];
            setSelectedManufactures(newManufactures);
            setManufacture(newManufactures);
            filterProducts(medal, ratings, country, newManufactures);
        }
    }

  

    function renderItem3({ item }) {
        const updatedDate = item.updated_at.split(' ')[0];
        const showCountry = item?.countries_name ? `(${item.countries_name})` : '';
        const medals = {
            1: Platinum,
            2: dimond,
            3: Gold,
            4: Silver
        };

        return (
            <TouchableOpacity activeOpacity={0.6} onPress={() => {
                handleItemClick(item);
                setSearchListShow(false);
                setSearchQuery('');
                // @ts-ignore
                if (item?.countries_iso_code?.length > 0) { navigation.navigate('Graph', { item: item, previousRoute: route.name }) }
            }} style={styles.itemContainer}>
                <View style={styles.itemView}>
                    <View style={[styles.itemContentView, item?.medal_status == 0 ? { width: '100%' } : { width: '90%' }]}>
                        <View style={styles.itemNameView}>
                            <Text style={styles.combinedText} numberOfLines={1} ellipsizeMode="tail">
                                {item?.company_name} {item?.product_name}
                            </Text>
                        </View>
                        <View style={styles.countryNameView}>
                            <Text style={styles.countryName}>{showCountry}</Text>
                        </View>
                        <View style={styles.ratingView}>
                            <StarRating rating={item?.star_rating} customStarImage={{ filled: fill, empty: unfill }} height={10} width={11} />
                            <Text style={styles.rating} allowFontScaling={false}>{item?.star_rating}</Text>
                        </View>
                    </View>
                    <View style={styles.medalView}>
                        <Image source={medals[item?.medal_status]} style={styles.medalImage} />
                    </View>
                </View>
                {updatedDate !== "0000-00-00" && <Text style={styles.date} allowFontScaling={false}>{t("last updated")} : {updatedDate}</Text>}
            </TouchableOpacity>
        );
    }


    const route = useRoute();
    return (
        <Pressable
            onPress={() => setSearchListShow(false)}
            style={styles.container}>
            <StatusBar
                animated={true}
                barStyle={'light-content'}
                backgroundColor={'transparent'}
                translucent={true}
            />
            <Pressable
                onPress={() => setSearchListShow(false)}
                style={[styles.View1, { flexDirection: isLandscape ? 'row' : 'column', paddingTop: isLandscape ? 17 : height / 15 }]}>
                <View style={styles.headerView}>
                    <Text
                        allowFontScaling={false}
                        style={[
                            // @ts-ignore
                            styles.title, { marginTop: Platform.OS === 'ios' ? (currentLanguage === 'ko' ? 4 : 0) : 0 }
                        ]} >
                        {title}
                    </Text>
                </View>
                <Pressable
                    onPress={() => { setSearchListShow(!searchListShow) }}
                    style={[styles.searchContainer, Platform.OS === 'web' ? styles.webWidth : { width: width - 32 }]}>
                    <View style={[
                        styles.searchBarView,
                        {
                            borderBottomLeftRadius: searchListShow ? 0 : 6,
                            borderBottomRightRadius: searchListShow ? 0 : 6,
                            borderColor: searchListShow ? '#214F71' : COLORS.white
                        }
                    ]}>
                        <Image source={Search} style={styles.searchIcon} />
                        <TextInput
                            style={[styles.searchInput, Platform.OS === 'web' && styles.webSearchInput]}
                            placeholder={t("Search")}
                            placeholderTextColor={COLORS.primarygraycolor}
                            // @ts-ignore
                            fontFamily={FONTS.PoppinsMedium}
                            onFocus={handleFocus}
                            color={COLORS.primarygraycolor}
                            onChangeText={handleSearch}
                            value={searchQuery}
                            selectionColor={COLORS.primary}
                        />
                        {showFilter && (
                            <Pressable
                                onPress={() => { Keyboard.dismiss(), openModal(), setSearchListShow(false), setSearchQuery('') }}
                                style={styles.filterButton}>
                                {(selectedMedals.length > 0 || selectedCountries.length > 0 || selectedRatings.length > 0 || selectedManufactures.length > 0) && (
                                    <Text allowFontScaling={false} style={styles.filterDot}>.</Text>
                                )}
                                <Text allowFontScaling={false} style={styles.filterText}>{t("Filter")}</Text>
                                <Image style={styles.filterIcon} source={Filter} />
                            </Pressable>
                        )}
                    </View>
                    {searchListShow && (

                        <View style={[
                            styles.nestedContainer1,
                            Platform.OS === 'web' ?
                                {
                                    position: 'absolute',
                                    top: 48,
                                    flex: 1,
                                    minHeight: !searchQuery && recentSearch.length > 0 ? 200 : 307
                                } :
                                {
                                    minHeight: !searchQuery && recentSearch.length > 0 ? 200 : 307
                                }
                        ]}>
                            {/* <View style={[styles.nestedContainer1, { minHeight: !searchQuery && recentSearch.length > 0 ? 200 : 307, }]}> */}
                            {!searchQuery && recentSearch.length > 0 ? (
                                <RecentSearch data={recentSearch} />
                            ) : (
                                <>
                                    <View style={styles.tabSwitch}>
                                        <TouchableOpacity onPress={() => setNewsShow(false)}>
                                            <Text style={[styles.tabText, !newsShow && styles.activeTab]}>Products</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => setNewsShow(true)}>
                                            <Text style={[styles.tabText, styles.tabTextMargin, newsShow && styles.activeTab]}>News</Text>
                                        </TouchableOpacity>
                                    </View>

                                    {isActive ? (
                                        <ActivityIndicator size='small' color={COLORS.primary} style={styles.loadingIndicator} />
                                    ) : (
                                        <>
                                            {newsShow === false ? (
                                                filteredData?.length > 0 ? (
                                                    <FlatList
                                                        style={styles.flatListView}
                                                        showsVerticalScrollIndicator={false}
                                                        data={filteredData}
                                                        renderItem={renderItem3}
                                                        keyboardShouldPersistTaps="handled"
                                                        keyExtractor={item => item.id.toString()}
                                                    />
                                                ) : (
                                                    <Text allowFontScaling={false} style={styles.noResultText}>Sorry, we couldn't find any product results</Text>
                                                )
                                            ) : (
                                                newsData?.length > 0 ? (
                                                    <FlatList
                                                        keyboardShouldPersistTaps="handled"

                                                        style={styles.flatListStyle}
                                                        showsVerticalScrollIndicator={false}
                                                        data={newsData}
                                                        renderItem={({ item }) => (<SuppplementnewsWeb item={item} marginBottom={10} />)}
                                                        keyExtractor={item => item.id.toString()}
                                                    />
                                                ) : (
                                                    <Text allowFontScaling={false} style={styles.noResultText}>Sorry, we couldn't find any news results</Text>
                                                )
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </View>
                    )}

                </Pressable>
                {isShowLoading === true && showFilter && (
                    <ActivityIndicator
                        position={"absolute"}
                        size={"small"}
                        style={{ top: Platform.OS === "web" ? 30 : 30 }}
                        color={COLORS.primary}
                    />
                )}

            </Pressable>

            <Modal
                animationType="slide"
                transparent={true}
                visible={isModalVisible}
                onRequestClose={closeModal}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <View style={[styles.filterContainer, { height: isLandscape ? width - 50 : height - 80 }]}>
                            <View style={styles.nestedView}>
                                <Pressable onPress={closeModal} style={styles.crossButton}>
                                    <Image source={require('../assets/images/cross.png')} style={styles.crossImage} />
                                </Pressable>
                                <TouchableOpacity activeOpacity={0.7} onPress={resetFilter} style={styles.resetButton}>
                                    <Text style={styles.resetText}>{t("Reset")}</Text>
                                </TouchableOpacity>
                                <Text allowFontScaling={false} style={styles.filterTitle}>{t('Filter')}</Text>
                                <View style={styles.applyButtonContainer}>
                                    <TouchableOpacity activeOpacity={0.7} onPress={closeModal} style={styles.applyButton}>
                                        <Text style={styles.applyText}>{t("Apply")}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <View style={{ height: 2200, flex: 1 }}>
                                <ScrollView>
                                    {[
                                        { title: t("Medals"), data: medalData, onPress: handleMedalPress, selected: selectedMedals },
                                        { title: t("Countries"), data: countryData, onPress: handleCountryPress, selected: selectedCountries },
                                        { title: t("Rating"), data: ratingData, onPress: handleRatingPress, selected: selectedRatings },
                                        { title: t("Manufacturer"), data: manufacturerData, onPress: handleManufacturPress, selected: selectedManufactures },
                                    ].map(({ title, data, onPress, selected }) => (
                                        <View key={title} style={[styles.section, title === t("Manufacturer") && { paddingBottom: 100 }]}>
                                            <Text style={styles.sectionTitle}>{t(title)}</Text>
                                            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                                {data.map((item) => {
                                                    const isSelected = selected.includes(item.id);
                                                    return (
                                                        <Pressable key={item.id} onPress={() => onPress(item.id)} style={[styles.pressableContainer3, { width: 'auto', backgroundColor: isSelected ? COLORS.primary : 'white' }]}>
                                                            <Image style={styles.image3} source={isSelected ? filterFill : filterUnfill} />
                                                            <Text allowFontScaling={false} style={[styles.text3, { color: isSelected ? 'white' : COLORS.darkbordercolor }]}>{item.filter}</Text>
                                                        </Pressable>
                                                    );
                                                })}
                                            </View>
                                        </View>
                                    ))}
                                </ScrollView>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
            <Animated.View
                style={[
                    styles.notification,
                    {
                        transform: [
                            {
                                translateY: animationValue.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [-50, 0],
                                }),
                            },
                        ],
                        opacity: animationValue,
                    },
                ]}
            >
                <View style={{
                    paddingLeft: 20,
                    paddingRight: 5,
                    paddingBottom: 6,
                    marginTop: 5
                }}>
                    <View style={styles.cardContainer}>
                        <View style={styles.imageContainer}>
                            <Image source={DEMO} style={styles.image} />
                        </View>
                        <View style={styles.textContainer}>
                            <Text style={[styles.nTitle, { borderWidth: 0, width: width - 210 }]}>{notificationData?.title}</Text>
                            <Text style={styles.nDes}>{notificationData?.message}</Text>
                        </View>
                        <Text style={styles.time}>{formatedDate}</Text>
                    </View>
                </View>
            </Animated.View>
          
        </Pressable>
    )
}
export default CommonHeader

const styles = StyleSheet.create({
    container: { zIndex: 10000, },
    searchContainer: { alignSelf: 'center', borderRadius: 6, maxWidth: 436, minWidth: 325, },
    webWidth: { width: '50%' },
    searchBarView: { flexDirection: 'row', alignItems: 'center', paddingHorizontal: 15, borderTopRightRadius: 6, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderWidth: 1.5, backgroundColor: '#fff' },
    searchIcon: { width: 20, height: 20 },
    searchInput: { marginLeft: 10, paddingRight: 10, height: 44, width: '65%' },
    webSearchInput: { width: '100%', outlineColor: 'white', outlineStyle: 'none' },
    View1: { width: '100%', backgroundColor: COLORS.primary, borderBottomLeftRadius: 20, borderBottomRightRadius: 20, paddingBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center', },
    headerView: { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingRight: 15 },
    img: { height: 24, width: 24, position: 'absolute', left: 20, top: 7 },
    title: { alignSelf: 'center', textAlign: 'center', fontSize: 22, maxWidth: 300, color: COLORS.white, fontFamily: 'Poppins-SemiBold', fontWeight: Platform.OS === 'web' ? 0 : '600', paddingVertical: 6 },
    SearchBarView: { backgroundColor: 'white', width: Platform.OS === 'web' ? '100%' : width - 32, alignSelf: "center", marginTop: 0, flexDirection: 'row', alignItems: 'center', paddingRight: 10, paddingLeft: 30 },
    filterButton: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '28%', height: '90%', borderLeftWidth: 1, borderColor: '#6F747966' },
    filterDot: { fontSize: 100, position: 'absolute', right: -4, bottom: 2, color: COLORS.primary },
    filterText: { fontWeight: '600', fontFamily: Platform.OS === "web" ? "'Poppins SemiBold', sans-serif" : FONTS.PoppinsSemiBold, fontSize: 14, lineHeight: 21, color: '#6F7479' },
    filterIcon: { height: 18, width: 18 },
    txt1: { fontFamily: 'Poppins-SemiBold', fontWeight: Platform.OS === 'web' ? 0 : '600', fontSize: 17, color: COLORS.primary, paddingLeft: 20, paddingTop: 0, marginBottom: 6 },
    popupContainer: { backgroundColor: 'white', padding: 0, borderRadius: 12, elevation: 5, width: "70%", height: 312 },
    inputView: { width: Platform.OS === 'web' ? '50%' : width - 92, marginTop: 25, maxWidth: 436, minWidth: 325, borderWidth: 1, borderRadius: 8, height: 50, paddingLeft: 20, paddingRight: 20, borderColor: COLORS.primarybordercolor, alignSelf: 'center' },
    inputproductname: { width: Platform.OS === 'web' ? '90%' : width - 92, maxWidth: 436, minWidth: 325, borderWidth: 1, borderRadius: 8, height: 50, paddingLeft: 20, paddingRight: 20, borderColor: COLORS.primarybordercolor, fontFamily: FONTS.PoppinsMedium, alignSelf: 'center', marginTop: 20 },
    blackBlurContainer: { flex: 1, height: height, position: 'absolute', width: width },
    bottomSheet: { backgroundColor: 'white', borderTopLeftRadius: 30, borderTopRightRadius: 30, position: 'absolute', bottom: 0, left: 0, right: 0, height: Platform.OS === 'web' ? height : Platform.OS == 'android' ? height - 40 : height - 70, width: '100%' },
    bottomSheetText: { fontSize: 20, marginBottom: 10 },
    closeButton: { padding: 10, backgroundColor: 'blue' },
    item: { marginBottom: 10, flex: 1, margin: 4, padding: 16, backgroundColor: '#eb4d4b', borderRadius: 8, alignItems: 'center', justifyContent: 'center' },
    gridView: { flex: 1 },
    itemName: { fontSize: 16, color: '#fff', fontWeight: '600' },
    itemCode: { fontWeight: '600', fontSize: 12, color: '#fff' },
    nestedContainer1: {
        backgroundColor: 'white',
        maxWidth: Platform.OS === 'web' ? 436 : '99.5%',
        minWidth: 325,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        alignSelf: "center",
        maxHeight: 340,
        paddingLeft: 10,
        paddingTop: 15,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 14 },
        shadowOpacity: 0.3,
        shadowRadius: 6,
        elevation: 5,
        paddingRight: 10,
        width: '100%',
        // position: 'absolute',
        // top: 48,
        // flex: 1

    },
    nestedView: { height: 50, backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', paddingRight: 15, paddingLeft: 15, borderTopRightRadius: 12, justifyContent: 'center', borderTopLeftRadius: 12, marginTop: 0 },
    pressableContainer3: { flexDirection: 'row', justifyContent: 'space-between', padding: 7, borderRadius: 6, borderWidth: 1, borderColor: COLORS.darkbordercolor, marginBottom: 10, marginRight: 10, height: 37, alignItems: 'center', maxWidth: width - 40 },
    image3: { height: 14, width: 14, resizeMode: 'contain', marginRight: 4 },
    text3: { fontFamily: FONTS.PoppinsMedium, fontWeight: '400', fontSize: 14, lineHeight: 21 },
    filterContainer: { borderWidth: 0 },
    crossButton: { position: 'absolute', right: Platform.OS == 'ios' ? 2 : -2, bottom: 30, borderWidth: 0 },
    crossImage: { height: 36, width: 36 },
    resetButton: { flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 12, borderRadius: 6, height: 38, alignItems: 'center', position: 'absolute', left: Platform.OS === 'web' ? 20 : 14, top: 10, backgroundColor: '#fff' },
    resetText: { fontFamily: FONTS.PoppinsMedium, fontWeight: '400', fontSize: 18, lineHeight: 21, color: COLORS.primary },
    filterTitle: { fontFamily: FONTS.PoppinsSemiBold, color: COLORS.primary, fontSize: 24, textAlign: 'center', lineHeight: 36, fontWeight: '600', alignSelf: 'center', marginTop: 8 },
    applyButtonContainer: { flexDirection: 'row', position: 'absolute', right: Platform.OS === 'web' ? 20 : 20, top: 8 },
    applyButton: { flexDirection: 'row', justifyContent: 'space-between', borderRadius: 6, height: 38, alignItems: 'center', position: 'absolute', right: Platform.OS === 'web' ? 10 : 10, top: 0, backgroundColor: '#fff' },
    applyText: { fontFamily: FONTS.PoppinsMedium, fontWeight: '400', fontSize: 18, lineHeight: 21, color: COLORS.primary },
    section: { paddingRight: 10, paddingLeft: 18, marginTop: height > 800 ? 15 : 2, borderWidth: 0 },
    sectionTitle: { fontWeight: '500', fontSize: 17, lineHeight: 24, color: COLORS.primary, fontFamily: FONTS.PoppinsSemiBold, marginBottom: 10 },
    fullWidth: { width: '100%' },
    flatListWrapper: { flexWrap: 'wrap' },
    itemContainer: { borderWidth: 1, borderRadius: 16, borderColor: COLORS.primarybordercolor, backgroundColor: 'white', paddingBottom: 10, padding: 2, width: 'auto', marginBottom: 7 },
    itemView: { flexDirection: 'row' },
    itemContentView: { maxWidth: '100%', paddingTop: 8, paddingLeft: 7 },
    itemNameView: { flexDirection: 'column', alignItems: 'flex-start' },
    combinedText: { fontFamily: FONTS.PoppinsMedium, fontWeight: '700', fontSize: 14, color: COLORS.primary, flexShrink: 1 },
    companyName: { fontFamily: FONTS.PoppinsMedium, fontWeight: '700', fontSize: 14, color: COLORS.primary },
    productName: { fontFamily: FONTS.PoppinsMedium, fontWeight: '700', fontSize: 14, color: COLORS.primary, marginLeft: 3, marginRight: 10 },
    countryNameView: { flexDirection: 'row', alignItems: 'center' },
    countryName: { fontFamily: FONTS.PoppinsMedium, fontSize: 12, color: COLORS.primarygraycolor, marginVertical: 4 },
    ratingView: { flexDirection: 'row', alignItems: 'center', height: 13 },
    rating: { marginLeft: 10, color: COLORS.black, fontFamily: FONTS.PoppinsMedium, fontWeight: '600', fontSize: 12 },
    medalView: { position: 'absolute', right: 8, width: 30, top: -10 },
    medalImage: { height: 35, width: 35 },
    date: { bottom: 6, fontFamily: FONTS.PoppinsRegular, fontSize: 12, fontWeight: '400', color: COLORS.primarygraycolor, lineHeight: 18, alignSelf: 'flex-end', position: 'absolute', right: 10 },
    activityIndicator: { flex: 1 },
    itemContainer2: { flexDirection: 'row', justifyContent: 'space-between', padding: 9, borderRadius: 6, borderWidth: 1, borderColor: COLORS.darkbordercolor, marginBottom: 10, marginRight: 13, height: 38, alignItems: 'center' },
    icon2: { height: 14, width: 14, resizeMode: 'contain', marginRight: 4 },
    itemText2: { fontFamily: FONTS.PoppinsMedium, fontWeight: '400', fontSize: 14, lineHeight: 21 },
    tabSwitch: { flexDirection: 'row', borderBottomWidth: 0, borderColor: COLORS.primarygraycolor, marginBottom: 7 },
    tabText: { color: COLORS.darkbordercolor, fontFamily: 'Poppins-SemiBold', fontWeight: Platform.OS === 'web' ? 'normal' : '600' },
    activeTab: { color: COLORS.primary },
    tabTextMargin: { marginLeft: 10 },
    loadingIndicator: { marginTop: 90 },
    flatListContent: { flexGrow: 1, maxHeight: 267 },
    loadingDots: { height: 20, width: 60, alignSelf: 'center', marginTop: 80 },
    flatListStyle: { height: 250 },
    flatListView: { height: 250 },
    noResultText: { marginTop: 35, color: COLORS.primarygraycolor, fontFamily: FONTS.PoppinsSemiBold, textAlign: 'center' },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.4)',
    },
    modalContent: {
        width: '100%',
        paddingBottom: 10,
        backgroundColor: '#fff',
        borderRadius: 20,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    },
    notification: {
        position: 'absolute',
        top: 20,
        left: 0,
        right: 10,
        // backgroundColor: '#C1C3C5',
        // padding: 10,
        // flexDirection: 'row',

        alignSelf: 'center', justifyContent: 'flex-end', alignItems: 'flex-end',
    },
    notificationContent: {
        flex: 1,
    },
    notificationTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff',
    },
    notificationMessage: {
        fontSize: 14,
        color: '#fff',
    },
    notificationClose: {
        paddingHorizontal: 10,
    },
    notificationCloseText: {
        fontSize: 18,
        color: '#fff',
    },
    cardContainer: {
        borderWidth: 0,
        // alignSelf: 'center',
        height: 'auto',
        flexDirection: 'row',
        // padding: 10,
        backgroundColor: 'white',
        borderRadius: 10,
        shadowColor: COLORS.primary,
        shadowOffset: { width: 0, height: 14 },
        shadowOpacity: 0.3,
        shadowRadius: 6,
        elevation: Platform.OS === 'web' ? 0.1 : 5,
        maxWidth: 500,
        minWidth: 325,
        padding: Platform.OS == "web" ? 15 : 6,
        // borderRadius: 6,
        // alignSelf: 'center'
    },
    imageContainer: {
        width: '17%',
        borderWidth: 0
    },
    image: {
        height: 50,
        width: 50,
        // borderRadius: 10
    },
    textContainer: {
        width: '83%',
        borderWidth: 0
    },
    nTitle: {
        color: 'black',
        fontSize: 16,
        // fontWeight: '700',
        fontFamily: "Poppins-Medium"
    },
    nDes: {
        color: 'gray',
        fontSize: 13,
        fontWeight: '400',
        fontFamily: FONTS.PoppinsRegular, marginTop: 5
    },
    time: {
        position: 'absolute',
        right: 10,
        top: 5,
        color: COLORS.primary,
        fontWeight: '700'
    }
});


