import React, { useState, useContext } from "react";
import {
  ScrollView,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Image,
  Pressable,
  StatusBar,
  Platform,
  StyleSheet,
} from "react-native";
import { useTranslation } from "react-i18next";
import { Toast } from "react-native-toast-notifications";
import { API, COLORS, FONTS } from "../../../utils";
const ResetPassword = ({ route, navigation }) => {
  const LeftArrow = require("../../assets/images/arrow-left.png");
  const PasswordEye = require("../../assets/images/eye-outline.png");
  const Passwordcloseye = require("../../assets/images/passwordcloseeye.png");
  const Key = require("../../assets/images/resetpassword.png");

  const { t } = useTranslation();
  const { email } = route?.params;
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isActive2, setIsActive2] = useState(true);
  const [isToastVisible, setIsToastVisible] = useState(false);

  const notifyMessage = (msg) => {
    Toast.show(msg, {
      type: "normal",
      placement: "bottom",
      duration: 1000,
      offset: 0,
      animationType: "zoom-in",
      onHide: () => setIsToastVisible(false),
    });
    setIsToastVisible(true);
  };
  const handleChnagePassword = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");

    const formdata = new FormData();
    formdata.append("email", email);
    formdata.append("newpassword", password);
    formdata.append("Cpassword", confirmPassword);
    formdata.append("type", "3");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };

    fetch(API.baseurl + API.forgetpassword, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 200) {
          navigation.navigate("Signin");
          notifyMessage(result?.message);
        } else {
          notifyMessage(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        notifyMessage(error);
      });
  };
  const validateInputs = () => {
    let formIsValid = true;

    if (!password) {
      setPasswordErr(t("Enter your Password"));
      formIsValid = false;
    } else if (password.length < 8) {
      setPasswordErr(t("Password must be at least 8 characters long"));
      formIsValid = false;
    } else {
      setPasswordErr("");
    }

    if (!confirmPassword) {
      setConfirmPasswordErr(t("Enter your Password"));
      formIsValid = false;
    } else if (confirmPassword !== password) {
      setConfirmPasswordErr(t("Passwords do not match"));
      formIsValid = false;
    } else {
      setConfirmPasswordErr("");
    }

    return formIsValid;
  };
  const handleSubmit = () => {
    if (!validateInputs()) {
      console.log("Error", "Please fill in all fields correctly");
      return;
    } else {
      handleChnagePassword();
    }
  };
  return (
    <ScrollView style={{ flex: 1, backgroundColor: "white" }}>
      <StatusBar
        animated={true}
        barStyle={"light-content"}
        backgroundColor={"transparent"}
        translucent={true}
      />
      <View style={styles.container}>
        <View style={styles.headerView}>
          <Pressable style={styles.img} onPress={() => navigation.goBack()}>
            <Image style={styles.img} source={LeftArrow} />
          </Pressable>
          <Text style={styles.title}>NutriSearch</Text>
        </View>
        <Image resizeMode="contain" source={Key} style={styles.keyImage} />
        <Text style={[styles.titleText, { marginTop: 25 }]}>
          {t("Reset Your Password")}
        </Text>
        <Text style={styles.infoText}>
          {t("Your password must be different from")}
        </Text>
        <Text style={styles.infoText}>{t("your old password")}</Text>
        <View style={styles.inputContainer}>
          <TextInput
            style={[styles.input, { paddingHorizontal: 15 }]}
            placeholder={t("Password")}
            placeholderTextColor={COLORS.primarygraycolor}
            value={password}
            onChangeText={setPassword}
            secureTextEntry={isActive}
          />
          <TouchableOpacity
            onPress={() => setIsActive(!isActive)}
            style={styles.eyeIconContainer}
          >
            <Image
              style={styles.eyeIcon}
              source={isActive ? Passwordcloseye : PasswordEye}
            />
          </TouchableOpacity>
        </View>
        {passwordErr ? (
          <Text style={styles.errorText}>{passwordErr}</Text>
        ) : null}
        <View style={styles.inputContainer}>
          <TextInput
            style={[styles.input, { paddingHorizontal: 15 }]}
            placeholder={t("Confirm Password")}
            placeholderTextColor={COLORS.primarygraycolor}
            value={confirmPassword}
            onChangeText={setConfirmPassword}
            secureTextEntry={isActive2}
          />
          <TouchableOpacity
            onPress={() => setIsActive2(!isActive2)}
            style={styles.eyeIconContainer}
          >
            <Image
              style={styles.eyeIcon}
              source={isActive2 ? Passwordcloseye : PasswordEye}
            />
          </TouchableOpacity>
        </View>
        {confirmPasswordErr ? (
          <Text style={styles.errorText}>{confirmPasswordErr}</Text>
        ) : null}
        <TouchableOpacity
          onPress={handleSubmit}
          activeOpacity={0.8}
          style={styles.submitButton}
        >
          <Text style={styles.buttonText}>{t("Reset Password")}</Text>
        </TouchableOpacity>
        <Pressable
          onPress={() => navigation.replace("Signin")}
          style={styles.loginLink}
        >
          <Text style={styles.loginLinkText}>{t("Back to Log in")}</Text>
        </Pressable>
      </View>
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
    padding: 20,
    alignItems: "center",
  },
  headerView: {
    width: "100%",
    flexDirection: "row",
    marginTop: Platform.OS === "web" ? 0 : 35,
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    height: 24,
    width: 24,
    position: "absolute",
    left: 0,
    top: 7,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 22,
    fontWeight: "600",
    color: COLORS.white,
    fontFamily: FONTS.PoppinsMedium,
  },
  keyImage: {
    width: 73,
    height: 62,
    marginTop: Platform.OS === "web" ? "2%" : "12%",
  },
  titleText: {
    fontSize: 18,
    fontWeight: "700",
    marginTop: 25,
    color: "black",
    fontFamily: FONTS.PoppinsMedium,
  },
  infoText: {
    fontSize: 14,
    fontWeight: "500",
    marginBottom: 10,
    color: "black",
    fontFamily: FONTS.PoppinsMedium,
  },
  inputContainer: {
    flexDirection: "row",
    width: Platform.OS === "web" ? "50%" : "100%",
    maxWidth: 436,
    minWidth: 325,
    marginTop: 10,
    borderWidth: 1,
    borderRadius: 8,
    height: 50,
    borderColor: COLORS.primarybordercolor,
  },
  input: {
    flex: 1,
    color: COLORS.primarygraycolor,
    fontFamily: FONTS.PoppinsMedium,
  },
  eyeIconContainer: {
    position: "absolute",
    height: 20,
    width: 20,
    right: "5%",
    top: "30%",
    justifyContent: "center",
    alignItems: "center",
  },
  eyeIcon: {
    height: 20,
    width: 20,
  },
  errorText: {
    color: "red",
    fontSize: 12,
    fontFamily: FONTS.PoppinsMedium,
    marginTop: 5,
  },
  submitButton: {
    marginTop: 20,
    width: Platform.OS === "web" ? "50%" : "100%",
    maxWidth: 436,
    minWidth: 325,
    borderRadius: 46,
    height: 50,
    backgroundColor: COLORS.primary,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.white,
  },
  loginLink: {
    flexDirection: "row",
    marginTop: Platform.OS === "web" ? 10 : 20,
  },
  loginLinkText: {
    fontSize: 14,
    lineHeight: 21,
    color: COLORS.primarygraycolor,
    fontFamily: FONTS.PoppinsRegular,
    textDecorationLine: "underline",
  },
});

export default ResetPassword;
