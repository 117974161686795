import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Image,
  TouchableOpacity,
  StatusBar,
} from "react-native";
import React, { useState, useRef } from "react";
import { API, COLORS, FONTS } from "../../../utils";
import { TextInput } from "react-native";
import { ScrollView } from "react-native";
import { Pressable } from "react-native";
import { Platform } from "react-native";
const { width, height } = Dimensions.get("screen");
export default function PasswordVerify({ navigation, route }) {
  const { email } = route?.params;
  let mail = email;
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [otp, setOtp] = useState(["", "", "", ""]);
  const LeftArrow = require("../../assets/images/arrow-left.png");
  const emailimg = require("../../assets/images/emailicon.png");
  const handleOTPChange = (text, index) => {
    if (/^[0-9]$/.test(text)) {
      otp[index] = text;
      setOtp([...otp]);

      if (index < 3) {
        inputRefs[index + 1].current.focus();
      }
    }
  };
  const handleBackspace = (event, index) => {
    if (event.nativeEvent.key === "Backspace") {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
      otp[index] = "";
      setOtp([...otp]);
    }
  };
  function verifyOtp() {
    const otpString = otp.join("");
    console.log("otp patern" + otpString);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
    var formdata = new FormData();
    formdata.append("email", email);
    formdata.append("otp", otpString);
    formdata.append("type", "2");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      // redirect: 'follow',
      // mode: ' // mode: 'no- cors''
    };

    fetch(API.baseurl + "forget_password", requestOptions)
      .then((response) => response?.json())
      .then((result) => {
        if (result?.message === "verify email and otp") {
          navigation.navigate("ResetPassword", { email: mail });
        } else {
          console.log("check see error");
        }
      })
      .catch((error) => console.log("error", error));
  }
  function handleResend() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
    var formdata = new FormData();
    formdata.append("email", mail);
    formdata.append("type", "1");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
      // mode: ' // mode: 'no- cors''
    };

    fetch("http://69.49.232.235/nutriSearch/forget_password", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  }
  return (
    <ScrollView style={{ flex: 1, backgroundColor: "white" }}>
      <StatusBar
        animated={true}
        barStyle={"light-content"}
        backgroundColor={"transparent"}
        translucent={true}
      />
      <View style={styles.container}>
        <View style={styles.headerView}>
          <Pressable style={styles.img} onPress={() => navigation.goBack()}>
            <Image style={styles.img} source={LeftArrow} />
          </Pressable>

          <Text style={styles.title}>NutriSearch</Text>
        </View>
        <Image
          resizeMode="contain"
          source={emailimg}
          style={{
            width: 73,
            height: 62,
            marginTop: Platform.OS === "web" ? "2%" : "12%",
          }}
        />
        <Text
          style={[
            styles.txt6,
            { marginTop: 25, color: "black", fontFamily: FONTS.PoppinsMedium },
          ]}
        >
          Verify Your Password
        </Text>
        <Text style={styles.txt7}>
          Enter the verification code that we have
        </Text>
        <Text style={styles.txt7}> sent to you</Text>

        <View style={styles.container2}>
          {inputRefs.map((inputRef, index) => (
            <TextInput
              editable={true}
              key={index}
              ref={inputRef}
              style={styles.input2}
              keyboardType="numeric"
              maxLength={1}
              value={otp[index]}
              onChangeText={(text) => handleOTPChange(text, index)}
              onKeyPress={(event) => handleBackspace(event, index)}
              fontSize={18}
              fontFamily={"PoppinsMedium"}
              fontWeight={"700"}
            />
          ))}
        </View>
        <TouchableOpacity onPress={() => handleResend()}>
          <Text
            style={{
              marginTop: 38,
              textDecorationLine: "underline",
              fontFamily: "PoppinsMedium",
              fontWeight: "300",
              fontSize: 14,
              color: COLORS.primary,
            }}
          >
            Resend
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => verifyOtp()}
          activeOpacity={0.8}
          style={styles.Signinbtn}
        >
          <Text style={styles.txt5}>Verify</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
    padding: 20,
    alignItems: "center",
  },
  headerView: {
    width: "100%",
    flexDirection: "row",
    marginTop: Platform.OS === "web" ? 0 : 35,
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    height: 24,
    width: 24,
    position: "absolute",
    left: 0,
    top: 7,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 22,
    fontWeight: "600",
    color: COLORS.white,
    fontFamily: FONTS.PoppinsMedium,
  },
  txt1: {
    fontFamily: FONTS.PoppinsMedium,
    fontWeight: "700",
    fontSize: 15,
    letterSpacing: 0.84,
    color: COLORS.black,
    // marginTop: Platform.OS === 'web' ? '4%':'13%'
    marginTop: Platform.OS === "web" ? 20 : 51,
  },
  txt2: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.black,
  },

  checkbox: { height: 16, width: 16, marginRight: "3%" },
  txt3: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "400",
    color: COLORS.primarygraycolor,
  },
  txt4: {
    marginTop: 33,
    textDecorationLine: "underline",
    fontFamily: FONTS.PoppinsMedium,
    fontWeight: "300",
    fontSize: 14,
    color: COLORS.primary,
  },
  Signinbtn: {
    marginTop: Platform.OS === "web" ? 40 : "28%",
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderRadius: 46,
    height: 50,
    backgroundColor: COLORS.primary,
    justifyContent: "center",
    alignItems: "center",
  },
  txt5: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.white,
  },
  txt6: {
    fontWeight: "700",
    fontSize: 18,
    lineHeight: 27,
    fontFamily: FONTS.PoppinsMedium,
    letterSpacing: 1,
  },
  txt7: {
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 21,
    letterSpacing: 0.5,
    color: "black",
    fontFamily: FONTS.PoppinsMedium,
  },
  container2: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    width: Platform.OS === "web" ? "50%" : "65%",
    maxWidth: 280,
    minWidth: 230,
  },
  input2: {
    borderTopColor: COLORS.primarybordercolor,
    borderRightColor: COLORS.primarybordercolor,
    borderLeftColor: COLORS.primarybordercolor,
    height: 50,
    width: 50,
    borderWidth: 1,
    borderRadius: 10,
    borderBottomWidth: 3,
    borderBottomColor: COLORS.primary,
    paddingLeft: 20,
  },
});
