import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Image,
  FlatList,
  Dimensions,
  Pressable,
  Alert,
  ActivityIndicator,
  Platform,
} from "react-native";
import { API, COLORS, FONTS } from "../../../utils";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
const { width } = Dimensions.get("window");
const LeftArrow = require("../../assets/images/whiteleftarrow.png");
const DEMO = require("../../assets/images/nutrilogo.png");
const Notification = ({ navigation }) => {
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();
  const [notifiData, setNotifiData] = useState([]);
  let previousSortDate = null;

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem("key");
      const id = await AsyncStorage.getItem("id");
      // setUserKey(value)
      // setId(id)
      if (value !== null || id !== null) {
        fetchFaqData(value, id);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchFaqData = async (value, id) => {
    setIsActive(true);
    try {
      const response = await fetch(`${API.baseurl}notification`, {
        method: "GET",
        headers: {
          id: id,
          session_key: value,
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      });
      const result = await response.json();
      // Alert.alert(JSON.stringify(result))
      // console.log(result)
      if (result.status == 200) {
        setNotifiData(result?.data);
        setIsActive(false);
      } else {
        setIsActive(false);
      }
      // setFaqData(result);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsActive(false);

      // setIsActive(false);
    }
  };
  const renderItem = ({ item, index }) => {
    const createdAt = new Date(item.created_at);
    const year = createdAt.getFullYear();
    const month = createdAt.toLocaleString("default", { month: "long" });
    const day = createdAt.getDate();

    const formattedDate = `${month} ${day}, ${year}`;

    const shouldShowSortDate = item?.sort_date !== previousSortDate;

    previousSortDate = item?.sort_date;

    return (
      <>
        {shouldShowSortDate && (
          <View style={styles.tab1}>
            <Text
              style={{
                color: COLORS.primary,
                fontSize: 14,
                fontFamily: FONTS.PoppinsMedium,
                textAlign: "center",
              }}
            >
              {item?.sort_date}
            </Text>
          </View>
        )}
        <View style={styles.itemContainer}>
          <View style={styles.cardContainer}>
            <View style={styles.imageContainer}>
              <Image source={DEMO} style={styles.image} />
            </View>
            <View style={styles.textContainer}>
              <Text
                style={[styles.nTitle, { borderWidth: 0, width: width - 210 }]}
              >
                {item?.title}
              </Text>
              <Text style={styles.nDes}>{item?.message}</Text>
            </View>
            <Text style={styles.time}>{formattedDate}</Text>
          </View>
        </View>
      </>
    );
  };
  return (
    <View style={{ flex: 1 }}>
      <StatusBar
        animated={true}
        barStyle={"light-content"}
        backgroundColor={"transparent"}
        translucent={true}
      />
      <View style={styles.header}>
        <Pressable
          style={styles.backButton}
          onPress={() => navigation.goBack()}
        >
          <Image source={LeftArrow} style={styles.backButtonImage} />
        </Pressable>
        <Text allowFontScaling={false} style={styles.title}>
          {t("Notifications")}
        </Text>
      </View>
      {isActive == false ? (
        <FlatList data={notifiData} renderItem={renderItem} />
      ) : (
        <ActivityIndicator
          size={"large"}
          color={COLORS.primary}
          style={{ marginTop: 200 }}
        />
      )}
    </View>
  );
};

export default Notification;

const styles = StyleSheet.create({
  header: {
    width: "100%",
    height: Platform.OS === "web" ? 78 : 100,
    backgroundColor: COLORS.primary,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    paddingTop: Platform.OS != "web" ? 45 : null,
    marginBottom: 18,
  },
  title: {
    fontSize: 22,
    color: COLORS.white,
    fontFamily: "Poppins-SemiBold",
    fontWeight: "600",
  },
  backButton: {
    position: "absolute",
    left: 15,
    top: Platform.OS == "web" ? 25 : 55,
  },
  backButtonImage: {
    height: 24,
    width: 24,
  },
  itemContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 6,
    marginTop: 5,
  },
  cardContainer: {
    borderWidth: 0,
    alignSelf: "center",
    height: "auto",
    flexDirection: "row",
    padding: 10,
    backgroundColor: "white",
    borderRadius: 10,
    shadowColor: COLORS.primary,
    shadowOffset: { width: 0, height: 14 },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    elevation: Platform.OS === "web" ? 0.1 : 5,
    maxWidth: 1000,
    minWidth: 325,
    padding: Platform.OS == "web" ? 15 : 6,
    borderRadius: 6,
    alignSelf: "center",
  },
  imageContainer: {
    width: "17%",
    borderWidth: 0,
  },
  image: {
    height: 50,
    width: 50,
    // borderRadius: 10
  },
  textContainer: {
    width: "83%",
    borderWidth: 0,
  },
  nTitle: {
    color: "black",
    fontSize: 16,
    // fontWeight: '700',
    fontFamily: "Poppins-Medium",
  },
  nDes: {
    color: "gray",
    fontSize: 13,
    fontWeight: "400",
    fontFamily: FONTS.PoppinsRegular,
    marginTop: 5,
    marginBottom: 11,
  },
  time: {
    position: "absolute",
    right: 10,
    bottom: 3,
    color: COLORS.primary,
    fontWeight: "700",
  },
  tab1: {
    borderWidth: 0,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 18,
    backgroundColor: "white",
    shadowColor: COLORS.primary,
    justifyContent: "center",
    alignItems: "center",
    shadowOffset: { width: "40%", height: 14 },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    elevation: Platform.OS === "web" ? 0.1 : 5,
    paddingBottom: 0,
    // maxWidth: Platform.OS != "web " ?width - 300:200,
    paddingLeft: 0,
    paddingRight: 0,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 6,
    marginTop: 5,
    borderWidth: 0,
    alignSelf: "center",
    height: "auto",
    flexDirection: "row",
    padding: 10,
    backgroundColor: "white",
    borderRadius: 10,
    shadowColor: COLORS.primary,
    shadowOffset: { width: 0, height: 14 },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    elevation: Platform.OS === "web" ? 0.1 : 5,

    // minWidth: 325,
    padding: Platform.OS == "web" ? 10 : 6,
    borderRadius: 100,
    alignSelf: "center",
    // alignSelf:'center'
  },
});
