
import { ColorValue } from "react-native";
import Svg, { Defs, G, Path, Rect, Use } from "react-native-svg"
interface StyledSvgProps {
    color?: ColorValue
}
export const EBookIcon = (props: StyledSvgProps) => {
    const { color, ...otherProps } = props;
    return (
        <Svg width="120" height="120" viewBox="0 0 120 120" fill="none" {...otherProps}>
            <G clip-path="url(#clip0_247_2254)">
                <Path d="M68.1999 39.7998H69.6999L71.9999 39.9998L74.4999 40.3998L77.4999 41.0998L80.2999 41.8998L82.8999 42.7998L84.0999 43.3998L84.4999 43.9998L84.7999 45.3998L87.6999 65.7998L88.7999 73.3998L88.9999 74.6998V76.0998L88.5999 76.7998L87.8999 77.3998L86.9999 77.5998L84.9999 77.1998L82.3999 76.4998L76.1999 74.7998L73.1999 74.0998L70.2999 73.5998L68.9999 73.4998H67.6999L66.0999 73.6998L64.7999 74.0998L63.7999 74.5998L62.7999 75.3998L61.8999 76.3998L61.1999 77.1998L60.5999 77.4998L59.7999 77.5998L59.0999 77.3998L58.0999 76.4998L56.9999 75.3998L55.6999 74.4998L54.2999 73.7998L52.9999 73.3998L51.9999 73.1998L50.6999 73.0998H49.5999L47.1999 73.2998L44.8999 73.6998L41.4999 74.5998L37.3999 75.9998L33.5999 77.4998L32.6999 77.5998L31.8999 77.2998L31.2999 76.7998L30.9999 76.1998L30.8999 75.3998L31.3999 71.6998L32.2999 65.5998L32.9999 60.5998L33.8999 54.3998L34.7999 47.8998L35.2999 44.5998L35.6999 43.5998L36.5999 42.9998L37.7999 42.3998L40.6999 41.2998L43.4999 40.4998L45.5999 40.0998L47.0999 39.8998H51.1999L53.8999 40.2998L56.0999 40.8998L57.9999 41.5998L59.7999 42.3998L61.8999 41.1998L63.3999 40.5998L64.8999 40.1998L66.6999 39.8998L68.1999 39.7998ZM68.2999 43.7998L66.1999 43.9998L64.1999 44.5998L62.5999 45.3998L61.9999 45.7998V70.8998L62.4999 70.7998L63.5999 70.2998L65.2999 69.7998L66.5999 69.5998H70.0999L72.9999 69.9998L75.8999 70.5998L78.5999 71.2998L83.9999 72.7998L84.5999 72.8998L84.4999 71.5998L82.4999 57.5998L80.9999 46.9998L80.7999 46.2998L77.9999 45.3998L75.0999 44.5998L72.0999 43.9998L69.7999 43.7998H68.2999ZM47.2999 43.8998L45.2999 44.1998L42.8999 44.7998L40.5999 45.5998L39.2999 46.0998L39.0999 46.2998L38.1999 52.4998L35.8999 68.5998L35.3999 71.9998V72.3998L36.2999 72.1998L39.1999 71.0998L42.2999 70.1998L44.3999 69.6998L46.8999 69.2998L47.7999 69.1998H51.8999L54.1999 69.5998L55.6999 70.0998L57.0999 70.6998L57.7999 71.0998H57.9999V46.0998L57.4999 45.7998L55.6999 44.9998L53.3999 44.2998L51.0999 43.8998H47.2999Z" fill={color || "#D7D7D7"} />
                <Path fill-rule="evenodd" clip-rule="evenodd" d="M35.3 0H84.7L87.1 0.6L88.7 1.2L90.3 2L92.1 3.2L93.3 4.3L94.3 5.3L95.3 6.6L96.1 7.9L97 9.8L97.6 11.7L97.9 13.5L98 17.1V103.9L97.9 106.4L97.6 108.1L97 110.1L95.9 112.4L94.9 113.8L94.1 114.8L93.8 115.2H93.6L93.4 115.6L92.4 116.5L91 117.5L89.4 118.4L87.8 119.1L85.8 119.7L84.4 120H35.6L32.7 119.3L31.1 118.7L29.2 117.7L28.1 116.9L27 116L25.7 114.7L24.5 113.1L23.6 111.6L22.7 109.4L22.3 108L22 105.7V14L22.3 12L22.9 10L23.8 8L24.7 6.6L25.6 5.4L26.6 4.3L27.4 3.6L28.7 2.6L30.2 1.7L31.7 1L33.5 0.4L35.3 0ZM26.1 24.3L26 24.4V95.6H93.9L94 95.5V24.4L93.9 24.3H26.1ZM51.4001 10.9004H68.6001L69.5001 11.2004L70.4001 12.0004L70.8001 12.8004L70.9001 13.2004V14.1004L70.6001 14.9004L70.0001 15.7004L69.2001 16.2004L68.7001 16.3004H51.1001L50.1001 15.8004L49.5001 15.1004L49.1001 14.2004V13.1004L49.5001 12.1004L50.0001 11.5004L50.9001 11.0004L51.4001 10.9004ZM61.1 103.7H58.9L58.5 103.8L57.3 104.3L56.4 104.9L55.5 105.9L54.9 107L54.5 108.6V109.6L54.8 110.9L55.6 112.4L56.6 113.4L57.7 114.1L59.1 114.5H60.7L61.2 114.4L62.4 114L63.5 113.3L64.4 112.3L65 111.3L65.4 110V108.1L65.3 107.7L64.9 106.6L64 105.3L63.3 104.7L62.3 104.1L61.1 103.7Z" fill={color || "#D7D7D7"} />
                <Path d="M41.8998 59.4004H53.1998L53.8998 59.7004L54.3998 60.1004L54.7998 60.9004V61.8004L54.3998 62.6004L53.6998 63.2004L52.9998 63.4004H42.0998L41.3998 63.2004L40.7998 62.7004L40.3998 62.0004L40.2998 61.1004L40.5998 60.3004L41.3998 59.6004L41.8998 59.4004Z" fill={color || "#D7D7D7"} />
                <Path d="M41.9998 51.9004H52.9998L53.8998 52.2004L54.3998 52.7004L54.7998 53.5004V54.4004L54.2998 55.2004L53.5998 55.8004L53.1998 55.9004H41.7998L41.1998 55.6004L40.5998 54.9004L40.2998 54.3004V53.6004L40.7998 52.6004L41.3998 52.1004L41.9998 51.9004Z" fill={color || "#D7D7D7"} />
                <Path d="M67.0002 51.9004H77.9002L78.8002 52.2004L79.4002 52.9004L79.7002 53.6004V54.3004L79.1002 55.3004L78.7002 55.7004L78.1002 55.9004H66.6002L66.0002 55.5004L65.4002 54.8004L65.2002 54.3004V53.5004L65.6002 52.7004L66.3002 52.1004L67.0002 51.9004Z" fill={color || "#D7D7D7"} />
                <Path d="M66.7002 59.4004H78.1002L78.8002 59.7004L79.3002 60.2004L79.7002 61.1004L79.6002 62.0004L79.0002 62.9004L78.4002 63.3004L77.8002 63.4004H66.9002L66.2002 63.1004L65.6002 62.6004L65.2002 61.8004V61.0004L65.6002 60.2004L66.4002 59.5004L66.7002 59.4004Z" fill={color || "#D7D7D7"} />
                <Path d="M59.5002 106.3H60.2002L61.2002 106.6L61.8002 107L62.4002 107.7L62.7002 108.5V109.6L62.4002 110.5L61.7002 111.3L60.9002 111.7L60.6002 111.8H59.4002L58.4002 111.4L57.7002 110.7L57.3002 109.8L57.2002 109L57.5002 107.8L58.1002 107L59.1002 106.4L59.5002 106.3Z" fill={color || "#D7D7D7"} />
            </G>
            <Defs>
                {/* <clipPath id="clip0_247_2254">
                    <Rect width="120" height="120" fill="white" />
                </clipPath> */}
            </Defs>
        </Svg>
    )
}
