import React, { useState, useEffect, useContext } from 'react';
import { View, Text, StyleSheet, Image, Platform, Dimensions, TouchableOpacity, StatusBar, ActivityIndicator, Pressable, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { WebView } from 'react-native-webview';
import { DataContext } from '../../components/DataContext';
import { useTranslation } from 'react-i18next';
import { useRoute } from '@react-navigation/native';
import { API, COLORS, FONTS } from '../../../utils';
import { Toast } from 'react-native-toast-notifications';

const { width, height } = Dimensions.get('window');
const LeftArrow = require('../../assets/images/whiteleftarrow.png');
const ClickIcon = require('../../assets/images/clickicon.png');

export default function UpgradeSubscription({ navigation }) {
    const { data3, data } = useContext(DataContext);
    const route = useRoute();
    const currentPlan = route?.params?.Plan;
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [planValue, setPlanValue] = useState({});
    const [urlActive, setUrlActive] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false); // State for loading indicator
    const { t } = useTranslation();

    useEffect(() => {
        const availablePlans = data3.data.filter(plan =>
            Math.trunc(parseFloat(plan.plan_price)) !== Math.trunc(parseFloat(currentPlan.Amount))
        );
        setSubscriptionData(availablePlans);
        setPlanValue(availablePlans[0] || {});
    }, [data3, currentPlan]);

    const notifyMessage = (msg) => {
        if (Platform.OS === 'ios') {
            Alert.alert(msg);
        } else {
            Toast.show(msg, {
                type: "normal",
                placement: "bottom",
                duration: 3000,
                offset: 0,
                animationType: "zoom-in",
            });
        }
    };

    const handleSubscription = async () => {
        const id = await AsyncStorage.getItem('id');
        const sessionKey = await AsyncStorage.getItem('key');
        setIsLoading(true); // Start loading indicator

        fetch(`${API.baseurl}${API.upgradesubscription}?newPlanId=${planValue?.stripe_plan_id}`, {
            method: 'GET',
            headers: {
                "id": id,
                "session_key": sessionKey,
                "Authorization": "Basic YWRtaW46MTIzNA==",
                "Cookie": "ci_session=2b19bf32b9a2f50974f1700c1479e593ec66dae2"
            },
            redirect: 'follow',
        })
            .then(response => response.json())
            .then(result => {
                notifyMessage(result.message);
                if (result.status === 200) {
                    navigation.goBack();
                }
            })
            .catch(() => notifyMessage('An error occurred. Please try again.'))
            .finally(() => setIsLoading(false)); // Stop loading indicator
    };

    // const renderPlan = (plan, index) => (

    //     <Pressable
    //         key={index}
    //         onPress={() => setPlanValue(plan)}
    //         style={[styles.planContainer, {
    //             backgroundColor: planValue.id === plan.id ? COLORS.primaryLite : 'transparent',
    //             borderStyle: planValue.id === plan.id ? 'solid' : 'dashed',
    //             borderWidth: planValue.id === plan.id ? 0 : 1
    //         }]}
    //     >
    //         <Text style={[styles.planTitle, { color: planValue.id === plan.id ? 'white' : COLORS.primary }]}>
    //             {t(plan.plan_name)}
    //         </Text>
    //         <View>
    //             <Text>
    //                 <Text style={[styles.planPrice, { color: planValue.id === plan.id ? 'white' : COLORS.primary }]}>
    //                     $ {plan.plan_price}/
    //                 </Text>
    //                 <Text style={[styles.planDuration, { color: planValue.id === plan.id ? 'white' : COLORS.primarygraycolor }]}>
    //                     {t(plan.plan_duration)}
    //                 </Text>
    //             </Text>
    //         </View>
    //         {planValue.id === plan.id && (
    //             <Image source={ClickIcon} style={styles.clickIcon} />
    //         )}
    //     </Pressable>
    // );

    const renderPlan = (plan, index) => {
        if (plan.plan_name === "Quarterly") return null;

        return (
            <Pressable
                key={index}
                onPress={() => setPlanValue(plan)}
                style={[styles.planContainer, {
                    backgroundColor: planValue.id === plan.id ? COLORS.primaryLite : 'transparent',
                    borderStyle: planValue.id === plan.id ? 'solid' : 'dashed',
                    borderWidth: planValue.id === plan.id ? 0 : 1
                }]}
            >
                <Text
                    // allowFontScaling={false}

                    style={[styles.planTitle, { color: planValue.id === plan.id ? 'white' : COLORS.primary }]}>
                    {t(plan.plan_name)}
                </Text>
                <View>
                    <Text
                        // allowFontScaling={false}

                    >
                        <Text style={[styles.planPrice, { color: planValue.id === plan.id ? 'white' : COLORS.primary }]}>
                            $ {plan.plan_price}/
                        </Text>
                        <Text style={[styles.planDuration, { color: planValue.id === plan.id ? 'white' : COLORS.primarygraycolor }]}>
                            {t(plan.plan_duration)}
                        </Text>
                    </Text>
                </View>
                {planValue.id === plan.id && (
                    <Image source={ClickIcon} style={styles.clickIcon} />
                )}
            </Pressable>
        );
    };


    return (
        <View style={styles.container}>
            <StatusBar animated={true} barStyle='light-content' backgroundColor='transparent' translucent={true} />
            {urlActive ? (
                <WebView style={{ flex: 1, marginTop: 40 }} source={{ uri: paymentUrl }} />
            ) : (
                <>
                    <View style={styles.headerView}>
                        <Pressable onPress={() => navigation.goBack()} style={styles.backButton}>
                            <Image style={styles.backImage} source={LeftArrow} />
                        </Pressable>
                        <Text style={styles.headerTitle} allowFontScaling={false}>{t("Upgrade Subscription")}</Text>
                    </View>
                    <View style={styles.content}>
                        <View style={styles.introTextContainer}>
                            <Text style={styles.introText}
                                allowFontScaling={false}

                            >
                                {t("Explore a variety of plans designed for your unique needs. Subscribe today and let's craft a healthier future together")}
                            </Text>
                        </View>
                        {subscriptionData.map(renderPlan)}
                        {isLoading ? (
                            <ActivityIndicator
                                size='large'
                                style={styles.activityIndicator}
                                color={COLORS.primary}
                            />
                        ) : (
                            <TouchableOpacity
                                onPress={handleSubscription}
                                activeOpacity={0.8}
                                style={styles.upgradeButton}
                            >
                                <Text
                                    // allowFontScaling={false}

                                    style={styles.buttonText}>{t("Upgrade Now")}</Text>
                            </TouchableOpacity>
                        )}
                        <TouchableOpacity
                            onPress={() => navigation.navigate('UpgradeBulksubscription', { "Plan": currentPlan })}
                            activeOpacity={0.8}
                            style={[styles.upgradeButton, styles.bulkButton]}
                        >
                            <Text
                                // allowFontScaling={false}

                                style={styles.buttonText}>{t("Bulk Subscription")}</Text>
                        </TouchableOpacity>
                    </View>
                </>
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    container: { flex: 1, backgroundColor: COLORS.primary },
    headerView: { width: '100%', flexDirection: 'row', marginTop: Platform.OS === 'web' ? 20 : 60, alignItems: 'center', justifyContent: 'center' },
    backButton: { height: 24, width: 24, position: 'absolute', left: 20, top: 7 },
    backImage: { height: 24, width: 24 },
    headerTitle: { alignSelf: 'center', textAlign: 'center', fontSize: 22, color: COLORS.white, fontFamily: 'Poppins-SemiBold', fontWeight: Platform.OS === 'web' ? 'normal' : '600' },
    content: { height: height * 2, backgroundColor: COLORS.white, marginTop: 50, borderTopRightRadius: 32, borderTopLeftRadius: 32, padding: 20 },
    introTextContainer: { width: Platform.OS === 'web' ? '50%' : width - 40, maxWidth: 436, minWidth: 325, alignSelf: 'center' },
    introText: { marginTop: 30, fontFamily: FONTS.PoppinsMedium, fontSize: 14, fontWeight: '500', color: COLORS.black, textAlign: 'center' },
    planContainer: { paddingLeft: 30, paddingRight: 30, borderColor: COLORS.primary, height: Platform.OS === 'web' ? 50 : 50, borderRadius: 6, marginTop: 16, justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', width: Platform.OS === 'web' ? '50%' : width - 40, maxWidth: 436, minWidth: 325, alignSelf: 'center' },
    planTitle: { fontFamily: FONTS.PoppinsMedium, fontSize: 14, fontWeight: '400' },
    planPrice: { fontFamily: FONTS.PoppinsMedium, fontSize: 14, fontWeight: '400' },
    planDuration: { fontFamily: FONTS.PoppinsMedium, fontSize: 14, fontWeight: '400' },
    clickIcon: { height: 19, width: 19, position: 'absolute', right: 0, top: -10 },
    activityIndicator: { marginTop: 20 },
    upgradeButton: { margin: 'auto', marginBottom: 20, marginTop: Platform.OS === 'web' ? 20 : 85, width: Platform.OS === 'web' ? '50%' : width - 92, maxWidth: 436, minWidth: 325, borderRadius: 46, height: 50, backgroundColor: COLORS.primary, justifyContent: 'center', alignItems: 'center', alignSelf: 'center' },
    bulkButton: { marginTop: 7 },
    buttonText: { fontFamily: FONTS.PoppinsMedium, fontSize: 14, fontWeight: "500", color: COLORS.white },
});
