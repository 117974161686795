import React, { useEffect, useState, useRef, useContext } from "react";
import {
  ScrollView,
  KeyboardAvoidingView,
  View,
  Text,
  Image,
  TextInput,
  Pressable,
  StyleSheet,
  Platform,
  ImageBackground,
  TouchableOpacity,
  StatusBar,
  ActivityIndicator,
  FlatList,
  LayoutAnimation,
  Animated,
  PanResponder,
  Dimensions,
  Alert,
  Keyboard,
} from "react-native";

import * as ImagePicker from "expo-image-picker";
import { API, COLORS, FONTS } from "../../../utils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import i18next, { languageResources } from "../../../services/i18next";
import { useTranslation } from "react-i18next";
import { Toast } from "react-native-toast-notifications";
import { BlurView } from "expo-blur";
import { OrientationContext } from "../../components/OrientationContext";
import PhoneInput from "react-native-phone-number-input";
import images from "../../../utils";
const { width, height } = Dimensions.get("window");
const Account = ({ navigation }) => {
  const phoneInput = useRef(null);
  const isLandscape = useContext(OrientationContext);
  const currentLanguage = i18next.language;

  // Images
  const Request = require("../../assets/images/request.png");
  const ActiveAccount = require("../../assets/images/ActiveAccount.png");
  const LogOut = require("../../assets/images/logout.png");
  const FillDot = require("../../assets/images/filldot.png");
  const UnfillDot = require("../../assets/images/unfilldot.png");
  const crossCircle = require("../../assets/images/crosscircle.png");
  const passwordCloseEye = require("../../assets/images/passwordcloseeye.png");
  const Camera = require("../../assets/images/camera.png");
  const Mobile = require("../../assets/images/Mobile.png");
  const Browser = require("../../assets/images/Browserr.png");
  const LogoutQuestionIcon = require("../../assets/images/logoutQuestionIcon.png");
  const selector = require("../../assets/images/imgselector.png");
  const eye = require("../../assets/images/blackeye.png");
  const defaultImg = require("../../assets/images/userDefault.png");
  // const defaultImg =
  //   "https://thumbs.dreamstime.com/b/default-avatar-profile-image-vector-social-media-user-icon-potrait-182347582.jpg";
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [oldpasswordErr, setOldPasswordErr] = useState("");
  const [opVisibility, setOpPVisibility] = useState(true);
  const [pVisibility, setPVisibility] = useState(true);
  const [cPVisibility, setCpVisibility] = useState(true);
  const [userNameError, setUserNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [logoutIndicator, setLogoutIndicator] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const fadeAnimm = useRef(new Animated.Value(0)).current;
  const [isVisible2, setIsVisible2] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const translateY = useRef(new Animated.Value(800)).current;
  const [isVisible, setIsVisible] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [width, setWidth] = useState(350);
  const [passwordSection, setPasswordSection] = useState(false);
  const [currentLogoutId, setCurrentLogoutId] = useState(2);
  const [countryCode, setCountryCode] = useState("");
  const [currentValue, setCurrentValue] = useState(0);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [userKey, setUserKey] = useState("");
  const [id, setId] = useState("");
  const [activeAccountList, setActiveAccountList] = useState([]);
  const [leftModal, setLeftModal] = useState(false);
  const [username, setUserName] = useState("");
  const [isAvailable, setIsAvailable] = useState("");
  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [currentData, setCurrentData] = useState(null);
  const [isSocialLogin, setIsSocialLogin] = useState(false);

  useEffect(() => {
    setSelectedItem(currentLanguage);
    getData();
  }, []);
  // language selector Funciton
  const changeLng = (lng) => {
    i18next.changeLanguage(lng);
    setVisible(false);
    handleSelectedLanguage(lng);
  };
  // Animated show or close Popup
  const showPopup = () => {
    setVisible(true);
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };
  const hidePopup = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      setVisible(false);
    });
  };
  const showPopup2 = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
    setIsVisible2(true);
  };
  const hidePopup22 = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => setIsVisible2(false));
  };
  const showPopup3 = () => {
    Animated.timing(fadeAnimm, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
    setIsVisible3(true);
  };
  const hidePopup3 = () => {
    Animated.timing(fadeAnimm, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => setIsVisible3(false));
  };
  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: (_, gestureState) => gestureState.dy > 5,
      onPanResponderMove: (_, gestureState) => {
        if (gestureState.dy > 0) {
          translateY.setValue(gestureState.dy);
        }
      },
      onPanResponderRelease: (_, gestureState) => {
        if (gestureState.dy > 50) {
          toggleBottomSheet(false);
          setIsVisible(false);
        } else {
          toggleBottomSheet(true);
          setIsVisible(true);
        }
      },
    })
  ).current;
  const toggleBottomSheet = (show) => {
    Animated.spring(translateY, {
      toValue: show ? 0 : 800,
      useNativeDriver: true,
      duration: 300,
    }).start(() => {});
  };
  const openBottomSheet = () => {
    setIsVisible(true);
    toggleBottomSheet(true);
  };
  const closeBottomSheet = () => {
    setIsVisible(false);
    toggleBottomSheet(false);
    setCurrentValue(0);
  };
  const hidePopup2 = () => {
    hidePopup22();
  };
  //Current User Active Plan
  function getActiveAccountsDetails() {
    const myHeaders = new Headers();
    myHeaders.append("id", id);
    myHeaders.append("session_key", userKey);
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
    myHeaders.append("Cookie", "ci_session=babo9h57iekovuikvfdiqgp7a31js37c");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API.baseurl + "fetchaccount", requestOptions)
      .then((response) => response.json())
      .then((result) => setActiveAccountList(result?.data))
      .catch((error) => console.error(error));
  }
  const togglePasswordSection2 = () => {
    setPopupVisible(!isPopupVisible);
  };
  const togglePasswordSection = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setPasswordSection(!passwordSection);
  };
  //For above Right modal
  function toggalLeftModal() {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setLeftModal(!leftModal);
  }
  //For Notification
  function notifyMessage(msg) {
    if (Platform.OS === "ios") {
      // Alert.alert(msg);
    } else {
      Toast.show(msg, {
        type: "normal",
        placement: "bottom",
        duration: 1200,
        offset: 0,
        animationType: "zoom-in",
      });
    }
  }
  //for Logout
  function handlelogout() {
    setLogoutIndicator(true);
    setIsActive(true);
    var myHeaders = new Headers();
    myHeaders.append("id", id);
    myHeaders.append("session_key", userKey);
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
    var formdata = new FormData();
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      // mode: ' // mode: 'no-cors''
    };

    fetch(
      API.baseurl + API.logout + "?id=" + id + "&session_key=" + userKey,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        let b = result;
        if (b?.status === 200) {
          storeData();
          notifyMessage(b?.message);
          navigation.replace("Signin");
          setIsActive(false);
          setLogoutIndicator(false);
        } else if (b?.status === 401) {
          notifyMessage(b?.message);
          setIsActive(false);
          setLogoutIndicator(false);
        } else {
          notifyMessage(b?.message);
          setIsActive(false);
          setLogoutIndicator(false);
        }
      })
      .catch((error) => {
        console.log("error", error), notifyMessage(error), setIsActive(false);
      });
  }
  // Store in Local Storage
  const storeData = async () => {
    try {
      await AsyncStorage.setItem("key", "");
      await AsyncStorage.setItem("id", "");
      await AsyncStorage.setItem("selectedItems", "");
    } catch (e) {
      console.log("error", e);
    }
  };
  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem("key");
      const id = await AsyncStorage.getItem("id");
      setUserKey(value);
      setId(id);
      if (value != "" || id != "") {
        handlegetData(value, id);
        // getActiveAccountsDetails(value,id)
      } else {
        navigation.navigate("Signin");
      }
    } catch (e) {
      console.log(e);
    }
  };
  function handlegetData(v, id) {
    setIsActive(true);
    var myHeaders = new Headers();
    myHeaders.append("id", id);
    myHeaders.append("session_key", v);
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
    myHeaders.append(
      "Cookie",
      "ci_session=2b19bf32b9a2f50974f1700c1479e593ec66dae2"
    );
    var formdata = new FormData();
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      API.baseurl + API.getprofile + "?id=" + id + "&session_key=" + v,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.message == "Client Error: Unauthorized") {
          navigation.replace("Signin");
        }
        if (result?.status == 200) {
          result.data.phone =
            result.data.phone == null ? " " : result.data.phone;
          // Alert.alert("ddd", JSON.stringify(result.data))
          if (result.data.social_id != null) {
            setIsSocialLogin(false);
          } else {
            setIsSocialLogin(true);
          }
          setCountryCode(
            result.data.country_code ? result.data.country_code : 0
          );
          setUserName(result?.data?.username);
          setName(result?.data?.username);
          setEmail(result?.data?.email);
          setMobileNumber(result.data.phone);
          setIsAvailable(result?.data?.phone);
          setImage(result?.data?.image);
          setImage2(result?.data?.background_image);
          setIsActive(false);
          if (result?.data.image == API.baseurl) {
            setImg1("");
          } else {
            setImg1(result?.data?.image);
          }
          if (result?.data?.background_image == API.baseurl) {
            setImg2("");
          } else {
            setImg2(result?.data?.background_image);
          }
        } else if (result?.status == 400) {
          notifyMessage(result?.message);
          setIsActive(false);
        } else {
          notifyMessage(result?.message);
          setIsActive(false);
          navigation.navigate("Signin");
        }
      })
      .catch((error) => {
        console.log("error", error), notifyMessage(error), setIsActive(false);
        navigation.navigate("Signin");
      });
  }
  //Image Selector
  let isChoosingFile = false;
  const chooseFile = async (type, num) => {
    if (isChoosingFile) {
      return;
    }
    isChoosingFile = true;
    try {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        base64: true,
        aspect: [4, 3],
        quality: 0.3,
      });

      if (!result.canceled) {
        if (num === 1) {
          setImage("data:image/png;base64," + result?.assets[0]?.base64);
          setImg1("data:image/png;base64," + result?.assets[0]?.base64);
        } else {
          setImage2("data:image/png;base64," + result?.assets[0]?.base64);
          setImg2("data:image/png;base64," + result?.assets[0]?.base64);
        }
      }
    } catch (error) {
      console.error("Error selecting image:", error);
    } finally {
      isChoosingFile = false;
    }
  };
  const validateInputs = () => {
    let formIsValid = true;
    if (!name) {
      setUserNameError("Name is required");
      formIsValid = false;
    } else {
      setUserNameError("");
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email) {
      setEmailError("Enter email address");
      formIsValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter a valid email address");
      formIsValid = false;
    } else {
      setEmailError("");
    }
    return formIsValid;
  };
  const handleSubmit = () => {
    let formIsValid = true;
    if (!validateInputs()) {
      console.log("Error", "Please fill in all fields correctly");
      return;
    } else if (passwordSection === true) {
      if (!oldPassword) {
        setOldPasswordErr("Enter Old Password");
        formIsValid = false;
      } else {
        setOldPasswordErr("");
      }
      if (!password) {
        setPasswordError("Enter new Password");
        formIsValid = false;
      } else if (password.length < 8) {
        setPasswordError("Password must be at least 8 characters long");
        formIsValid = false;
      } else {
        setPasswordError("");
      }
      if (!confirmPassword) {
        setconfirmPasswordError("Enter confirm Password");
        formIsValid = false;
      } else if (confirmPassword !== password) {
        setconfirmPasswordError("Passwords do not match");
        formIsValid = false;
      } else {
        setconfirmPasswordError("");
      }
      if (formIsValid) {
        handlePasswordChange();
      }
    } else {
      handleSave();
    }
  };
  const handleSave = async () => {
    setIsActive(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("id", id);
      myHeaders.append("session_key", userKey);
      myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
      if (Platform.OS != "web") {
        myHeaders.append("Content-Type", "multipart/form-data");
      }
      const formdata = new FormData();
      formdata.append("username", name);
      formdata.append("email", email);
      formdata.append("phone", mobileNumber);
      formdata.append("country_code", countryCode);
      if (image) {
        formdata.append("image", image);
      }
      if (image2) {
        formdata.append("background_image", image2);
      }
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
        // mode: 'no-cors'
      };
      const response = await fetch(
        API.baseurl + API.profileupdate,
        requestOptions
      );
      const result = await response.json();
      if (result.status === 200) {
        notifyMessage(result?.message);
        setName(result?.data?.username);
        setUserName(result?.data?.username);
        setEmail(result?.data?.email);
        setMobileNumber(result?.data?.phone);
        setIsAvailable(result?.data?.phone);
        setCountryCode(result?.data?.country_code);

        setIsActive(false);
      } else if (result?.status === 400) {
        setIsActive(false);
        notifyMessage(result?.message);
      } else {
        setIsActive(false);
        notifyMessage(result?.message);
      }
    } catch (error) {
      console.log("error", error);
      setIsActive(false);
      notifyMessage(error.toString());
    }
  };
  function handlePasswordChange() {
    setIsActive(true);
    var myHeaders = new Headers();
    myHeaders.append("id", id);
    myHeaders.append("session_key", userKey);
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");

    var formdata = new FormData();
    formdata.append("password", oldPassword);
    formdata.append("newpassword", password);
    formdata.append("Cpassword", confirmPassword);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
      // mode: ' // mode: 'no-cors''
    };

    fetch(
      API.baseurl +
        API.changepassword +
        "?id=" +
        id +
        "&session_key=" +
        userKey,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        if (result?.status === 200) {
          notifyMessage(result?.message);
          setOldPassword("");
          setPassword("");
          setConfirmPassword("");
          setconfirmPasswordError("");
          setPasswordSection(false);
          setIsActive(false);
        } else if (result?.status === 400) {
          notifyMessage(result?.message);
          setIsActive(false);
        } else {
          notifyMessage(result?.message);
          setIsActive(false);
        }
      })
      .catch((error) => {
        setIsActive(false);
        console.error("Error:", error);
        notifyMessage(error?.message);
      });
  }
  function handleNavigation(id) {
    if (id === 1) {
      navigation.navigate("MySubscripion");
      setLeftModal(false);
    } else if (id === 2) {
      showPopup3();
      setLeftModal(false);
    } else if (id === 3) {
      showPopup2();
      setLeftModal(false);
    } else if (id === 4) {
      setPasswordSection(false);
      setLeftModal(false);
      openBottomSheet();
      setCurrentValue(1);
    } else if (id === 5) {
      setPasswordSection(false);
      setLeftModal(false);
      openBottomSheet();
      setCurrentValue(2);
    }
  }
  const handleSelectedLanguage = async (value) => {
    try {
      await AsyncStorage.setItem("Language", value);
    } catch (e) {
      console.log(JSON.stringify(e));
    }
  };
  function renderActiveAccList({ item }) {
    return (
      <View
        style={{
          marginTop: 3,
        }}
      >
        <View
          activeOpacity={0.6}
          style={{
            width:
              Platform.OS === "web" ? (width < 400 ? "90%" : "50%") : "90%",
            maxWidth: 436,
            minWidth: 325,
            borderRadius: 10,
            height: 50,
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            backgroundColor: "white",
            shadowColor: "gray",
            shadowOffset: { width: 0, height: Platform.OS == "web" ? 14 : 2 },
            shadowOpacity: 0.3,
            shadowRadius: 6,
            elevation: Platform.OS === "web" ? 0.1 : 2,
            paddingRight: 10,
            marginBottom: 15,
          }}
        >
          <View
            style={{
              borderWidth: 0,
              width: "15%",
              height: 50,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              style={{ height: 30, width: 30 }}
              source={item?.device_type == "3" ? Browser : Mobile}
            />
          </View>
          <View
            style={{
              borderWidth: 0,
              width: "65%",
              height: 50,
              justifyContent: "center",
            }}
          >
            <View style={{ borderWidth: 0, height: 40 }}>
              <Text
                allowFontScaling={false}
                numberOfLines={1}
                style={{
                  color: COLORS.primary,
                  fontSize: 14,
                  fontFamily: FONTS.PoppinsRegular,
                }}
              >
                {item?.device_name
                  ? item.device_name.charAt(0).toUpperCase() +
                    item.device_name.slice(1)
                  : ""}
              </Text>
              <Text
                allowFontScaling={false}
                numberOfLines={1}
                style={{
                  color: COLORS.darkbordercolor,
                  fontSize: 13,
                  fontFamily: FONTS.PoppinsRegular,
                }}
              >
                Last Login {item?.created_at}
              </Text>
            </View>

            {/* <Text  numberOfLines={1} style={{color:COLORS.primary,fontSize:15,fontFamily:FONTS.PoppinsRegular}}>{item?.device_name}</Text> */}
          </View>
          <View
            style={{
              borderWidth: 0,
              width: "20%",
              height: 50,
              justifyContent: "center",
            }}
          >
            {item?.current_user == 0 ? (
              <TouchableOpacity
                // onPress={()=>logoutActiveAcc(item?.device_uuid)}
                onPress={() => {
                  showPopup(), setCurrentData(item?.device_uuid);
                }}
                activeOpacity={0.4}
                style={{
                  width: "auto",
                  height: 37,
                  borderWidth: 0,
                  backgroundColor: COLORS.primary,
                  borderRadius: 7,
                  justifyContent: "center",
                  alignItems: "center",
                  borderWidth: 0,
                }}
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: 14,
                    fontFamily: FONTS.PoppinsRegular,
                  }}
                >
                  {t("logout")}
                </Text>
              </TouchableOpacity>
            ) : null}
          </View>
        </View>
      </View>
    );
  }
  function logoutActiveAcc() {
    hidePopup();
    const myHeaders = new Headers();
    myHeaders.append("id", id);
    myHeaders.append("session_key", userKey);
    myHeaders.append("Cookie", "ci_session=bml3imvgits35oe0t1jntna9hq6pcdv0");

    const formdata = new FormData();
    formdata.append("device_uuid", currentData);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(API.baseurl + "deleteloggedaccount", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 200) {
          getActiveAccountsDetails();
        }
      })
      .catch((error) => console.error(error));
  }
  const inputAccessoryViewID = "uniqueID"; // Unique ID for InputAccessoryView
  const [inputValue, setInputValue] = useState("");
  return (
    <View
      style={{
        backgroundColor: "#F2F2F2",
        flex: 1,
        marginLeft: isLandscape ? 75 : 0,
      }}
    >
      <ScrollView
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        bounces={true}
      >
        <Pressable onPress={() => setLeftModal(false)}>
          <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : ""}
            keyboardVerticalOffset={Platform.OS === "ios" ? 20 : 0}
            style={{
              flex: 1,
              backgroundColor: "#F2F2F2",
              justifyContent: "center",
            }}
          >
            <View style={styles.container}>
              <StatusBar
                animated={true}
                barStyle={"light-content"}
                backgroundColor={"transparent"}
                translucent={true}
              />
              {image2 ? (
                <Pressable onPress={() => setLeftModal(false)}>
                  <ImageBackground
                    resizeMode="cover"
                    source={{ uri: image2 }}
                    style={[styles.header, { paddingLeft: 0, paddingRight: 0 }]}
                  >
                    <TouchableOpacity
                      onPress={() => navigation.navigate("Notification")}
                      style={{
                        zIndex: 1,
                        width: 35,
                        height: 35,
                        marginTop: 45,
                        left: 15,
                        backgroundColor: COLORS.primary,
                        borderRadius: 100,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        style={{
                          width: 25,
                          height: 25,
                        }}
                        source={require("../../assets/images/notificationicon.png")}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={styles.cameraIcon}
                      onPress={() => chooseFile("photo")}
                    >
                      <Image
                        resizeMode="contain"
                        style={{
                          height: 18,
                          width: 18,
                        }}
                        source={Camera}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        toggalLeftModal(), getActiveAccountsDetails();
                      }}
                      style={{
                        height: 35,
                        width: 35,
                        position: "absolute",
                        right: 15,
                        top: 45,
                        backgroundColor: COLORS.primary,
                        borderRadius: 100,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        resizeMode="contain"
                        style={{
                          height: 22,
                          width: 22,
                        }}
                        source={images.icons?.dot}
                      />
                    </TouchableOpacity>
                    {/* </BlurView> */}
                  </ImageBackground>
                </Pressable>
              ) : (
                <Pressable onPress={() => setLeftModal(false)}>
                  <View style={styles.header}>
                    <Pressable
                      style={{
                        height: 35,
                        width: 35,
                        position: "absolute",
                        right: 15,
                        bottom: 15,

                        backgroundColor: COLORS.primary,
                        borderRadius: 100,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onPress={() => chooseFile("photo")}
                    >
                      <Image
                        resizeMode="contain"
                        style={{
                          height: 20,
                          width: 20,
                        }}
                        source={Camera}
                      />
                    </Pressable>
                  </View>
                  <TouchableOpacity
                    onPress={() => toggalLeftModal()}
                    style={{
                      height: 23,
                      width: 23,
                      position: "absolute",
                      right: 15,
                      top: 45,
                    }}
                  >
                    <Image
                      resizeMode="contain"
                      style={{
                        height: 25,
                        width: 25,
                      }}
                      source={images.icons?.dot}
                    />
                  </TouchableOpacity>
                </Pressable>
              )}
              <Pressable
                onPress={() => setLeftModal(false)}
                style={[
                  styles.profileImageContainer,
                  { marginTop: Platform.OS === "web" ? -50 : -50 },
                ]}
              >
                {image == "" ? (
                  <Image style={styles.profileImage} source={defaultImg} />
                ) : image == API.baseurl ? (
                  <Image style={[styles.profileImage]} source={defaultImg} />
                ) : (
                  <Image
                    defaultSource={{ uri: image }}
                    source={{ uri: image }}
                    style={styles.profileImage}
                  />
                )}
                <Pressable
                  onPress={() => chooseFile("photo", 1)}
                  style={styles.imageSelector}
                >
                  <Image
                    resizeMode="contain"
                    style={styles.selectorIcon}
                    source={selector}
                  />
                </Pressable>
              </Pressable>
              <View style={[styles.profileInfo, { height: 30, marginTop: 20 }]}>
                {username ? (
                  <Text
                    allowFontScaling={false}
                    style={[styles.profileName, { textTransform: "uppercase" }]}
                  >
                    {username}
                  </Text>
                ) : null}
              </View>
              {isActive === true ? (
                <ActivityIndicator
                  position={"absolute"}
                  size={"small"}
                  style={{ top: Platform.OS === "web" ? 15 : 30 }}
                  color={COLORS.primary}
                />
              ) : (
                <ActivityIndicator
                  position={"absolute"}
                  size={"small"}
                  style={{ top: Platform.OS === "web" ? 15 : 30 }}
                  color={"#F2F2F2"}
                />
              )}
              <View style={{ padding: 20, alignItems: "center", flex: 1 }}>
                <View
                  style={[
                    styles.inputContainer,
                    {
                      marginTop: Platform.OS === "web" ? 0 : "0%",
                      borderColor: userNameError ? "red" : "black",
                    },
                  ]}
                >
                  <Text allowFontScaling={false} style={[styles.inputLabel]}>
                    {t("username")}
                  </Text>
                  <TextInput
                    // allowFontScaling={false}
                    style={{
                      marginBottom: 3,
                      fontSize: Platform.OS === "ios" ? 16.8 : 15.8,
                      ...(Platform.OS === "web" && {
                        outlineColor: "white",
                        outlineStyle: "none",
                      }),
                    }}
                    fontFamily={FONTS.PoppinsRegular}
                    placeholderTextColor={"black"}
                    onChangeText={setName}
                    value={name}
                  />
                </View>
                <View
                  style={{
                    height: 8,
                    width: "100%",
                    width: Platform.OS === "web" ? "50%" : "100%",
                    maxWidth: 436,
                    minWidth: 325,
                  }}
                >
                  {userNameError ? (
                    <Text style={styles.err} allowFontScaling={false}>
                      {userNameError}
                    </Text>
                  ) : null}
                </View>
                <View
                  style={[
                    styles.inputContainer,
                    { borderColor: emailError ? "red" : "black" },
                  ]}
                >
                  <Text allowFontScaling={false} style={styles.inputLabel}>
                    {t("email")}
                  </Text>
                  <Text
                    allowFontScaling={false}
                    style={{
                      fontSize: 16,
                      marginBottom: 6,
                      color: "black",
                      fontFamily: FONTS.PoppinsRegular,
                    }}
                  >
                    {email}
                  </Text>
                </View>
                <View
                  style={{
                    height: 8,
                    width: "100%",
                    width: Platform.OS === "web" ? "50%" : "100%",
                    maxWidth: 436,
                    minWidth: 325,
                  }}
                >
                  {emailError ? (
                    <Text allowFontScaling={false} style={styles.err}>
                      {emailError}
                    </Text>
                  ) : null}
                </View>
                {isSocialLogin && (
                  <TouchableOpacity
                    style={[
                      styles.inputContainer5,
                      {
                        marginTop: Platform.OS === "web" ? 0 : "0%",
                        borderBottomWidth:
                          passwordSection === false ? 0.5 : null,
                        borderBottomColor:
                          Platform.OS === "web"
                            ? "black"
                            : Platform.OS === "ios"
                            ? COLORS.darkbordercolor
                            : "black",
                        borderBottomWidth:
                          passwordSection === true
                            ? 0
                            : Platform.OS === "ios"
                            ? 0.5
                            : 0.5,
                      },
                    ]}
                    onPress={() => {
                      togglePasswordSection(), setLeftModal(false);
                    }}
                  >
                    <Text
                      allowFontScaling={false}
                      style={[styles.inputLabel, { width: "100%" }]}
                    >
                      {t("change-password")}
                    </Text>
                    {passwordSection === false ? (
                      <Text
                        style={{
                          width: "100%",
                          marginLeft: Platform.OS === "ios" ? 0 : 3,
                          height:
                            Platform.OS === "web"
                              ? 12
                              : Platform.OS === "android"
                              ? 19.9
                              : 11,
                          color: "black",
                          fontSize: 17,
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      ></Text>
                    ) : null}
                  </TouchableOpacity>
                )}
                {passwordSection === true ? (
                  <>
                    <View
                      style={[
                        styles.inputContainer,
                        {
                          borderColor: passwordError ? "red" : "black",
                          marginTop: -6,
                        },
                      ]}
                    >
                      <Text allowFontScaling={false} style={styles.inputLabel}>
                        {t("Old-password")}
                      </Text>
                      <View style={styles.passwordInput}>
                        <TextInput
                          secureTextEntry={opVisibility}
                          fontSize={17}
                          style={{
                            marginBottom: 3,
                            width: "90%",
                            ...(Platform.OS === "web" && {
                              outlineColor: "white",
                              outlineStyle: "none",
                            }),
                          }}
                          fontFamily={FONTS.PoppinsMedium}
                          placeholderTextColor={"black"}
                          onChangeText={setOldPassword}
                          value={oldPassword}
                        />
                        <TouchableOpacity
                          activeOpacity={0.8}
                          onPress={() => setOpPVisibility(!opVisibility)}
                        >
                          <Image
                            source={!opVisibility ? eye : passwordCloseEye}
                            style={styles.eyeIcon}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View
                      style={{
                        height: 8,
                        width: "100%",
                        width: Platform.OS === "web" ? "50%" : "100%",
                        maxWidth: 436,
                        minWidth: 325,
                      }}
                    >
                      {oldpasswordErr ? (
                        <Text style={styles.err} allowFontScaling={false}>
                          {oldpasswordErr}
                        </Text>
                      ) : null}
                    </View>
                    <View
                      style={[
                        styles.inputContainer,
                        { borderColor: passwordError ? "red" : "black" },
                      ]}
                    >
                      <Text allowFontScaling={false} style={styles.inputLabel}>
                        {t("New-password")}
                      </Text>
                      <View style={styles.passwordInput}>
                        <TextInput
                          secureTextEntry={pVisibility}
                          fontSize={17}
                          style={{
                            marginBottom: 3,
                            width: "90%",
                            ...(Platform.OS === "web" && {
                              outlineColor: "white",
                              outlineStyle: "none",
                            }),
                          }}
                          fontFamily={FONTS.PoppinsMedium}
                          placeholderTextColor={"black"}
                          onChangeText={setPassword}
                          value={password}
                        />
                        <TouchableOpacity
                          activeOpacity={0.8}
                          onPress={() => setPVisibility(!pVisibility)}
                        >
                          <Image
                            source={!pVisibility ? eye : passwordCloseEye}
                            style={styles.eyeIcon}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View
                      style={{
                        height: 8,
                        width: "100%",
                        width: Platform.OS === "web" ? "50%" : "100%",
                        maxWidth: 436,
                        minWidth: 325,
                      }}
                    >
                      {passwordError ? (
                        <Text style={styles.err} allowFontScaling={false}>
                          {passwordError}
                        </Text>
                      ) : null}
                    </View>
                    <View
                      style={[
                        styles.inputContainer,
                        { borderColor: confirmPasswordError ? "red" : "black" },
                      ]}
                    >
                      <Text allowFontScaling={false} style={styles.inputLabel}>
                        {t("Confirm Password")}
                      </Text>
                      <View style={styles.passwordInput}>
                        <TextInput
                          secureTextEntry={cPVisibility}
                          fontSize={17}
                          style={{
                            marginBottom: 3,
                            width: "90%",
                            ...(Platform.OS === "web" && {
                              outlineColor: "white",
                              outlineStyle: "none",
                            }),
                          }}
                          fontFamily={FONTS.PoppinsMedium}
                          placeholderTextColor={"black"}
                          onChangeText={setConfirmPassword}
                          value={confirmPassword}
                        />
                        <TouchableOpacity
                          activeOpacity={0.8}
                          onPress={() => setCpVisibility(!cPVisibility)}
                        >
                          <Image
                            source={!cPVisibility ? eye : passwordCloseEye}
                            style={styles.eyeIcon}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View
                      style={{
                        height: 8,
                        width: "100%",
                        width: Platform.OS === "web" ? "50%" : "100%",
                        maxWidth: 436,
                        minWidth: 325,
                      }}
                    >
                      {confirmPasswordError ? (
                        <Text allowFontScaling={false} style={styles.err}>
                          {confirmPasswordError}
                        </Text>
                      ) : null}
                    </View>
                  </>
                ) : null}
                <View style={{ height: 8, width: "100%" }}></View>
                {/* <View style={[styles.inputContainer, { borderColor: 'black', marginTop: passwordSection === true ? -8 : 0 }]}>
                  <Text allowFontScaling={false} style={styles.inputLabel}>{t('mobile-number')}</Text>
                  <TextInput
                    allowFontScaling={false}
                    fontSize={15.8}
                    style={{
                      marginBottom: 3, ...(Platform.OS === 'web' && {
                        outlineColor: 'white', outlineStyle: 'none', fontWeight: '500'
                      })
                    }}
                    fontFamily={FONTS.PoppinsRegular}
                    placeholderTextColor={'black'}
                    onChangeText={setMobileNumber}
                    value={mobileNumber}
                  />
                </View> */}
                <View
                  style={[
                    styles.inputContainer,
                    {
                      borderColor: "black",
                      marginTop: passwordSection === true ? -8 : 0,
                    },
                  ]}
                >
                  <Text
                    allowFontScaling={false}
                    style={{
                      fontWeight: "400",
                      fontSize: 14,
                      color: COLORS.primary,
                      fontFamily: FONTS.PoppinsMedium,
                      position: "absolute",
                    }}
                  >
                    {t("mobile-number")}
                  </Text>

                  {Platform.OS === "web" ? (
                    isAvailable ? (
                      <PhoneInput
                        // codeTextStyle={{

                        //   width: 0,
                        //   height: 0,
                        //   borderWidth: 1,
                        // }}
                        withShadow={false}
                        textInputStyle={{
                          outlineColor: "white",
                          outlineStyle: "none",
                          fontFamily: FONTS.PoppinsMedium,
                        }}
                        countryPickerButtonStyle={{
                          width: 50,
                          borderWidth: 0,
                          minWidth: 60,
                          outlineColor: "white",
                          outlineStyle: "none",
                          marginTop: 10,
                        }}
                        textContainerStyle={{
                          // backgroundColor: 'transparent',
                          // fontFamily: FONTS.PoppinsMedium,
                          // flex: 1,
                          // height: '100%',
                          // justifyContent: 'center',
                          // alignItems: 'center',
                          // borderWidth: 0,
                          // outlineColor: 'white',
                          // outlineStyle: 'none',
                          // width: width,
                          backgroundColor: "transparent",
                          fontFamily: FONTS.PoppinsMedium,
                          color: "black",
                        }}
                        containerStyle={{
                          width: Platform.OS === "web" ? "100%" : width - 92,
                          maxWidth: 436,
                          minWidth: 325,
                          borderWidth: 0,
                          borderRadius: 8,
                          height: 50,
                          paddingRight: 20,
                          borderColor: COLORS.primarybordercolor,
                          flexDirection: "row",
                          alignItems: "center",
                          padding: 10,
                          outlineColor: "white",
                          outlineStyle: "none",
                          backgroundColor: "transparent",
                        }}
                        ref={phoneInput}
                        placeholderTextColor={"black"}
                        defaultValue={mobileNumber}
                        defaultCode={countryCode}
                        layout="first"
                        onChangeCountry={(country) => {
                          setCountryCode(country?.cca2);
                          // console.log("Selected country code:", country.cca2);
                        }}
                        onChangeText={(text) => {
                          console.log("ssss", text);
                          setMobileNumber(text);
                        }}
                        onChangeFormattedText={(text) => {}}
                        withDarkTheme
                      />
                    ) : null
                  ) : isAvailable ? (
                    <PhoneInput
                      filterProps={{
                        allowFontScaling: false,
                      }}
                      countryPickerProps={{
                        allowFontScaling: false,
                      }}
                      textInputProps={{
                        allowFontScaling: false,
                        returnKeyType: "done",
                      }}
                      textInputStyle={{
                        fontSize: 16,
                        height: 30,
                        color: "black",
                      }}
                      textContainerStyle={{
                        backgroundColor: "transparent",
                        fontFamily: FONTS.PoppinsMedium,
                        color: "black",
                      }}
                      codeTextStyle={{
                        fontSize: 15,
                        marginLeft: -20,
                        lineHeight: 24,
                        height: 25,
                        // width: 40,
                        width: 0,
                      }}
                      containerStyle={{
                        alignSelf: "center",
                        backgroundColor: "transparent",
                        height: 30,
                        width: Platform.OS === "web" ? "50%" : "100%",
                        maxWidth: 436,
                        minWidth: 325,
                        borderWidth: 0,
                        marginTop: 14,
                      }}
                      ref={phoneInput}
                      placeholderTextColor={"black"}
                      defaultValue={mobileNumber}
                      defaultCode={
                        countryCode && countryCode.length === 2
                          ? countryCode
                          : "US"
                      }
                      onChangeCountry={(country) => {
                        setCountryCode(country?.cca2);
                        // console.log("Selected country code:", country.cca2);
                      }}
                      onChangeText={(text) => {
                        // console.log("ssss", text);
                        setMobileNumber(text);
                      }}
                      onChangeFormattedText={(text) => {}}
                      withDarkTheme={false}
                      withShadow={false}
                      autoFocus={false}
                    />
                  ) : null}
                </View>
                <View
                  style={{
                    height: 8,
                    width: "100%",
                    width: Platform.OS === "web" ? "50%" : "100%",
                    maxWidth: 436,
                    minWidth: 325,
                  }}
                >
                  {phoneError ? (
                    <Text allowFontScaling={false} style={styles.err}>
                      {phoneError}
                    </Text>
                  ) : null}
                </View>

                <TouchableOpacity
                  onPress={() => {
                    if (!isActive) {
                      handleSubmit();
                    }
                  }}
                  activeOpacity={0.8}
                  style={{
                    width: 170,
                    height: 50,
                    backgroundColor: COLORS.primary,
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: Platform.OS === "web" ? 5 : "3%",
                    marginBottom: 40,
                  }}
                >
                  <Text
                    // allowFontScaling={false}
                    style={{
                      fontFamily: FONTS.PoppinsMedium,
                      fontSize: 14,
                      fontWeight: "500",
                      color: COLORS.white,
                    }}
                  >
                    {t("Save")}
                  </Text>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  height: passwordSection === true ? 0 : 200,
                  width: "100%",
                }}
              ></View>
            </View>
          </KeyboardAvoidingView>
        </Pressable>
      </ScrollView>
      {isVisible3 && (
        <>
          <BlurView
            style={styles.blurBackground}
            blurType="light"
            intensity={20}
            reducedTransparencyFallbackColor="white"
          />
          <Animated.View style={[styles.popup, { opacity: fadeAnimm }]}>
            <View>
              <View
                style={{
                  height: 50,
                  backgroundColor: "white",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingRight: 15,
                  paddingLeft: 15,
                  borderTopRightRadius: 12,
                  justifyContent: "center",
                  borderTopLeftRadius: 12,
                }}
              >
                <Text
                  // allowFontScaling={false}
                  style={{
                    color: COLORS.primary,
                    fontSize: 20,
                    textAlign: "center",
                    lineHeight: 30,
                    alignSelf: "center",
                    marginTop: 5,
                    fontFamily: "Poppins-SemiBold",
                    fontWeight: Platform.OS === "web" ? 0 : "600",
                  }}
                >
                  {t("Language")}
                </Text>
                <TouchableOpacity
                  activeOpacity={0.7}
                  onPress={hidePopup3}
                  style={{
                    height: 35,
                    width: 35,
                    position: "absolute",
                    right: -10,
                    bottom: 30,
                  }}
                >
                  <Image
                    source={crossCircle}
                    style={{ height: 35, width: 35, resizeMode: "contain" }}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <FlatList
              showsVerticalScrollIndicator={false}
              style={{ paddingRight: 10, paddingLeft: 10 }}
              data={Object.keys(languageResources)}
              renderItem={({ item }) => (
                <Pressable
                  style={{
                    borderBottomWidth: 0.5,
                    borderColor: "#6F74793D",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: 20,
                    paddingVertical: 6.7,
                    justifyContent: "space-between",
                  }}
                  onPress={() => {
                    setSelectedItem(item),
                      togglePasswordSection2(),
                      changeLng(item),
                      hidePopup3();
                  }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Image
                      resizeMode="contain"
                      source={
                        item === "en"
                          ? images.flags.england
                          : item === "ko"
                          ? images.flags.korea
                          : item === "ch"
                          ? images.flags.china
                          : item === "fr"
                          ? images.flags.france
                          : item === "sp"
                          ? images.flags.spain
                          : null
                      }
                      style={{ height: 30, width: 30, marginRight: 15 }}
                    />
                    <Text
                      // allowFontScaling={false}
                      style={{
                        fontSize: 19,
                        fontFamily: FONTS.PoppinsSemiBold,
                        color: selectedItem === item ? COLORS.primary : "black",
                      }}
                    >
                      {item === "en"
                        ? "English"
                        : item === "ko"
                        ? "Korean"
                        : item === "ch"
                        ? "Chinese"
                        : item === "fr"
                        ? "French"
                        : item === "sp"
                        ? "Spanish"
                        : null}
                    </Text>
                  </View>
                  <Image
                    source={selectedItem === item ? FillDot : UnfillDot}
                    style={{
                      height: 14,
                      width: 14,
                      resizeMode: "contain",
                      marginRight: 15,
                    }}
                  />
                </Pressable>
              )}
            />
          </Animated.View>
        </>
      )}
      {isVisible2 && (
        <>
          <BlurView
            style={styles.blurBackground}
            tint={"default"}
            intensity={20}
            reducedTransparencyFallbackColor="white"
          />
          <Animated.View style={[styles.popup22, { opacity: fadeAnim }]}>
            <View>
              <View
                style={{
                  height: 50,
                  backgroundColor: "white",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingRight: 15,
                  paddingLeft: 15,
                  borderTopRightRadius: 12,
                  justifyContent: "center",
                  borderTopLeftRadius: 12,
                }}
              >
                <Text
                  allowFontScaling={false}
                  style={{
                    color: COLORS.primary,
                    fontSize: 20,
                    textAlign: "center",
                    lineHeight: 30,
                    alignSelf: "center",
                    marginTop: 20,
                    fontFamily: "Poppins-SemiBold",
                    fontWeight: Platform.OS === "web" ? 0 : "600",
                  }}
                >
                  {t("Log Out")}
                </Text>
              </View>
            </View>
            <Text
              style={{
                textAlign: "center",
                lineHeight: 24,
                fontSize: 14,
                fontWeight: "500",
                color: "#6F7479",
                marginTop: 15,
                fontFamily: FONTS.PoppinsMedium,
              }}
            >
              {t("Are You Sure to Log Out")}
            </Text>
            <Text
              style={{
                textAlign: "center",
                lineHeight: 24,
                fontSize: 14,
                fontWeight: "500",
                color: "#6F7479",
                fontFamily: FONTS.PoppinsMedium,
              }}
            >
              {t("the Account")}
            </Text>
            {logoutIndicator === true ? (
              <ActivityIndicator size={"small"} color={COLORS.primary} />
            ) : (
              <ActivityIndicator size={"small"} color={COLORS.white} />
            )}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: 6,
                paddingRight: 8,
                paddingLeft: 8,
              }}
            >
              <Pressable
                onPress={() => {
                  setCurrentLogoutId(1), hidePopup2();
                }}
                style={{
                  width: 118,
                  height: 41,
                  borderWidth: 1,
                  borderRadius: 30,
                  borderColor: COLORS.primary,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    currentLogoutId === 1 ? COLORS.primary : "white",
                  marginRight: 5,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    lineHeight: 21,
                    fontSize: 14,
                    fontWeight: "500",
                    color: "#6F7479",
                    fontFamily: FONTS.PoppinsMedium,
                    color: currentLogoutId === 1 ? "white" : COLORS.primary,
                  }}
                >
                  {t("Cancel")}
                </Text>
              </Pressable>
              <Pressable
                onPress={() => {
                  setCurrentLogoutId(2), handlelogout();
                }}
                style={{
                  marginLeft: 5,
                  width: 118,
                  height: 41,
                  borderWidth: 1,
                  borderRadius: 30,
                  borderColor: COLORS.primary,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    currentLogoutId === 2 ? COLORS.primary : "white",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    lineHeight: 21,
                    fontSize: 14,
                    fontWeight: "500",
                    color: "#6F7479",
                    fontFamily: FONTS.PoppinsMedium,
                    color: currentLogoutId === 2 ? "white" : COLORS.primary,
                  }}
                >
                  {t("Log Out")}
                </Text>
              </Pressable>
            </View>
          </Animated.View>
        </>
      )}
      {leftModal === true ? (
        <View style={styles.leftmodal}>
          {[
            { key: 1, icon: images.icons?.faq, label: "Subscription" },
            { key: 2, icon: images.icons.language, label: "Language" },
            { key: 3, icon: LogOut, label: "Log Out" },
            // { key: 4, icon: Request, label: "Product Request" },
            { key: 5, icon: ActiveAccount, label: "Manage Device" },
          ].map((item) => (
            <TouchableOpacity
              key={item.key}
              onPress={() => handleNavigation(item.key)}
              activeOpacity={0.7}
              style={{
                flexDirection: "row",
                alignItems: "center",
                borderBottomWidth: 0.6,
                flex: 1,
                borderColor: "#6F74793D",
                marginLeft: 13,
                minHeight: 37,
                marginRight: 15,
              }}
            >
              <Image
                source={item.icon}
                style={{
                  width: 15,
                  height: 15,
                  resizeMode: "contain",
                  // marginTop: 5,
                }}
              />
              <Text
                // allowFontScaling={false}
                style={{
                  fontFamily: FONTS.PoppinsRegular,
                  marginLeft: 10,
                  color: COLORS.primarygraycolor,
                  marginTop: 5,
                }}
              >
                {t(item.label)}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      ) : // <View style={styles.leftmodal}>
      //   <TouchableOpacity
      //     onPress={() => handleNavigation(1)}
      //     activeOpacity={0.7}
      //     style={{
      //       flexDirection: "row",
      //       alignItems: "center",
      //       borderBottomWidth: 0.6,
      //       flex: 1,
      //       borderColor: "#6F74793D",
      //       marginLeft: 22,
      //     }}
      //   >
      //     <Image
      //       source={images.icons?.faq}
      //       style={{
      //         width: 15,
      //         height: 15,
      //         resizeMode: "contain",
      //         marginTop: 10,
      //       }}
      //     />
      //     <Text
      //       // allowFontScaling={false}
      //       style={{
      //         fontFamily: FONTS.PoppinsRegular,
      //         marginLeft: 10,
      //         color: COLORS.primarygraycolor,
      //         marginTop: 10,
      //       }}
      //     >
      //       {t("Subscription")}
      //     </Text>
      //   </TouchableOpacity>

      //   <TouchableOpacity
      //     onPress={() => handleNavigation(2)}
      //     activeOpacity={0.7}
      //     style={{
      //       flexDirection: "row",
      //       alignItems: "center",
      //       borderBottomWidth: 0.6,
      //       flex: 1,
      //       borderColor: "#6F74793D",
      //       marginLeft: 22,
      //       marginTop: -5,
      //     }}
      //   >
      //     <Image
      //       source={images.icons.language}
      //       style={{
      //         width: 15,
      //         height: 15,
      //         resizeMode: "contain",
      //         marginTop: 5,
      //       }}
      //     />
      //     <Text
      //       // allowFontScaling={false}
      //       style={{
      //         fontFamily: FONTS.PoppinsRegular,
      //         marginLeft: 10,
      //         color: COLORS.primarygraycolor,
      //         marginTop: 5,
      //       }}
      //     >
      //       {t("Language")}
      //     </Text>
      //   </TouchableOpacity>
      //   <TouchableOpacity
      //     onPress={() => handleNavigation(3)}
      //     activeOpacity={0.7}
      //     style={{
      //       flexDirection: "row",
      //       alignItems: "center",
      //       borderBottomWidth: 0.6,
      //       flex: 1,
      //       borderColor: "#6F74793D",
      //       marginLeft: 22,
      //       marginTop: -5,
      //     }}
      //   >
      //     <Image
      //       source={LogOut}
      //       style={{
      //         width: 15,
      //         height: 15,
      //         resizeMode: "contain",
      //         marginTop: 5,
      //       }}
      //     />
      //     <Text
      //       // allowFontScaling={false}
      //       style={{
      //         fontFamily: FONTS.PoppinsRegular,
      //         marginLeft: 10,
      //         color: COLORS.primarygraycolor,
      //         marginTop: 5,
      //       }}
      //     >
      //       {t("Log Out")}
      //     </Text>
      //   </TouchableOpacity>
      //   <TouchableOpacity
      //     onPress={() => handleNavigation(4)}
      //     activeOpacity={0.7}
      //     style={{
      //       flexDirection: "row",
      //       alignItems: "center",
      //       borderBottomWidth: 0.6,
      //       flex: 1,
      //       borderColor: "#6F74793D",
      //       marginLeft: 22,
      //       marginTop: -5,
      //       padding: 5,
      //     }}
      //   >
      //     <Image
      //       source={Request}
      //       style={{
      //         width: 15,
      //         height: 15,
      //         resizeMode: "contain",
      //         marginTop: 0,
      //       }}
      //     />
      //     <Text
      //       // allowFontScaling={false}
      //       style={{
      //         fontFamily: FONTS.PoppinsRegular,
      //         marginLeft: 10,
      //         color: COLORS.primarygraycolor,
      //         marginTop: 5,
      //       }}
      //     >
      //       {t("Product Request")}
      //     </Text>
      //   </TouchableOpacity>
      //   {/* ))} */}
      //   <TouchableOpacity
      //     onPress={() => handleNavigation(5)}
      //     activeOpacity={0.7}
      //     style={{
      //       flexDirection: "row",
      //       alignItems: "center",
      //       borderBottomWidth: 0.6,
      //       flex: 1,
      //       borderColor: "#6F74793D",
      //       marginLeft: 22,
      //       marginTop: -5,
      //     }}
      //   >
      //     <Image
      //       source={ActiveAccount}
      //       style={{
      //         width: 15,
      //         height: 15,
      //         resizeMode: "contain",
      //         marginTop: 0,
      //       }}
      //     />
      //     <Text
      //       // allowFontScaling={false}
      //       style={{
      //         fontFamily: FONTS.PoppinsRegular,
      //         marginLeft: 10,
      //         color: COLORS.primarygraycolor,
      //         marginTop: 5,
      //       }}
      //     >
      //       {t("Manage Device")}
      //     </Text>
      //   </TouchableOpacity>
      // </View>
      null}
      {isVisible === true ? (
        <BlurView
          // experimentalBlurMethod='dimezisBlurView'
          tint={"dark"}
          intensity={isVisible === true ? 20 : 0}
          style={styles.blackBlurContainer}
        ></BlurView>
      ) : null}
      <Animated.View
        {...panResponder.panHandlers}
        style={[styles.bottomSheet, { transform: [{ translateY }] }]}
      >
        {currentValue == 1 ? (
          <>
            <View
              style={{
                height: 50,
                backgroundColor: "white",
                flexDirection: "row",
                alignItems: "center",
                paddingRight: 15,
                paddingLeft: 15,
                borderTopRightRadius: 12,
                justifyContent: "center",
                borderTopLeftRadius: 12,
                marginTop: 5,
              }}
            >
              <Text
                allowFontScaling={false}
                style={{
                  color: COLORS.primary,
                  fontSize: 24,
                  textAlign: "center",
                  lineHeight: 36,
                  alignSelf: "center",
                  marginTop: 5,
                  fontFamily: "Poppins-SemiBold",
                  fontWeight: Platform.OS === "web" ? 0 : "600",
                }}
              >
                {t("Request")}
              </Text>
              <TouchableOpacity
                activeOpacity={0.7}
                onPress={closeBottomSheet}
                style={{
                  height: 35,
                  width: 35,
                  position: "absolute",
                  right: 9,
                  bottom: 12,
                }}
              >
                <Image
                  source={crossCircle}
                  style={{ height: 35, width: 35, resizeMode: "contain" }}
                />
              </TouchableOpacity>
            </View>

            <KeyboardAvoidingView
              behavior={Platform.OS === "ios" ? "padding" : "height"}
              keyboardVerticalOffset={Platform.OS === "ios" ? 20 : 0}
            >
              <TextInput
                // allowFontScaling={false}
                keyboardType="ascii-capable"
                style={[
                  styles.inputView,
                  { fontFamily: FONTS.PoppinsRegular, fontWeight: "400" },
                ]}
                placeholder={t("Manufacturer")}
                placeholderTextColor={COLORS.primarygraycolor}
                color={COLORS.primarygraycolor}
              />
              <TextInput
                // allowFontScaling={false}
                autoCapitalize="none"
                keyboardType="ascii-capable"
                style={[
                  styles.inputproductname,
                  { fontFamily: FONTS.PoppinsRegular, fontWeight: "400" },
                ]}
                placeholder={t("Product Name")}
                placeholderTextColor={COLORS.primarygraycolor}
                color={COLORS.primarygraycolor}
              />
              <View
                style={{
                  borderWidth: 2,
                  borderRadius: 5,
                  width: "100%",
                  height: 250,
                  marginTop: 20,
                  width: Platform.OS === "web" ? "50%" : "64%",
                  maxWidth: 436,
                  minWidth: 325,
                  borderWidth: 1,
                  borderRadius: 8,
                  paddingRight: Platform.OS === "web" ? 0 : 20,
                  borderColor: COLORS.primarybordercolor,
                  alignSelf: "center",
                  marginTop: 20,
                }}
              >
                <TextInput
                  style={[
                    styles.textInput,
                    { height: Platform.OS === "web" ? 250 : null },
                  ]}
                  multiline={true}
                  placeholder={t("Description")}
                  keyboardType="ascii-capable"
                  color={COLORS.primarygraycolor}
                  placeholderTextColor={COLORS.primarygraycolor}
                  returnKeyType="done"
                  blurOnSubmit={true}
                  onSubmitEditing={() => Keyboard.dismiss()}
                />
              </View>
            </KeyboardAvoidingView>
            <TouchableOpacity
              onPress={() => {
                notifyMessage("Request Submitted"), closeBottomSheet();
              }}
              activeOpacity={0.6}
              style={styles.submitBtn}
            >
              <Text
                // allowFontScaling={false}
                style={{
                  fontWeight: "500",
                  fontFamily: FONTS.PoppinsSemiBold,
                  fontSize: 14,
                  textAlign: "center",
                  lineHeight: 21,
                  color: "white",
                }}
              >
                {t("Submit")}
              </Text>
            </TouchableOpacity>
          </>
        ) : currentValue == 2 ? (
          <>
            <View
              style={{
                height: 50,
                backgroundColor: "white",
                flexDirection: "row",
                alignItems: "center",
                paddingRight: 15,
                paddingLeft: 15,
                borderTopRightRadius: 12,
                justifyContent: "center",
                borderTopLeftRadius: 12,
                marginTop: 5,
                marginBottom: 10,
              }}
            >
              <Text
                allowFontScaling={false}
                style={{
                  color: COLORS.primary,
                  fontSize: 24,
                  textAlign: "center",
                  lineHeight: 36,
                  alignSelf: "center",
                  marginTop: 5,
                  fontFamily: "Poppins-SemiBold",
                  fontWeight: Platform.OS === "web" ? 0 : "600",
                }}
              >
                {t("Manage Device")}
              </Text>
              <TouchableOpacity
                activeOpacity={0.7}
                onPress={closeBottomSheet}
                style={{
                  height: 35,
                  width: 35,
                  position: "absolute",
                  right: 9,
                  bottom: 12,
                }}
              >
                <Image
                  source={crossCircle}
                  style={{ height: 35, width: 35, resizeMode: "contain" }}
                />
              </TouchableOpacity>
            </View>

            <FlatList
              data={activeAccountList}
              renderItem={renderActiveAccList}
            />
            {visible && (
              <Animated.View style={[styles.popup2, { opacity: fadeAnim }]}>
                <Image
                  source={LogoutQuestionIcon}
                  style={{
                    height: 50,
                    width: 50,
                    resizeMode: "contain",
                    marginVertical: 6,
                  }}
                />

                <Text style={styles.popupText2}>
                  {t("Are you sure want to logout ?")}
                </Text>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    borderWidth: 0,
                    height: 50,
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 0,
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: COLORS.primary,
                      borderRadius: 10,
                      width: "47%",
                    }}
                    onPress={() => {
                      hidePopup(), setCurrentData(null);
                    }}
                  >
                    <Text style={styles.closeButton2}>{t("Close")}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      borderWidth: 0,
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: COLORS.primary,
                      borderRadius: 10,
                      width: "47%",
                    }}
                    onPress={logoutActiveAcc}
                  >
                    <Text style={styles.closeButton2}>{t("Log Out")}</Text>
                  </TouchableOpacity>
                </View>
              </Animated.View>
            )}
          </>
        ) : null}
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    width: "100%",
    height: Platform.OS === "web" ? 175 : 200,
    backgroundColor: COLORS.primary,
    paddingLeft: 15,
    paddingRight: 15,
  },
  cameraIcon: {
    height: 35,
    width: 35,
    position: "absolute",
    right: 15,
    bottom: 15,

    backgroundColor: COLORS.primary,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
  profileImageContainer: {
    height: 100,
    width: 100,
    borderWidth: 2,
    borderRadius: 100,
    alignSelf: "center",
    marginTop: -50,
    borderColor: COLORS.white,
    backgroundColor: "white",
  },
  profileImage: {
    height: "100%",
    width: "100%",
    borderRadius: 100,
  },
  imageSelector: {
    width: 24,
    height: 24,
    borderWidth: 2,
    borderColor: COLORS.white,
    backgroundColor: COLORS.primary,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: -10,
    bottom: 13,
  },
  selectorIcon: {
    height: 9,
    width: 9,
  },
  profileInfo: {
    marginTop: 40,
  },
  profileName: {
    textAlign: "center",
    fontSize: 20,
    lineHeight: 24,
    color: "black",
    fontFamily: "Poppins-SemiBold",
    fontWeight: Platform.OS === "web" ? 0 : "600",
  },
  profileLocation: {
    fontSize: 16,
    fontWeight: "500",
    lineHeight: 24,
    textAlign: "center",
    color: "#F2F2F2",
    fontFamily: FONTS.PoppinsMedium,
  },
  inputContainer: {
    borderBottomWidth: 0.5,
    borderBottomColor: COLORS.darkbordercolor,

    width: Platform.OS === "web" ? "50%" : "100%",
    maxWidth: 436,

    minWidth: 325,
    minWidth: 325,
    height: 50,
  },
  inputContainer5: {
    width: Platform.OS === "web" ? "50%" : "100%",
    maxWidth: 436,

    minWidth: 325,
    minWidth: 325,
    height: 50,
  },
  inputContainer2: {
    borderBottomWidth: 0.5,
    borderBottomColor: COLORS.darkbordercolor,
    marginVertical: 5,
    width: Platform.OS === "web" ? "30%" : "100%",
  },
  inputLabel: {
    fontWeight: "400",
    fontSize: 14,
    color: COLORS.primary,
    fontFamily: FONTS.PoppinsMedium,
    height: 19.9,
  },
  textInput: {
    width: "100%",
    marginBottom: 5,
    paddingVertical: Platform.OS === "web" ? 0 : 0,
    color: "black",
  },
  passwordInput: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  eyeIcon: {
    height: 20,
    width: 20,
  },
  err: {
    lineHeight: 11,
    fontSize: 9,
    width: Platform.OS === "web" ? "50%" : "100%",
    maxWidth: 436,
    minWidth: 325,
    color: "red",
    fontFamily: FONTS.PoppinsMedium,
  },
  blurContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  popupContainer: {
    backgroundColor: "white",
    padding: 0,
    borderRadius: 12,
    elevation: 5,
    width: "70%",
    height: 312,
  },
  bottomSheet: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: Platform.OS === "ios" ? 630 : Platform.OS === "web" ? 600 : 630,
    backgroundColor: "white",
    // padding: 16,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    elevation: 5,
  },
  inputView: {
    width: Platform.OS === "web" ? "50%" : width - 92,
    marginTop: 25,
    maxWidth: 436,
    minWidth: 325,
    borderWidth: 1,
    borderRadius: 8,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    borderColor: COLORS.primarybordercolor,
    alignSelf: "center",
  },
  inputproductname: {
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderWidth: 1,
    borderRadius: 8,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    borderColor: COLORS.primarybordercolor,
    fontFamily: FONTS.PoppinsMedium,
    alignSelf: "center",
    marginTop: 20,
  },
  blackBlurContainer: {
    flex: 1,
    height: height,
    position: "absolute",
    width: width,
    justifyContent: "center",
  },
  popup: {
    position: "absolute",
    backgroundColor: "white",
    padding: 0,
    borderRadius: 12,
    elevation: 5,
    alignSelf: "center",
    height: 312,
    width: Platform.OS === "web" ? "20%" : "70%",
    maxWidth: 400,
    minWidth: Platform.OS === "web" ? 300 : undefined,
    borderWidth: 0,
    top: Platform.OS === "web" ? 150 : 300,
  },
  popup22: {
    position: "absolute",
    backgroundColor: "white",
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 35,
    borderRadius: 10,
    elevation: 5,
    alignSelf: "center",
    width: Platform.OS === "web" ? "20%" : "80%",
    maxWidth: 436,
    minWidth: Platform.OS === "web" ? 325 : undefined,
    top: Platform.OS === "web" ? 150 : 300,
  },
  blurBackground: {
    ...StyleSheet.absoluteFillObject,
  },
  submitBtn: {
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderRadius: 30,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    alignSelf: "center",
    marginTop: 20,
    backgroundColor: COLORS.primary,
    alignItems: "center",
    justifyContent: "center",
  },
  textInput: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingRight: 20,
    color: COLORS.primarygraycolor,
    fontFamily: FONTS.PoppinsRegular,
    fontWeight: "400",
  },
  leftmodal: {
    // height: 170,
    // width: 183,
    backgroundColor: "white",
    alignSelf: "flex-end",
    marginRight: 25,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    position: "absolute",
    top: 72,
    right: Platform.OS === "web" ? 0 : 0,
    shadowColor: "black",
    borderBottomRightRadius: 20,
    shadowOffset: {
      width: 0,
      height: 14,
    },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    elevation: Platform.OS === "web" ? 0.1 : 5,
    paddingRight: 0,
  },
  popup2: {
    position: "absolute",
    top: "20%",
    // width: '80%',
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 290,
    minWidth: 280,
    padding: 10,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    alignItems: "center",
    alignSelf: "center",
  },
  popupText2: {
    fontSize: 20,
    marginBottom: 10,
    fontFamily: FONTS.PoppinsMedium,
    color: COLORS.primary,
    textAlign: "center",
  },
  closeButton2: {
    fontSize: Platform.OS == "web" ? 18 : 15,
    color: COLORS.primary,
    color: "white",
  },
});

export default Account;
