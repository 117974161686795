import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Pressable,
  Image,
  Platform,
  StatusBar,
  FlatList,
  ActivityIndicator,
  LayoutAnimation,
  TouchableOpacity,
  TextInput,
  PanResponder,
  KeyboardAvoidingView, Animated, Alert, Linking,
  Keyboard,
  ScrollView,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useTranslation } from 'react-i18next';
import { API, COLORS, FONTS } from '../../../utils';
import { OrientationContext } from '../../components/OrientationContext'
import { BlurView } from 'expo-blur';
import { Toast } from "react-native-toast-notifications";
import FAQs from '../../../locales/faqs.json'
const { width, height } = Dimensions.get("window");
interface FaqItem {
  id: string;
  question: string;
  answer: string;
}

const Faq = ({ navigation }: { navigation: any }) => {

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      closeBottomSheet()
    });
    return unsubscribe;
  }, [navigation]);


  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  // console.log(">>>> language transation >>>", currentLanguage)
  const crossCircle = require("../../assets/images/crosscircle.png");

  const isLandscape = useContext(OrientationContext);

  const { t } = useTranslation();
  // const [faqData, setFaqData] = useState<FaqItem[]>([]);
  const [faqData, setFaqData] = useState([
    {
      id: 1,
      question: t("What is the Comparative Guide?"),
      answer: t("The <em>NutriSearch Comparative Guide to Nutritional Supplements™</em> is a publication, now in its sixth edition, which summarizes recent findings in nutritional sciences and provides comparisons of over 1,600 broad-spectrum multiple vitamin and mineral supplements."),
      answer2: t(""),
      answer3: t(""),
      answer4: t(""),

    },
    {
      id: 2,
      question: t("What is the Blended Standard?"),
      answer: t("The Blended Standard is the benchmark created by NutriSearch for individual nutrient amounts since there is no other available standard for optimal nutrient amounts. Most authorities use DRI (Dietary Reference Intake) amounts or their predecessor RDA (Recommended Daily Allowances). Neither of these values provide optimal intake; rather, they are based on the minimum values to sustain life. The RDAs were originally developed to keep soldiers alive during World War II."),
      answer2: t("We created the Blended Standard by combining the published recommendations for optimal intake from twelve separate recognized authorities in the nutrition field. Full details are available in \"Chapter 8: Comparing Supplements\" of the sixth edition guide."),
      answer3: t(""),
      answer4: t(""),
    },

    {
      id: 3,
      question: t("What are the Health Support Criteria (HSP)?"),
      answer: t("Over the more than two decades since production began on the guide, NutriSearch developed several criteria based on important health concerns. Each of those criteria examine a different nutrition-related concern, such as Heart Health, Immune Support, and so on. The sixth edition now includes two HSP that focus on recent revelations in nutrition science surrounding gene expression, Healthy Aging Support and LifeSpan Essentials. Other criteria have been combined for a total of sixteen HSP."),
      answer2: t(""),
      answer3: t(""),
      answer4: t(""),
    },
    {
      id: 4,
      question: t("What is the NutriSearch Medals of Achievement Program NMAP?"),
      answer: t("It's not enough for a product to include a wide variety of nutrients; equally important, perhaps even moreso, is the quality of manufacturing. NutriSearch developed NMAP so that manufacturers of products reaching a high Star Rating (four stars or more) against the Blended Standard and Health Support Criteria have an opportunity to demonstrate superior quality. NMAP requires third-party certification of Good Manufacturing Practices, laboratory analysis of ingredients, and third party testing for purity to award Bronze, Silver, Gold, Diamond, and Platinum medals. Full details can be found in Chapter 9: Product Rating Criteria in the sixth edition guide."),
      answer2: t(""),
      answer3: t(""),
      answer4: t(""),
    },
    {
      id: 5,
      question: t("Where does NutriSearch get its product information?"),
      answer: t("We have strict guidelines to ensure our product information is accurate and reliable. In our first decade, our primary source of information was photographs of product bottles on the shelf at supermarkets, large chain stores, pharmacies, and other retail outlets."),
      answer2: t("With the growth of the Internet, it's now possible for us to do much of our product research online. We still rely primarily on product labels for our information, and most manufacturers provide them on their corporate website. Where the label itself is not available, we will accept a product monograph or fact sheet only if it is available directly from the manufacturer, such as on its official web site."),
      answer3: t("In rare instances, when no other source of information is available, we have included product information from a third-party source (such as amazon.com) if photographs of the actual product label are available."),
      answer4: t("In earlier editions, we also accepted product information from Health Canada's Licensed Natural Health Products Database. We now try to avoid this option, however, as the site does not always include complete information."),
    },
    {
      id: 6,
      question: t("Why is product <em>X</em> not included in the database?"),
      answer: t("There are several reasons a product may not be in our database.The simplest is that we just haven't come across it in our research. We are constantly searching pharmacies and other retail outlets for new products, along with web searches, and we also rely on users of our products to let us know if we've missed something. That's why you'll find in this app a form to alert us of anything we've overlooked."),
      answer2: t("A product may not meet our standards for inclusion. A qualifying product must be a multivitamin and mineral product intended for everyday intake by adults. We don't accept products intended for therapeutic uses, such as prenatal supplements or those intended to support a weakened immune system."),
      answer3: t("Another common reason a product may not be in our database is that it is a food supplement rather than a multiple vitamin and mineral supplement. Many products marketed as healthy supplements for everyday use do not list individual nutrients in their formulations. While these products may well be very good for you, without detailed information on the nutrients contained in the product there is simply no way for NutriSearch to compare the product."),
      answer4: t(""),
    },
    {
      id: 7,
      question: t("Examples of food products include:"),
      answer: t("Juice Plus and similar brands, which provide \"plant-based nutrients\" but do not specify any amounts. While oranges provide many nutrients, there is no reliable way to determine how much vitamin A or C or calcium is in a particular fruit."),
      answer2: t("Algae supplements, which contain a wide variety of nutrients but cannot provide specific amounts. Each batch is likely to be different."),
      answer3: t("Many brands of Green Food or Red Food supplements, which include many vegetable or fruit powders but no detailed nutrient information."),
      answer4: t(""),
    },

  ]);


  const [isActive, setIsActive] = useState(false);
  const [showText, setShowText] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const translateY = useRef(new Animated.Value(800)).current;
  const [manufacturer, setManufacture] = useState('')
  const [productName, setProductName] = useState('')
  const [description, setDescription] = useState('')
  const [urlLink, setUrlLink] = useState('')

  useEffect(() => {
    const getData = async () => {
      try {
        const value = await AsyncStorage.getItem('key');
        const id = await AsyncStorage.getItem('id');
        if (value && id) {
          fetchFaqData(value, id);
        }
      } catch (e) {
        console.error(e);
      }
    };
    getData();
  }, []);

  const fetchFaqData = async (sessionKey: string, id: string) => {
    setIsActive(true);
    try {
      const response = await fetch(`${API.baseurl}${API.faq}?id=${id}&session_key=${sessionKey}`, {
        method: 'GET',
        headers: {
          'id': id,
          'session_key': sessionKey,
          'Authorization': 'Basic YWRtaW46MTIzNA==',
        },
      });
      const result = await response.json();
      setFaqData(result);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsActive(false);
    }
  };
  const handleToggle = (id: string) => {
    // Custom LayoutAnimation configuration
    const customAnimation = {
      duration: 300, // Adjust the duration here (in milliseconds)
      update: {
        type: LayoutAnimation.Types.easeInEaseOut,
        property: LayoutAnimation.Properties.opacity,
      },
      create: {
        type: LayoutAnimation.Types.easeInEaseOut,
        property: LayoutAnimation.Properties.opacity,
      },
      delete: {
        type: LayoutAnimation.Types.easeInEaseOut,
        property: LayoutAnimation.Properties.opacity,
      },
    };

    LayoutAnimation.configureNext(customAnimation);
    setShowText((prev) => (prev === id ? null : id));
  };
  const renderFaqItem = ({ item }: { item: FaqItem }) => {
    // console.log(">>>>>. item >>>>", item)
    const isExpanded = showText === item?.id;
    let question = item?.question
    return (
      <View style={styles.faqItemContainer}>
        <View style={styles.faqItem}>
          <Pressable onPress={() => handleToggle(item?.id)} style={styles.questionContainer}>
            <Image
              source={isExpanded ? require('../../assets/images/minusL.png') : require('../../assets/images/plusblueicon.png')}
              style={[styles.icon]}
            />
            {/* <Text style={styles.questionText} >{item?.question?.en}</Text> */}
            <Text style={styles.questionText} >     {currentLanguage == "en" ? item?.question.en
              : currentLanguage == "ko" ? item?.question.ko
                : currentLanguage == "fr" ? item?.question.fr
                  : currentLanguage == "ch" ? item?.question.zh
                    : currentLanguage == "sp" ? item?.question.es : null}</Text>
            {/* <Text style={styles.questionText} >{item.question.replace(/[^\w\s-]|_/g, '')}</Text> */}
          </Pressable>
          {isExpanded && <>
            {/* <Text style={styles.answerText}>{item?.answer}</Text>
            <Text style={styles.answerText}>{item?.answer2}</Text>
            <Text style={styles.answerText}>{item?.answer3}</Text>
            <Text style={styles.answerText}>{item?.answer4}</Text> */}
            {/* {item.answer.en.split('\n').map((paragraph, pIndex) => ( */}
            {/* <Text style={styles.answerText}>
              {item.answer.en.split('\n')}
            </Text> */}
            <Text style={styles.answerText}>
              {(() => {
                const text =
                  currentLanguage === "en" ? item?.answer.en :
                    currentLanguage === "ko" ? item?.answer.ko :
                      currentLanguage === "fr" ? item?.answer.fr :
                        currentLanguage === "ch" ? item?.answer.zh :
                          currentLanguage === "sp" ? item?.answer.es :
                            null;

                if (!text) return null;

                return text.split('\n').map((line, index) => (
                  <Text key={index}>
                    {line}
                    {'\n'} {/* Ensures a new line between lines */}
                  </Text>
                ));
              })()}
            </Text>

          </>
          }
        </View>
      </View>
    );
  };

  // const renderFaqItem = ({ item }: { item: FaqItem }) => {
  //   const isExpanded = showText === item.id;
  //   return (
  //     <View style={styles.faqItemContainer}>
  //       <View style={styles.faqItem}>
  //         <Pressable onPress={() => handleToggle(item.id)} style={styles.questionContainer}>
  //           <Image
  //             source={isExpanded ? require('../../assets/images/minusL.png') : require('../../assets/images/plusblueicon.png')}
  //             style={[styles.icon]}
  //           />
  //           <Text style={styles.questionText} >{item?.question}</Text>
  //           {/* <Text style={styles.questionText} >{item.question.replace(/[^\w\s-]|_/g, '')}</Text> */}
  //         </Pressable>
  //         {isExpanded && <>
  //           <Text style={styles.answerText}>{item?.answer}</Text>
  //           <Text style={styles.answerText}>{item?.answer2}</Text>
  //           <Text style={styles.answerText}>{item?.answer3}</Text>
  //           <Text style={styles.answerText}>{item?.answer4}</Text>
  //         </>
  //         }
  //       </View>
  //     </View>
  //   );
  // };
  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: (_, gestureState) => gestureState.dy > 5,
      onPanResponderMove: (_, gestureState) => {
        if (gestureState.dy > 0) {
          translateY.setValue(gestureState.dy);
        }
      },
      onPanResponderRelease: (_, gestureState) => {
        if (gestureState.dy > 50) {
          toggleBottomSheet(false);
          setIsVisible(false);
        } else {
          toggleBottomSheet(true);
          setIsVisible(true);
        }
      },
    })
  ).current;
  const toggleBottomSheet = (show) => {
    Animated.spring(translateY, {
      toValue: show ? 0 : 800,
      useNativeDriver: true,
      duration: 300,
    }).start(() => {
    });
  };
  const openBottomSheet = () => {
    setIsVisible(true);
    toggleBottomSheet(true);
  };
  const closeBottomSheet = () => {
    Keyboard.dismiss()

    setIsVisible(false);
    toggleBottomSheet(false);
  };
  const FooterComponent = () => {
    return (
      <TouchableOpacity

        onPress={() => {
          openBottomSheet()
        }}
        activeOpacity={0.8}
        style={{
          // width: 170,
          height: 50,
          backgroundColor: COLORS.primary,
          borderRadius: 100,
          justifyContent: "center",
          alignItems: "center",
          marginTop: Platform.OS === "web" ? 5 : "3%",
          marginBottom: 40, alignSelf: 'center'
        }}
      >
        <Text
          allowFontScaling={false}
          style={{
            fontFamily: FONTS.PoppinsMedium,
            fontSize: 14,
            fontWeight: "500",
            color: COLORS.white, paddingHorizontal: 15
          }}
        >
          {t("Product Request")}
        </Text>
      </TouchableOpacity>
    );
  };
  function notifyMessage(msg) {
    if (Platform.OS === "ios") {
      Alert.alert(msg);
    } else {
      Toast.show(msg, {
        type: "normal",
        placement: "bottom",
        duration: 1200,
        offset: 0,
        animationType: "zoom-in",
      });
    }
  }
  const openGmail = () => {
    const email = "products@nutrisearch.ca";
    const subject = "Product Request";

    const body = `
      Product Name: ${productName}
      Manufacturer Name: ${manufacturer}
      Description: ${description}
      Url:${urlLink}

    `;

    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
      email
    )}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.open(gmailUrl, "_blank");
  };
  const openGmail_through_linking = () => {
    const email = "products@nutrisearch.ca";
    const subject = "Product Request";
    const body = `
      Product Name: ${productName}
      Manufacturer Name: ${manufacturer}
      Description: ${description}
      Url:${urlLink}
    `;
    const url = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    Linking.openURL(url);
  };
  function handleProductRequest() {
    if (manufacturer == "") {
      notifyMessage('Please Enter Manufacture')
    }
    else if (productName == "") {
      notifyMessage('Please Enter Product')

    }
    else if (description == "") {
      notifyMessage('Please Enter description')

    }
    else {
      if (Platform.OS === "web") {
        openGmail();
      } else {
        // Linking.openURL("mailto:products@nutrisearch.ca");
        openGmail_through_linking()
      }
      closeBottomSheet();

    }


  }
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);

  useEffect(() => {
    // Subscribe to keyboard events
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => setKeyboardVisible(true) // Keyboard is open
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => setKeyboardVisible(false) // Keyboard is closed
    );

    // Clean up the event listeners
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);
  return (
    <View style={[styles.container, { marginLeft: isLandscape ? 75 : 0, }]}>
      <StatusBar animated={true} barStyle={'light-content'} backgroundColor={'transparent'} translucent={true} />
      <View style={styles.header}>
        <Text allowFontScaling={false} style={styles.title}>{t('FAQ')}</Text>
      </View>
      {isActive ? (
        <ActivityIndicator color={COLORS.primary} size={'small'} style={styles.loader} />
      ) : (
        <View style={{ flex: 1, borderWidth: 0, marginBottom: isLandscape ? 0 : 60 }}>
          {/* <FlatList
            ListHeaderComponent={() => <Text style={[styles.headerText]}>{t('Popular Solutions')}</Text>}
            data={faqData}
            renderItem={renderFaqItem}
            keyExtractor={(item) => item.id}
            showsVerticalScrollIndicator={false}
            ListFooterComponent={FooterComponent}
          /> */
            <FlatList
              ListHeaderComponent={() => <Text style={[styles.headerText]}>{t('Popular Solutions')}</Text>}
              // data={faqData}
              data={FAQs.faqs}
              renderItem={renderFaqItem}
              keyExtractor={(item) => item.id}
              showsVerticalScrollIndicator={false}
              ListFooterComponent={FooterComponent}
            />}
        </View>
      )}
      {isVisible === true ? (
        <BlurView
          tint={"dark"}
          intensity={isVisible === true ? 20 : 0}
          style={styles.blackBlurContainer}
        ></BlurView>
      ) : null}
      <Animated.View
        // {...panResponder.panHandlers}
        style={[styles.bottomSheet, { transform: [{ translateY }] }]}
      >
        {/* <ScrollView contentContainerStyle={{ paddingBottom: isKeyboardVisible ? 150 : 10, }}> */}

        <View
          style={styles.animatedView}
        >
          <Text
            allowFontScaling={false}
            style={styles.requestText}
          >
            {t("Request")}
          </Text>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={closeBottomSheet}
            style={{
              height: 35,
              width: 35,
              position: "absolute",
              right: 9,
              bottom: 12,
            }}
          >
            <Image
              source={crossCircle}
              style={{ height: 35, width: 35, resizeMode: "contain" }}
            />
          </TouchableOpacity>
        </View>
        <ScrollView style={{ flex: 1 }}>
          <KeyboardAvoidingView style={{ flex: 1 }}
          behavior={Platform.OS === 'ios' ? 'padding' : 'position'}
            keyboardVerticalOffset={Platform.OS === 'ios' ? 20 : 0}
          >
            <Pressable onPress={() => Keyboard.dismiss()} >
              <TextInput
                value={manufacturer}
                onChangeText={(text) => setManufacture(text)}
                keyboardType="ascii-capable"
                style={[
                  styles.inputView,
                  { fontFamily: FONTS.PoppinsRegular, fontWeight: "400" },
                  Platform.OS === "web" ? { outlineColor: "white", outlineStyle: "none" } : {}
                ]}
                placeholder={t("Manufacturer")}
                placeholderTextColor={COLORS.primarygraycolor}
                color={COLORS.primarygraycolor}
              />
              <TextInput
                value={productName}
                onChangeText={(text) => setProductName(text)}
                autoCapitalize="none"
                keyboardType="ascii-capable"
                style={[
                  styles.inputproductname,
                  { fontFamily: FONTS.PoppinsRegular, fontWeight: "400" },
                  Platform.OS === "web" ? { outlineColor: "white", outlineStyle: "none" } : {}
                ]}
                placeholder={t("Product Name")}
                placeholderTextColor={COLORS.primarygraycolor}
                color={COLORS.primarygraycolor}
              />
              <TextInput
                value={urlLink}
                onChangeText={(text) => setUrlLink(text)}
                autoCapitalize="none"
                keyboardType="ascii-capable"
                style={[
                  styles.inputproductname,
                  { fontFamily: FONTS.PoppinsRegular, fontWeight: "400" },
                  Platform.OS === "web" ? { outlineColor: "white", outlineStyle: "none" } : {}
                ]}
                placeholder={t("URL")}
                placeholderTextColor={COLORS.primarygraycolor}
                color={COLORS.primarygraycolor}
              />
              <Pressable
                onPress={() => Keyboard.dismiss()}
                style={{
                  // width: "100%",
                  height: 160,
                  width: Platform.OS === "web" ? "50%" : "64%",
                  maxWidth: 436,
                  minWidth: 325,
                  borderWidth: 1,
                  borderRadius: 8,
                  paddingRight: Platform.OS === "web" ? 0 : 20,
                  borderColor: COLORS.primarybordercolor,
                  alignSelf: "center",
                  marginTop: 20,
                }}
              >
                <TextInput
                  value={description}
                  onChangeText={(text) => setDescription(text)}
                  style={[
                    styles.textInput,
                    { height: Platform.OS === "web" ? 250 : null },
                    Platform.OS === "web" ? { outlineColor: "white", outlineStyle: "none", } : {}
                  ]}
                  multiline={true}
                  placeholder={t("Description")}
                  // returnKeyType="done"
                  keyboardType="ascii-capable"
                  // blurOnSubmit={true}
                  // onSubmitEditing={() => Keyboard.dismiss()}
                  color={COLORS.primarygraycolor}
                  placeholderTextColor={COLORS.primarygraycolor}
                />
              </Pressable>
            </Pressable>
          </KeyboardAvoidingView>
          <TouchableOpacity
            onPress={() => {
              handleProductRequest()
            }}
            activeOpacity={0.6}
            style={[styles.submitBtn, { marginBottom: isKeyboardVisible ? 200 : 10 }]}
          >
            <Text
              style={{
                fontWeight: "500",
                fontFamily: FONTS.PoppinsSemiBold,
                fontSize: 14,
                textAlign: "center",
                lineHeight: 21,
                color: "white",
              }}
            >
              {t("Submit")}
            </Text>
          </TouchableOpacity>
        </ScrollView>

      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  header: {
    width: '100%',
    height: Platform.OS === 'web' ? 78 : 94,
    backgroundColor: COLORS.primary,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    alignItems: 'center',
    justifyContent: 'center', paddingTop: Platform.OS != "web" ? 45 : null
  },
  title: {
    fontSize: 22,
    color: COLORS.white,
    fontFamily: 'Poppins-SemiBold',
    fontWeight: '500',
  },
  loader: { flex: 1 },
  headerText: {
    paddingLeft: width >= 1200 ? 0 : 20,
    // paddingTop: 10,
    // marginBottom: 15,
    // borderWidth:1

    width: '100%',
    maxWidth: 1000,
    minWidth: 325,
    // padding: 15,
    borderRadius: 6, alignSelf: 'center',
    fontFamily: 'Poppins-SemiBold',
    fontWeight: '500',
    fontSize: 17,
    color: COLORS.primary,
    paddingTop: Platform.OS == "web" ? 10 : 5, paddingBottom: Platform.OS == "web" ? 10 : 5,
    // paddingTop:Platform.OS == "web" ? 3:0,
    // paddingBottom:Platform.OS == "web"? 3:0





  },
  faqItemContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 9,
    marginTop: 5
  },
  faqItem: {
    backgroundColor: 'white',
    shadowColor: 'black',
    shadowOffset: { width: 0, height: Platform.OS == "ios" ? 5 : 5 },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    elevation: Platform.OS === 'web' ? 0.1 : 5,
    width: '100%',
    maxWidth: 1000,
    minWidth: 325,
    padding: Platform.OS == "web" ? 15 : 6,
    borderRadius: 6, alignSelf: 'center'
  },
  questionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%', padding: 3
  },
  icon: { height: 14, width: 14 },
  questionText: {
    marginLeft: 10,
    fontSize: 14,
    color: COLORS.primary,
    letterSpacing: 0.8,
    fontFamily: 'Poppins-SemiBold',
    // fontWeight: '600',
    flexShrink: 1,

  },
  answerText: {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: '400',
    color: '#161819',
    marginTop: 5,
    fontFamily: FONTS.PoppinsRegular, padding: 5
  },
  blackBlurContainer: {
    flex: 1,
    // height: height,
    position: "absolute",
    width: width,
    justifyContent: "center",
  },
  popup: {
    position: "absolute",
    backgroundColor: "white",
    padding: 0,
    borderRadius: 12,
    elevation: 5,
    alignSelf: "center",
    height: 312,
    width: Platform.OS === "web" ? "20%" : "70%",
    maxWidth: 400,
    minWidth: Platform.OS === "web" ? 300 : undefined,
    borderWidth: 0,
    top: Platform.OS === "web" ? 150 : 300,
  },
  popup22: {
    position: "absolute",
    backgroundColor: "white",
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 35,
    borderRadius: 10,
    elevation: 5,
    alignSelf: "center",
    width: Platform.OS === "web" ? "20%" : "80%",
    maxWidth: 436,
    minWidth: Platform.OS === "web" ? 325 : undefined,
    top: Platform.OS === "web" ? 150 : 300,
  },
  blurBackground: {
    ...StyleSheet.absoluteFillObject,
  },
  submitBtn: {
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderRadius: 30,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    alignSelf: "center",
    marginTop: 20,
    backgroundColor: COLORS.primary,
    alignItems: "center",
    justifyContent: "center",
  },
  textInput: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingRight: 20,
    color: "black",
    fontFamily: FONTS.PoppinsRegular,
    fontWeight: "400",
  },
  leftmodal: {
    height: 170,
    width: 183,
    backgroundColor: "white",
    alignSelf: "flex-end",
    marginRight: 25,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    position: "absolute",
    top: 72,
    right: Platform.OS === "web" ? 0 : 0,
    shadowColor: "black",
    borderBottomRightRadius: 20,
    shadowOffset: {
      width: 0,
      height: 14,
    },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    elevation: Platform.OS === "web" ? 0.1 : 5,
  },
  popup2: {
    position: "absolute",
    top: "20%",
    // width: '80%',
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 290,
    minWidth: 280,
    padding: 10,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    alignItems: "center",
    alignSelf: "center",
  },
  popupText2: {
    fontSize: 20,
    marginBottom: 10,
    fontFamily: FONTS.PoppinsMedium,
    color: COLORS.primary,
    textAlign: "center",
  },
  closeButton2: {
    fontSize: Platform.OS == "web" ? 18 : 15,
    color: COLORS.primary,
    // color: "white",
  },
  bottomSheet: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: Platform.OS === "ios" ? height - 120 : Platform.OS === "web" ? 600 : "90%",
    backgroundColor: "white",
    // padding: 16,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    elevation: 5,
  },
  inputproductname: {
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderWidth: 1,
    borderRadius: 8,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    borderColor: COLORS.primarybordercolor,
    fontFamily: FONTS.PoppinsMedium,
    alignSelf: "center",
    marginTop: 20,
  },
  inputView: {
    width: Platform.OS === "web" ? "50%" : width - 92,
    marginTop: 25,
    maxWidth: 436,
    minWidth: 325,
    borderWidth: 1,
    borderRadius: 8,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    borderColor: COLORS.primarybordercolor,
    alignSelf: "center",
  },
  animatedView: {
    height: 50,
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 15,
    paddingLeft: 15,
    borderTopRightRadius: 12,
    justifyContent: "center",
    borderTopLeftRadius: 12,
    marginTop: 5,
  }, requestText: {
    color: COLORS.primary,
    fontSize: 24,
    textAlign: "center",
    lineHeight: 36,
    alignSelf: "center",
    marginTop: 5,
    fontFamily: "Poppins-SemiBold",
    fontWeight: Platform.OS === "web" ? 0 : "600",
  }
});

export default Faq;
