

// import { View, Text, Image, FlatList, Platform, Dimensions, Pressable } from 'react-native'
// import React from 'react'
// import { FONTS } from '../../utils';
// import { useNavigation } from '@react-navigation/native';
// import { useTranslation } from 'react-i18next';

// export default function RecentSearch({ data, marginTop }) {
//     const { t } = useTranslation();
//     console.log(">>>>>>>>>>>>>>>>>>>> >>>>>>>>>>>>>> >>>>>>>>>>>>. margin top", marginTop)
//     const arrowAngle = require('../assets/images/arrowangle.png')
//     const { width, height } = Dimensions.get('window')
//     const navigation = useNavigation();
//     return (
//         <View style={{
//             backgroundColor: 'white',
//             width: Platform.OS === 'web' ? '50%' : width - 32,
//             maxWidth: Platform.OS === 'web' ? 436 : '99.5%',
//             minWidth: 325,
//             borderBottomLeftRadius: 6,
//             borderBottomRightRadius: 6,
//             alignSelf: "center",
//             height: 200, position: 'absolute',
//             top: 48,
//             paddingRight: 10,
//             paddingLeft: 10,
//             paddingTop: 15,
//             height: 200,
//             backgroundColor: 'white',
//             alignSelf: 'center',
//             shadowColor: 'black',
//             width: '100%',
//             shadowOffset: {
//                 width: 0,
//                 height: 14,
//             },
//             shadowOpacity: 0.3,
//             shadowRadius: 6,
//             elevation: 5,
//         }}>

//             <FlatList
//                 showsVerticalScrollIndicator={false}
//                 data={data}
//                 renderItem={({ item }) => (
//                     <Pressable
//                         onPress={() => { navigation.navigate('Graph', { item: item }) }}
//                         style={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
//                         <View style={{ width: '95%', flexDirection: 'row', borderWidth: 0, }}>
//                             <Text
//                                 allowFontScaling={false}
//                                 numberOfLines={1}
//                                 style={{
//                                     fontWeight: '500',
//                                     fontSize: 14,
//                                     color: '#6F7479',
//                                     fontFamily: FONTS.PoppinsMedium,

//                                 }}>
//                                 {item?.company_name} {item?.product_name}
//                             </Text>

//                         </View>
//                         <View style={{ width: '5%' }}>
//                             <Image
//                                 source={arrowAngle}
//                                 style={{ height: 9, width: 9, }}
//                             />
//                         </View>


//                     </Pressable>
//                 )}
//             />
//         </View>

//     )
// }






import React from 'react';
import { View, Text, Image, FlatList, Platform, Dimensions, Pressable, StyleSheet } from 'react-native';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { FONTS } from '../../utils';

interface SearchItem {
    company_name: string;
    product_name: string;
}
interface RecentSearchProps {
    data: SearchItem[];
}

const RecentSearch: React.FC<RecentSearchProps> = ({ data }) => {
    const { t } = useTranslation();
    const arrowAngle = require('../assets/images/arrowangle.png');
    const { width } = Dimensions.get('window');
    const navigation = useNavigation<NavigationProp<any>>();

    return (
        <View style={[
            styles.container,
            {
                width: Platform.OS === 'web' ? '50%' : width - 32,
                maxWidth: Platform.OS === 'web' ? 436 : '99.5%',
            }
        ]}>
            <FlatList
                data={data}
                keyExtractor={(_, index) => index.toString()}
                showsVerticalScrollIndicator={false}
                renderItem={({ item }) => (
                    <Pressable onPress={() => navigation.navigate('Graph', { item })} style={styles.itemContainer}>
                        <Text style={styles.itemText} numberOfLines={1} allowFontScaling={true}>
                            {`${item.company_name} ${item.product_name}`}
                        </Text>
                        <Image source={arrowAngle} style={styles.arrowIcon} />
                    </Pressable>
                )}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        minWidth: 325,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        alignSelf: 'center',
        position: 'absolute',
        padding: 10,
        paddingVertical: 15,
    },
    itemContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    itemText: {
        fontWeight: '500',
        fontSize: 14,
        color: '#6F7479',
        fontFamily: FONTS.PoppinsMedium,
        width: '95%',
    },
    arrowIcon: {
        height: 9,
        width: 9,
    },
});

export default RecentSearch;


