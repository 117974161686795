import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Image,
  TouchableOpacity,
  StatusBar,
  ActivityIndicator,
  Alert,
} from "react-native";
import React, { useState, useRef } from "react";
import { API, COLORS, FONTS } from "../../../utils";
import { TextInput } from "react-native";
import { Pressable } from "react-native";
import { Platform } from "react-native";
import { ScrollView } from "react-native";
import { Toast } from "react-native-toast-notifications";
import { useTranslation } from "react-i18next";
const { width, height } = Dimensions.get("screen");

export default function VerifyEmail({ navigation, route }) {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const { email } = route?.params;
  let mail = email;
  const LeftArrow = require("../../assets/images/arrow-left.png");
  const emailimg = require("../../assets/images/emailicon.png");
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isToastVisible, setIsToastVisible] = useState(false);

  function notifyMessage(msg: any) {
    if (Platform.OS === "ios") {
      Alert.alert(msg);
    } else if (Platform.OS === "android") {
      if (!isToastVisible) {
        Toast.show(msg, {
          type: "normal",
          placement: "bottom",
          duration: 1000,
          offset: 0,
          animationType: "zoom-in",
          onHide: () => setIsToastVisible(false),
        });
        setIsToastVisible(true);
      }
    } else {
      if (!isToastVisible) {
        Toast.show(msg, {
          type: "normal",
          placement: "bottom",
          duration: 1000,
          offset: 0,
          animationType: "zoom-in",
          onHide: () => setIsToastVisible(false),
        });
        setIsToastVisible(true);
      }
    }
  }

  async function verifyOtp() {
    setLoader(true);
    try {
      const otpString = otp.join("");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
      var formdata = new FormData();
      formdata.append("email", email);
      formdata.append("otp", otpString);
      formdata.append("type", "2");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
        // mode: ' // mode: 'no- cors''
      };

      const response = await fetch(
        API.baseurl + API.forgetpassword,
        requestOptions
      );
      const result = await response.json();
      if (result?.status === 200) {
        notifyMessage(result?.message);
        navigation.navigate("ResetPassword", { email: mail });
        setLoader(false);
      } else if (result?.status === 400) {
        notifyMessage(result?.message);
        setLoader(false);
      }
    } catch (error) {
      notifyMessage(error);
      console.log("Error during OTP verification:", error);
      setLoader(false);
    }
  }
  const handleOTPChange = (text, index) => {
    if (/^[0-9]$/.test(text)) {
      otp[index] = text;
      setOtp([...otp]);

      if (index < 3) {
        inputRefs[index + 1].current.focus();
      }
    }
  };
  const handleBackspace = (event, index) => {
    if (event.nativeEvent.key === "Backspace") {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
      otp[index] = "";
      setOtp([...otp]);
    }
  };
  function notifyMessage(msg) {
    Toast.show(msg, {
      type: "normal",
      placement: "bottom",
      duration: 1200,
      offset: 0,
      animationType: "zoom-in",
    });
  }
  return (
    <ScrollView style={{ flex: 1, backgroundColor: "white" }}>
      <StatusBar
        animated={true}
        barStyle={"light-content"}
        backgroundColor={"transparent"}
        translucent={true}
      />
      <View style={styles.container}>
        <View style={styles.headerView}>
          <Pressable style={styles.img} onPress={() => navigation.goBack()}>
            <Image style={styles.img} source={LeftArrow} />
          </Pressable>
          <Text style={styles.title}>NutriSearch</Text>
        </View>
        <Image
          resizeMode="contain"
          source={emailimg}
          style={{
            width: 73,
            height: 62,
            marginTop: Platform.OS === "web" ? "2%" : "12%",
          }}
        />
        <Text style={[styles.txt6]}>{t("Verify Your Email")}</Text>
        <Text style={styles.txt7}>
          {t("Enter the verification code that we have")}
        </Text>
        <Text style={styles.txt7}>{t(" sent to you")}</Text>
        <View style={styles.container2}>
          {inputRefs.map((inputRef, index) => (
            <TextInput
              editable={true}
              key={index}
              ref={inputRef}
              style={styles.input2}
              keyboardType="numeric"
              maxLength={1}
              value={otp[index]}
              onChangeText={(text) => handleOTPChange(text, index)}
              onKeyPress={(event) => handleBackspace(event, index)}
              fontSize={18}
              fontFamily={"PoppinsMedium"}
              fontWeight={"700"}
            />
          ))}
        </View>
        <View style={{ height: 40, marginTop: 5 }}>
          {loader === true ? (
            <ActivityIndicator
              color={COLORS.primary}
              style={{ marginVertical: Platform.OS === "web" ? "0.1%" : "10%" }}
            />
          ) : (
            <ActivityIndicator
              color={COLORS.white}
              style={{ marginVertical: Platform.OS === "web" ? "0.1%" : "10%" }}
            />
          )}
        </View>
        <TouchableOpacity
          onPress={() => verifyOtp()}
          activeOpacity={0.8}
          style={styles.Signinbtn}
        >
          <Text style={styles.txt5}>{t("Verify")}</Text>
        </TouchableOpacity>
        <Pressable
          onPress={() => navigation.navigate("Signin")}
          style={{
            flexDirection: "row",
            marginTop: Platform.OS === "web" ? "5%" : height - 700,
          }}
        >
          <Text style={styles.backtoText}>{t("Back to ")}</Text>
          <Text style={styles.logintext}>{t("Log in")}</Text>
        </Pressable>
      </View>
    </ScrollView>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
    padding: 20,
    alignItems: "center",
  },
  headerView: {
    width: "100%",
    flexDirection: "row",
    marginTop: Platform.OS === "web" ? 0 : 35,
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    height: 24,
    width: 24,
    position: "absolute",
    left: 0,
    top: 7,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 22,
    fontWeight: "600",
    color: COLORS.white,
    fontFamily: FONTS.PoppinsMedium,
  },
  txt1: {
    fontFamily: FONTS.PoppinsMedium,
    fontWeight: "700",
    fontSize: 15,
    letterSpacing: 0.84,
    color: COLORS.black,
    marginTop: Platform.OS === "web" ? 20 : 51,
  },
  txt2: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.black,
  },
  checkbox: {
    height: 16,
    width: 16,
    marginRight: "3%",
  },
  txt3: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "400",
    color: COLORS.primarygraycolor,
  },
  txt4: {
    marginTop: 33,
    textDecorationLine: "underline",
    fontFamily: FONTS.PoppinsMedium,
    fontWeight: "300",
    fontSize: 14,
    color: COLORS.primary,
  },
  Signinbtn: {
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderRadius: 46,
    height: 50,
    backgroundColor: COLORS.primary,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 40,
  },
  txt5: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.white,
  },
  txt6: {
    fontWeight: "700",
    fontSize: 18,
    lineHeight: 27,
    fontFamily: FONTS.PoppinsMedium,
    letterSpacing: 1,
    marginTop: 25,
    color: "black",
    fontFamily: FONTS.PoppinsMedium,
  },
  txt7: {
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 21,
    letterSpacing: 0.5,
    color: "black",
    fontFamily: FONTS.PoppinsMedium,
  },
  container2: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    width: Platform.OS === "web" ? "50%" : "65%",
    maxWidth: 280,
    minWidth: 230,
  },
  input2: {
    borderTopColor: COLORS.primarybordercolor,
    borderRightColor: COLORS.primarybordercolor,
    borderLeftColor: COLORS.primarybordercolor,
    height: 50,
    width: 50,
    borderWidth: 1,
    borderRadius: 10,
    borderBottomWidth: 3,
    borderBottomColor: COLORS.primary,
    paddingLeft: 20,
  },
  logintext: {
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 21,
    color: COLORS.primary,
    fontFamily: FONTS.PoppinsRegular,
  },
  backtoText: {
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 21,
    color: COLORS.primarygraycolor,
    fontFamily: FONTS.PoppinsRegular,
  },
});
