import {
  StyleSheet,
  Animated,
  Easing,
  ImageBackground,
  Image,
  StatusBar,
} from "react-native";
import React, { useEffect, useRef } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import background from "../../assets/images/background.png";
import logo from "../../assets/images/nutrilogo.png";
import { API } from "../../../utils";

export default function Splash({ navigation }) {
  const translateYAnim = useRef(new Animated.Value(500)).current;
  useEffect(() => {
    Animated.timing(translateYAnim, {
      toValue: 0,
      duration: 1000,
      easing: Easing.out(Easing.ease),
      useNativeDriver: true,
    }).start(() => {
      navigateToScreen();
    });
  }, []);
  const navigateToScreen = async () => {
    try {
      const value = await AsyncStorage.getItem("key");
      const id = await AsyncStorage.getItem("id");
      if (!value || !id) {
        navigation.replace("Signin");
        return;
      }
      await getprofile(value, id);
    } catch (e) {
      console.log("splash error", e);
      navigation.replace("Signin");
    }
  };

  const getprofile = async (value, id) => {
    var myHeaders = new Headers();
    myHeaders.append("id", id);
    myHeaders.append("session_key", value);
    myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
    myHeaders.append(
      "Cookie",
      "ci_session=2b19bf32b9a2f50974f1700c1479e593ec66dae2"
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      `${API.baseurl}${API.getprofile}?id=${id}&session_key=${value}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.message === "Client Error: Unauthorized") {
          navigation.replace("Signin");
        } else {
          navigation.replace("Home2");
        }
      })
      .catch((error) => {
        console.log("Profile fetch error", error);
        navigation.replace("Signin");
      });
  };

  return (
    <ImageBackground source={background} style={styles.container}>
      <Animated.View
        style={[
          styles.container,
          { transform: [{ translateY: translateYAnim }] },
        ]}
      >
        <StatusBar
          animated={true}
          barStyle={"light-content"}
          backgroundColor={"transparent"}
          translucent={true}
        />
        <Image source={logo} style={{ height: 174, width: 201 }} />
      </Animated.View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  },
});
