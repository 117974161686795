
import { ColorValue } from "react-native";
import Svg, { Circle, Defs, G, Path, Rect } from "react-native-svg"
interface StyledSvgProps {
    color?: ColorValue
}
export const AccountIcon = (props: StyledSvgProps) => {
    const { color, ...otherProps } = props;
    return (
        <Svg width="120" height="120" viewBox="0 0 120 120" fill="none" {...otherProps}>
            <G clip-path="url(#clip0_247_2266)">
                <Path d="M52.3332 70.25H67.7498L71.0832 70.4167L74.0832 70.75L76.7498 71.25L79.0832 71.8333L81.9998 72.75L84.1665 73.5833L86.2498 74.5L88.4165 75.5833L90.1665 76.5833L91.7498 77.5833L93.0832 78.5L94.4165 79.5L95.5832 80.4167L96.6665 81.3333L97.4998 82.0833L98.1665 82.6667L100.333 84.8333L100.917 85.5L101.167 85.75V85.9167H101.333L102.083 86.8333L103.167 88.1667L104.333 89.75L105 90.75L106.083 92.4167L107.25 94.5L108.083 96.0833L109.083 98.25L110 100.583L110.917 103.333L111.583 105.833L112.083 108.417L112.5 111.333L112.75 114.417V117.083L112.5 117.833L111.917 118.833L111.25 119.417L110.417 119.833L110 120H9.9165L8.74984 119.417L8.1665 118.917L7.58317 118.083L7.24984 117.333L7.1665 116.667L7.24984 113.5L7.49984 111L7.9165 108.167L8.6665 104.75L9.24984 102.75L9.99984 100.5L11.0832 97.8333L12.2498 95.4167L13.1665 93.6667L14.1665 92L15.4998 90L16.1665 89.0833L17.1665 87.75L17.9165 86.8333L18.9998 85.5833L19.1665 85.3333H19.3332L19.4998 85L20.2498 84.25L20.3332 84.0833H20.4998V83.9167H20.6665V83.75L21.3332 83.1667L22.5832 81.9167L23.4998 81.1667L24.6665 80.1667L25.7498 79.3333L26.9165 78.5L27.7498 77.9167L29.1665 77L30.4165 76.25L32.2498 75.25L33.4998 74.5833L35.5832 73.6667L37.9165 72.75L40.0832 72.0833L42.2498 71.5L44.9998 70.9167L47.3332 70.5833L48.9165 70.4167L52.3332 70.25Z" fill={color || "#D7D7D7"} />
                <Path d="M56.7502 0H63.9168L64.2502 0.166667L66.9168 0.666667L69.1668 1.25L70.9168 1.83333L72.9168 2.66667L74.8335 3.58333L76.0002 4.25L77.3335 5.08333L78.4168 5.83333L79.7502 6.83333L80.9168 7.83333L81.9168 8.75L83.5002 10.3333L84.0835 11L85.1668 12.3333L86.1668 13.75L87.0835 15.1667L88.0835 16.9167L89.2502 19.3333L90.1668 21.8333L90.9168 24.5L91.3335 26.75L91.5835 28.8333L91.6668 30.25V32.9167L91.5002 35.25L91.1668 37.5L90.7502 39.3333L90.1668 41.4167L89.2502 43.9167L88.0835 46.3333L87.5002 47.4167L86.7502 48.6667L85.8335 50L84.8335 51.3333L83.8335 52.5L83.2502 53.1667L81.0835 55.3333L80.1668 56.0833L78.9168 57.0833L77.5835 58L76.0002 59L74.8335 59.6667L72.9168 60.5833L70.8335 61.4167L68.2502 62.25L65.5835 62.8333L63.0002 63.1667L60.5002 63.3333H59.4168L56.6668 63.1667L54.1668 62.8333L51.1668 62.0833L48.6668 61.25L46.7502 60.4167L45.1668 59.6667L43.1668 58.5L41.3335 57.25L40.0002 56.1667L39.0835 55.4167L37.8335 54.25L36.5835 53L36.0002 52.3333L35.0835 51.25L33.9168 49.6667L33.0002 48.25L32.0835 46.6667L31.4168 45.4167L30.5002 43.3333L29.6668 41L29.0835 38.75L28.5835 36.1667L28.3335 33.5833V29.5L28.5835 27.0833L29.0835 24.5L29.7502 22L30.7502 19.3333L31.5002 17.6667L32.5002 15.8333L33.4168 14.3333L34.5835 12.6667L35.5835 11.4167L36.1668 10.75L36.9168 9.91667L37.1668 9.58333H37.3335L37.5002 9.25H37.6668V9.08333L38.3335 8.5L38.9168 7.91667L39.8335 7.16667L41.1668 6.08333L43.1668 4.75L44.4168 4L46.3335 3L48.0002 2.25L49.2502 1.75L51.3335 1.08333L54.1668 0.416667L56.7502 0Z" fill={color || "#D7D7D7"} />
            </G>
            {/* <Defs>
                <clipPath id="clip0_247_2266">
                    <Rect width="120" height="120" fill="white" />
                </clipPath>
            </Defs> */}
        </Svg>
    )
}
