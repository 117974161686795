import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  Pressable,
  Image,
  Platform,
  Dimensions,
} from "react-native";
import RenderHtml from "react-native-render-html";
import { COLORS, FONTS } from "../../../utils";
import { StatusBar } from "react-native";
const { width } = Dimensions.get("window");

const BlogScreen = ({ navigation, route }) => {
  const LeftArrow = require("../../assets/images/whiteleftarrow.png");
  const { item } = route.params;
  const initialHtmlData = item?.body_html;
  const [htmlData, setHtmlData] = useState("");
  const [textv, setTextV] = useState("");

  useEffect(() => {
    const paragraphs = initialHtmlData.split("</p>");
    paragraphs[0] = paragraphs[0].replace(
      "<p",
      '<p style="font-style: Bold; font-weight: bold; font-family: Poppins-Regular; color: #2c648e; font-size: 35px; text-align: center;"'
    );
    let firstParagraphText = paragraphs[0].replace(/<[^>]*>/g, "");
    firstParagraphText = firstParagraphText.replaceAll("\n<blockquote>\n", "");
    setTextV(firstParagraphText);
    const filteredParagraphs = paragraphs.slice(1);
    const modifiedHtml = filteredParagraphs.join("</p>");
    let modifiedHtmlContent = modifiedHtml.replace(
      /<p/g,
      '<p style="font-size: 19px; font-family: Poppins-Regular !important; line-height: 22px;"'
    );

    modifiedHtmlContent = modifiedHtmlContent.replace(
      /<h1/g,
      '<h1 style="color: #2c648e;">'
    );

    modifiedHtmlContent = modifiedHtmlContent.replace(
      /<h2>/g,
      '<h2 style=" color: #2c648e;">'
    );
    modifiedHtmlContent = modifiedHtmlContent.replace(
      /<h3>/g,
      '<h3 style=" color: #2c648e;">'
    );
    modifiedHtmlContent = modifiedHtmlContent.replace(
      /<div>/g,
      '<div style="font-family:Raleway !important;">'
    );
    setHtmlData(modifiedHtmlContent);
  }, [initialHtmlData]);

  return (
    <View style={styles.container}>
      <StatusBar
        animated={true}
        barStyle={"light-content"}
        backgroundColor={"transparent"}
        translucent={true}
      />
      <View style={[styles.View1, Platform.OS === "web" && styles.webStyle]}>
        <View style={styles.headerView}>
          <Pressable onPress={() => navigation.goBack()} style={styles.img}>
            <Image
              style={{
                height: 24,
                width: 24,
                marginLeft: Platform.OS === "web" ? 10 : 30,
                marginTop: -10,
              }}
              source={LeftArrow}
            />
          </Pressable>
          <Text
            allowFontScaling={false}
            numberOfLines={3}
            style={[
              styles.title,
              {
                width: Platform.OS === "web" ? "95%" : "80%",
                color: "white",
                fontSize: 19,
                fontFamily: FONTS.PoppinsSemiBold,
                paddingLeft: 12,
                paddingRight: 12,
                marginTop: -9,
                textAlign: "center",
                maxWidth: 1000,
                marginLeft: width > 1200 ? 30 : 0,
                margin: "auto",
                borderWidth: 0,
              },
            ]}
          >
            {item?.title}
          </Text>
          <Pressable style={styles.img}>
            <View
              style={{ height: 24, width: 24, marginLeft: 10, marginTop: -10 }}
            />
          </Pressable>
        </View>
      </View>
      <ScrollView style={{ flex: 1 }}>
        <View
          style={{
            flex: 1,
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 20,
            maxWidth: Platform.OS === "web" ? width > 1200 && 990 : "100%",
            margin: Platform.OS === "web" ? width > 1200 && "auto" : "auto",
          }}
        >
          <RenderHtml contentWidth={width} source={{ html: htmlData }} />
        </View>
      </ScrollView>
    </View>
  );
};

export default BlogScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.primaryBackgroundColor,
  },
  View1: {
    width: "100%",
    backgroundColor: COLORS.primary,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    shadowColor: "#000",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    elevation: 10,
  },
  headerView: {
    width: "100%",
    flexDirection: "row",
    marginTop: Platform.OS === "web" ? 30 : 50,
    alignItems: "center",
    paddingLeft: -40,
    justifyContent: "center",
    minHeight: 60,
    marginBottom: 9,
  },
  webStyle: {
    boxShadow: "rgba(0, 0, 0, 0.34) 0px 5px 6.27px",
    height: "auto",
    position: "sticky",
    top: 0,
    zIndex: 999999,
  },
});
