import { Alert, Platform, StyleSheet } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import AppNavigator from "./src/screens/navigation/navigation";
import { SafeAreaProvider } from "react-native-safe-area-context";
import React, { useEffect, useState } from "react";
import * as Font from "expo-font";
import { LogBox } from "react-native";
import { API, FONTS } from "./utils";
import { ToastProvider } from "react-native-toast-notifications";
import { DataProvider } from "./src/components/DataContext";
import { ReaderProvider } from "@epubjs-react-native/core";
import { OrientationProvider } from "./src/components/OrientationContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import messaging from "./firebaseMessaging";
import notifee, { AndroidImportance } from "@notifee/react-native";
LogBox.ignoreAllLogs(true);
let PermissionsAndroid;
if (Platform.OS === "android") {
  PermissionsAndroid = require("react-native").PermissionsAndroid;
}
export default function App() {
  async function onDisplayNotification(value) {
    // Request permissions (required for iOS)
    if (Platform.OS == "ios") {
      await notifee.requestPermission();
    }
    // Create a channel (required for Android)
    const channelId = await notifee.createChannel({
      id: "default3",
      name: "Default Channel 3",
      sound: "default",
      importance: AndroidImportance.HIGH,
    });

    // Display a notification
    await notifee.displayNotification({
      title: value?.notification?.title,
      body: value?.notification?.body,
      android: {
        channelId,
        pressAction: {
          id: "defaul",
        },
      },
    });
  }

  useEffect(() => {
    if (Platform.OS !== "web") {
      const unsubscribe = messaging().onMessage(async (remoteMessage) => {
        console.log("Foreground message received:", remoteMessage);
        onDisplayNotification(remoteMessage);
      });
      return unsubscribe;
    }
  }, []);
  useEffect(() => {
    if (Platform.OS !== "web") {
      messaging().setBackgroundMessageHandler(async (remoteMessage) => {
        console.log("Background message received:", remoteMessage);
        return Promise.resolve();
      });
    }
  }, []);
  async function requestUserPermission() {
    if (Platform.OS == "ios") {
      const authStatus = await messaging().requestPermission();
      const enabled =
        authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
        authStatus === messaging.AuthorizationStatus.PROVISIONAL;
      if (enabled) {
        if (enabled) {
          console.log("enable");
        }
      } else {
        console.log("Notenable");
      }
    }
    if (Platform.OS == "android") {
      try {
        await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS
        );
      } catch (error) {
        console.error("Error checking notification permission:", error);
      }
    }
  }

  const getFCMToken = async () => {
    try {
      await requestUserPermission(); // Request notification permission on iOS

      const DToken = await messaging().getToken();
      if (DToken) {
        console.log("🚀 ~ getFCMToken ~ DToken:", DToken);
        // Alert.alert("FCM Token", DToken);
        storeData(DToken);
      } else {
        console.log("No FCM token received");
      }
    } catch (e) {
      console.log("Error fetching FCM token", e);
      // Alert.alert("Error", "Failed to get FCM Token", e);
    }
  };
  useEffect(() => {
    if (Platform.OS !== "web") {
      getFCMToken();
      // requestUserPermission();
    }
    async function loadFonts() {
      await Font.loadAsync({
        [FONTS.PoppinsRegular]: require("./src/assets/font/Poppins-Regular.ttf"),
        [FONTS.PoppinsBlack]: require("./src/assets/font/Poppins-Black.ttf"),
        [FONTS.PoppinsLight]: require("./src/assets/font/Poppins-Light.ttf"),
        [FONTS.PoppinsMedium]: require("./src/assets/font/Poppins-Medium.ttf"),
        [FONTS.PoppinsSemiBold]: require("./src/assets/font/Poppins-SemiBold.ttf"),
        "Poppins-SemiBold": require("./src/assets/font/Poppins-SemiBold.ttf"),
        "Poppins-Medium": require("./src/assets/font/Poppins-Medium.ttf"),
      });
    }
    loadFonts();
  }, []);
  const storeData = async (value) => {
    try {
      await AsyncStorage.setItem("DeviceToken", value);
    } catch (e) {
      console.log(JSON.stringify(e));
    }
  };
  return (
    <OrientationProvider>
      <ReaderProvider>
        <DataProvider>
          <SafeAreaProvider style={styles.container}>
            <ToastProvider>
              <NavigationContainer>
                <AppNavigator />
              </NavigationContainer>
            </ToastProvider>
          </SafeAreaProvider>
        </DataProvider>
      </ReaderProvider>
    </OrientationProvider>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
