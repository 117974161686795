import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Pressable,
  Image,
  Platform,
  StatusBar,
  Alert,
} from "react-native";
import React, { useState, useContext } from "react";
import { COLORS, FONTS } from "../../../utils";
import * as Progress from "react-native-progress";
import { ScrollView } from "react-native";
import fill from "../../assets/images/fillstar.png";
import unfill from "../../assets/images/unfillstar.png";
import StarRating from "../../components/starrating";
import { useTranslation } from "react-i18next";
import CountryFlag from "react-native-country-flag";
import { Popable } from "react-native-popable";
import { OrientationContext } from "../../components/OrientationContext";

const { width, height } = Dimensions.get("window");
const Graph = ({ navigation, route }) => {
  const isLandscape = useContext(OrientationContext);
  const { t } = useTranslation();
  const { item } = route.params;


  const LeftArrow = require("../../assets/images/whiteleftarrow.png");
  const icon = require("../../assets/images/achivementicon.png");
  const Platinum = require("../../assets/images/PlatinumL.png");
  const Silver = require("../../assets/images/SilverL.png");
  const Diamond = require("../../assets/images/DiamondL.png");

  const getProgress = (value) => {
    console.log("<<< Value >>>", value);
    if (value.charAt(0) == "-") {
      if (value.charAt(1) == "0" || value.charAt(2) == ".") {
        return `0.${value.charAt(3) + value.charAt(4)}`;
      } else if (value.charAt(1) == "1") {
        return value.charAt(1);
      }
    } else {
      return value;
    }
    // return value?.charAt(0) == "-"
    //   ? `0.${value.charAt(1) + value.charAt(2)}`
    //   : value;
  };
  const progressBarData = [
    {
      label: "Completeness",
      progress: getProgress(item?.completeness),
      color: item?.completeness?.charAt(0) === "-" ? COLORS.maroon : "#41AD59",
      definition:
        "Does the product contain the full spectrum of nutrients and nutrient categories listed in the Blended Standard and considered essential for optimal health?",
    },
    {
      label: "Potency",
      progress: getProgress(item?.potency),
      color: item?.potency?.charAt(0) === "-" ? COLORS.maroon : "#41AD59",
      definition:
        "For each nutrient in the product, what is the level of potency relative to the potency for that nutrient in the Blended Standard?",
    },
    {
      label: "Vitamin E Forms",
      progress: getProgress(item?.vitamin_e_forms),
      color:
        item?.vitamin_e_forms?.charAt(0) === "-" ? COLORS.maroon : "#41AD59",
      definition:
        "Does the product contain the more active forms of vitamin E, such as d-alpha-, gamma-, or mixed-tocopherols?",
    },
    {
      label: "Immune Support",
      progress: getProgress(item?.immune_support),
      color:
        item?.immune_support?.charAt(0) === "-" ? COLORS.maroon : "#41AD59",
      definition:
        "Does the product contain nutrients that boost immune response at the potencies established in the Blended Standard?",
    },
    {
      label: "Bone Health",
      progress: getProgress(item?.bone_health_score),
      color:
        item?.bone_health_score?.charAt(0) === "-" ? COLORS.maroon : "#41AD59",
      definition:
        "Does the product contain nutrients supporting bone strength and repair at the potencies established in the Blended Standard?",
    },
    {
      label: "Heart Health",
      progress: getProgress(item?.heart_health),
      color: item?.heart_health?.charAt(0) === "-" ? COLORS.maroon : "#41AD59",
      definition:
        "Does the product contain nutrients reducing risks of cardiovascular diseases at the potencies established in the Blended Standard?",
    },
    {
      label: "Liver Health (Detoxification)",
      progress: getProgress(item?.liver_health),
      color: item?.liver_health?.charAt(0) === "-" ? COLORS.maroon : "#41AD59",
      definition:
        "Does the product contain nutrients supporting liver function and detoxification at the potencies established in the Blended Standard?",
    },
    {
      label: "Metabolic Health",
      progress: getProgress(item?.metabolic_health),
      color:
        item?.metabolic_health?.charAt(0) === "-" ? COLORS.maroon : "#41AD59",
      definition:
        "Does the product contain nutrients that increase insulin sensitivity or assist in metabolic support at the potencies established in the Blended Standard?",
    },
    {
      label: "Healthy Aging Support",
      progress: getProgress(item?.healthy_aging_support),
      color:
        item?.healthy_aging_support?.charAt(0) === "-"
          ? COLORS.maroon
          : "#41AD59",
      definition:
        "Does the product contain phenolic compounds and other nutrients known to elicit a cellular response at the potencies established in the Blended Standard or the scientific literature?",
    },
    {
      label: "Methylation Support",
      progress: getProgress(item?.methylation_support),
      color:
        item?.methylation_support?.charAt(0) === "-"
          ? COLORS.maroon
          : "#41AD59",
      definition:
        "Does the product contain nutrients known to reduce homocysteine levels in the blood at the potencies established in the Blended Standard?",
    },
    {
      label: "Lipotropic Factors",
      progress: getProgress(item?.lipotropic_factors),
      color:
        item?.lipotropic_factors?.charAt(0) === "-" ? COLORS.maroon : "#41AD59",
      definition:
        "Does the product contain nutrients known to help the liver mobilize fat stores and remove toxins at the potencies established in the Blended Standard?",
    },
    {
      label: "Inflammation Control",
      progress: getProgress(item?.inflammation_control),
      color:
        item?.inflammation_control?.charAt(0) === "-"
          ? COLORS.maroon
          : "#41AD59",
      definition:
        "Does the product contain those nutrients known to reduce cellular inflammation at the potencies established in the Blended Standard?",
    },
    {
      label: "Glycation Control",
      progress: getProgress(item?.glycation_control),
      color:
        item?.glycation_control?.charAt(0) === "-" ? COLORS.maroon : "#41AD59",
      definition:
        "Does the product contain those nutrients known to reduce glycation, a key factor in aging, at the potencies established in the Blended Standard?",
    },
    {
      label: "Lifespan Essentials",
      progress: getProgress(item?.lifespan_essentials),
      color:
        item?.lifespan_essentials?.charAt(0) === "-"
          ? COLORS.maroon
          : "#41AD59",
      definition:
        "Does the product contain those bioflavonoids, phenolic acids, and other nutrients involved in gene transcription and anti-aging at the potencies established in the Blended Standard?",
    },
    {
      label: "Common Antioxidants (Penalty)",
      progress: getProgress(item?.common_antioxidants),
      color:
        item?.common_antioxidants?.charAt(0) === "-"
          ? COLORS.maroon
          : "#41AD59",
      definition:
        "Does the product contain excessive amounts of antioxidants that have recently been found to be ineffective or harmful at high doses? This is a penalty score.",
    },
    {
      label: "Potential Toxicities (Penalty)",
      progress: getProgress(item?.potential_toxicity),
      color:
        item?.potential_toxicity?.charAt(0) === "-" ? COLORS.maroon : "#41AD59",
      definition:
        "Does the product contain vitamin A and iron at potencies long known to be potentially harmful? This is a penalty score.",
    },
  ];
  const handleStarPress = (newRating) => { };
  const customStarImage = {
    filled: fill,
    empty: unfill,
  };
  const ProgressBarView = ({ label, definition, progress, color }) => (
    <View style={{ flexDirection: "row", marginBottom: 7 }}>
      <View style={styles.View2}>
        <Popable
          position="top"
          backgroundColor={COLORS.primary}
          style={{
            width: Platform.OS != "web" ? 250 : width <= 450 ? 200 : 500,
            // marginTop:Platform.OS != "web "? -34:0
            marginTop:
              Platform.OS != "android" ? -34 : Platform.OS == "ios" ? -30 : 0,
            marginBottom: Platform.OS == "ios" ? -30 : 0,
            marginLeft: Platform.OS == "ios" ? 10 : 20,
            borderWidth: 0,
            alignSelf: "center",
          }}
          content={
            <View>
              {/* <Text style={{
                    color: "#fff", fontSize: 10, fontWeight: '700',
                    fontFamily: FONTS.PoppinsMedium,padding:8
                }}>{t(label)}</Text> */}
              <Text
                style={{
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: Platform.OS == "web" ? "normal" : "700",
                  fontFamily: FONTS.PoppinsMedium,
                  padding: 8,
                  textAlign: "center",
                }}
              >
                {definition}
              </Text>
            </View>
          }
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 0,
            }}
          >
            <Text allowFontScaling={false} style={styles.txt5}>
              {t(label)}{" "}
            </Text>
            {/* <Image source={Platform.OS == "web" ?INFO :Exclamation} style={{ width: 11, height: 11}} /> */}
            <View
              style={{
                borderRadius: 100,
                borderWidth: 1,
                borderColor: "black",
                padding: 0,
                width: 11,
                height: 11,
                justifyContent: "center",
                alignItems: "center",
                marginLeft: Platform.OS == "ios" ? 3 : 0,
              }}
            >
              <Text style={{ color: "black", fontSize: 7, fontWeight: "bold" }}>
                i
              </Text>
            </View>
          </View>
          {/* <Text allowFontScaling={false} style={styles.txt5}>{t(label)} <Image source={INFO} style={{ width: 11, height: 11, resizeMode: 'contain', }} /></Text> */}
        </Popable>
      </View>
      <View style={styles.View3}>
        <Progress.Bar
          progress={progress}
          width={width > 1000 ? 400 : width / 2 - 10}
          height={Platform.OS === "web" ? 26 : 18}
          color={color}
          borderRadius={0}
          borderWidth={0}
          unfilledColor={COLORS.primarybordercolor}
        />
      </View>
    </View>
  );

  return (
    <View style={styles.container}>
      <StatusBar
        animated={true}
        barStyle={"light-content"}
        backgroundColor={"transparent"}
        translucent={true}
      />
      <View style={styles.View1}>
        <View style={styles.headerView}>
          <Pressable
            onPress={() => {
              if (route.params.previousRoute) {
                navigation.navigate(route.params.previousRoute);
              } else {
                navigation.goBack();
              }
            }}
            style={styles.img}
          >
            <Image style={{ height: 24, width: 24 }} source={LeftArrow} />
          </Pressable>
          <Text
            allowFontScaling={false}
            style={[styles.title, { width: "70%" }]}
          >
            {t("Health Support Criteria and Achievements")}
          </Text>
        </View>
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        bounces={false}
        style={{
          marginBottom: !isLandscape
            ? Platform.OS === "ios"
              ? 85
              : Platform.OS == "android" || "web"
                ? 55
                : 0
            : 0,
        }}
      >
        {item.medal_status == "3" ? (
          <Image source={icon} style={styles.flagimg} />
        ) : item.medal_status == "1" ? (
          <Image source={Platinum} style={styles.flagimg} />
        ) : item.medal_status == "2" ? (
          <Image source={Diamond} style={styles.flagimg} />
        ) : item.medal_status == "4" ? (
          <Image source={Silver} style={styles.flagimg} />
        ) : null}
        <View style={[styles.imgView]}>
          <View style={styles.textContainer}>
            <Text style={styles.txt2}>
              {item?.countries_iso_code && (
                <View>
                  <CountryFlag
                    isoCode={item.countries_iso_code}
                    size={20}
                    style={{ marginTop: 4 }}
                  />
                </View>

              )}
            </Text>
            <Text
              // allowFontScaling={false}
              style={[styles.txt2, { marginLeft: 3 }]}
            >
              {" "}
              {item.company_name}
            </Text>
            <Text
              // allowFontScaling={false}
              style={[styles.txt2, { marginLeft: 4 }]}
            >
              {item.product_name}
            </Text>
          </View>
        </View>
        {Platform.OS == "web" && width <= 450 ? (
          <Text
            style={{
              textAlign: "center",
              fontSize: 10,
              marginBottom: 10,
              color: "gray",
            }}
          >
            (best viewed in landscape mode)
          </Text>
        ) : null}
        <View style={{ alignSelf: "center", borderWidth: 0, minWidth: "50%" }}>
          <View style={{ flexDirection: "row", marginBottom: 7 }}>
            <View style={styles.View2}>
              <Text allowFontScaling={false} style={styles.txt5}></Text>
            </View>
            <View style={styles.View3}>
              <View
                style={{
                  width: width > 1000 ? 400 : width / 2 - 10,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderWidth: 0,
                }}
              >
                <Text style={styles.txt4}>{t("LOW")}</Text>
                <Text style={styles.txt4}>{t("HIGH")}</Text>
              </View>
            </View>
          </View>
          {progressBarData.map((data, index) => (
            <ProgressBarView
              key={index}
              label={data.label}
              progress={data.progress}
              color={data.color}
              definition={data.definition}
            />
          ))}
        </View>
        <View
          style={{
            marginTop: 13,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            marginBottom: 20,
          }}
        >
          <Text
            allowFontScaling={false}
            style={{
              marginRight: 5,
              fontSize: Platform.OS === "web" ? 17 : 12,
              fontWeight: "500",
              fontFamily: FONTS.PoppinsMedium,
            }}
          >
            {t("Health Support Profile Rating")}
          </Text>
          <StarRating
            rating={item?.star_rating}
            onStarPress={handleStarPress}
            customStarImage={customStarImage}
            height={Platform.OS === "web" ? 15 : 11}
            width={Platform.OS === "web" ? 15 : 11}
          />
        </View>
      </ScrollView>
    </View>
  );
};
export default Graph;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#DBEEFE",
  },
  View1: {
    width: "100%",
    height: Platform.OS === "web" ? 100 : 185,
    backgroundColor: COLORS.primary,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  headerView: {
    width: "100%",
    flexDirection: "row",
    marginTop: Platform.OS === "web" ? 30 : 70,
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    height: 24,
    width: 24,
    position: "absolute",
    left: 20,
    top: 7,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    // fontSize: 12,
    fontSize: 22,
    color: COLORS.white,
    fontFamily: "Poppins-SemiBold",
    fontWeight: Platform.OS === "web" ? 0 : "600",
  },
  SearchBarView: {
    backgroundColor: "white",
    width: Platform.OS === "web" ? 600 : width - 32,
    alignSelf: "center",
    marginTop: Platform.OS === "web" ? 11 : 11,
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 10,
    paddingLeft: 30,
  },
  txt1: {
    fontFamily: "Poppins-SemiBold",
    fontWeight: Platform.OS === "web" ? 0 : "600",
    fontSize: 17,
    color: COLORS.primary,
    paddingLeft: 20,
    paddingTop: 10,
    marginBottom: 10,
  },
  txt2: {
    marginLeft: 15,
    fontSize: Platform.OS === "web" ? 19 : 13.5,
    fontWeight: "500",
    fontFamily: FONTS.PoppinsMedium,
    color: "black",
  },
  imgView: {
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 15,
    maxWidth: width - 20,
    minHeight: 45,
  },
  textContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    maxHeight: 40
  },

  View2: {
    width: width / 2 - 10,
    maxWidth: 300,
    alignItems: "flex-end",
    paddingRight: 10,
    marginLeft: 10,
  },
  View3: {
    alignItems: "center",
    paddingRight: 3,
    maxWidth: 450,
    alignItems: "flex-start",
  },
  nestedView: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: Platform.OS === "web" ? "56%" : "60%",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    paddingRight: Platform.OS === "web" ? "29%" : "5%",
    marginBottom: 2,
    marginTop: 10,
    paddingLeft: Platform.OS === "web" ? -6 : 0,
    marginRight: Platform.OS === "web" ? -20 : 0,
    borderWidth: 0,
  },
  nestedView2: {
    marginTop: 10,
  },
  txt3: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 10,
    fontWeight: "500",
    color: COLORS.white,
  },
  txt4: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: Platform.OS === "web" ? 15 : 10,
    fontWeight: "500",
    color: "black",
  },
  txt5: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: Platform.OS == "web" ? 12 : 10,
    textAlign: "right",
    color: "#000000e3",
    fontWeight: Platform.OS == "web" ? "normal" : "600",
    textTransform: "capitalize",
  },
  flagimg: {
    width: Platform.OS === "web" ? 177 : 155,
    height: Platform.OS === "web" ? 144 : 122,
    alignSelf: "center",
    marginTop: 25,
  },
});
