import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Dimensions,
  TouchableOpacity,
  StatusBar,
  ActivityIndicator,
  Alert,
} from "react-native";
import React, { useState, useEffect, useContext } from "react";
import { API, COLORS, FONTS } from "../../../utils";
import { Pressable } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Toast } from "react-native-toast-notifications";
const { height, width } = Dimensions.get("window");
import { useTranslation } from "react-i18next";
import { WebView } from "react-native-webview";
import { DataContext } from "../../components/DataContext";
import { useNavigation, useRoute } from "@react-navigation/native";

export default function UpgradeBulksubscription() {
  const [urlActive, setUrlActive] = useState(false);
  const [selectedStripId, setSelectedStripeId] = useState("");
  const { data } = useContext(DataContext);
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [planValue, setPlanValue] = useState(1);
  const LeftArrow = require("../../assets/images/whiteleftarrow.png");
  const select = require("../../assets/images/select.png");
  const unselect = require("../../assets/images/unselect.png");
  const whiteselectbtn = require("../../assets/images/whiteselectbtn.png");
  const bluecircle = require("../../assets/images/bluecircle.png");
  const graycircle = require("../../assets/images/graycircle.png");
  const navigation = useNavigation();
  const route = useRoute();
  const [bulkData, setBulkData] = useState([]);
  const [paymentUrl, setPaymentUrl] = useState("");
  const currentPlan = route?.params?.Plan.Amount;
  const [planPrice, setPlanPrice] = useState("$3.9");
  const [planid, setPlanId] = useState();

  useEffect(() => {
    if (data.status == "200") {
      setBulkData(data?.data);
      setPlanPrice(data?.data[6]?.plan_price);
      setPlanId(data?.data[0]?.id);
      setPlanValue(1), setPlanPrice(data?.data[0]?.plan_price);
    } else {
      console.log("no data found");
    }
  }, [data]);
  function notifyMessage(msg) {
    if (Platform.OS === "ios") {
      Alert.alert(msg);
    } else if (Platform.OS === "android") {
      Toast.show(msg, {
        type: "normal",
        placement: "bottom",
        duration: 1200,
        offset: 0,
        animationType: "zoom-in",
      });
    } else {
      Toast.show(msg, {
        type: "normal",
        placement: "bottom",
        duration: 1200,
        offset: 0,
        animationType: "zoom-in",
      });
    }
  }
  const handleSubscription = async () => {
    setIsActive(true);
    const id = await AsyncStorage.getItem("id");
    const sessionKey = await AsyncStorage.getItem("key");

    fetch(
      `${API.baseurl}${API.upgradesubscription}?newPlanId=${selectedStripId}`,
      {
        method: "GET",
        headers: {
          id: id,
          session_key: sessionKey,
          Authorization: "Basic YWRtaW46MTIzNA==",
          Cookie: "ci_session=2b19bf32b9a2f50974f1700c1479e593ec66dae2",
        },
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        notifyMessage(result.message);
        if (result.status === 200) {
          setIsActive(false);
          notifyMessage("Plan Upgraded");
          navigation.navigate("MySubscripion");
        }
      })
      .catch(() => {
        notifyMessage("An error occurred. Please try again."),
          setIsActive(false);
      });
  };
  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1, backgroundColor: COLORS.primary }}>
        <StatusBar
          animated={true}
          barStyle={"light-content"}
          backgroundColor={"transparent"}
          translucent={true}
        />
        {urlActive === false ? (
          <>
            <View style={styles.headerView}>
              <Pressable onPress={() => navigation.goBack()} style={styles.img}>
                <Image style={{ height: 24, width: 24 }} source={LeftArrow} />
              </Pressable>

              <Text style={styles.title} allowFontScaling={false}>
                {t("Upgrade Subscription")}
              </Text>
            </View>
            <View style={styles.View2}>
              <View
                style={{
                  width: Platform.OS === "web" ? "50%" : width - 40,
                  maxWidth: 436,
                  minWidth: 325,
                  alignSelf: Platform.OS === "web" ? "center" : "center",
                }}
              >
                <Text style={styles.txt1} 
                  allowFontScaling={false}
                
                >
                  {t(
                    "Explore a variety of plans designed for your unique needs. Subscribe today and let's craft a healthier future together"
                  )}
                </Text>
              </View>
              <Pressable
                onPress={() => {
                  setPlanValue(1), setPlanPrice(bulkData[0]?.plan_price);
                }}
                style={[
                  styles.View3,
                  {
                    backgroundColor: planValue === 1 ? COLORS.primary : null,
                    borderStyle: planValue === 1 ? null : "dashed",
                    borderWidth: planValue === 1 ? null : 1,
                    padding: 20,
                  },
                ]}
              >
                <View style={{ width: "50%", paddingLeft: 10 }}>
                  <Text
                  allowFontScaling={false}

                    style={{
                      fontFamily: "Poppins-SemiBold",
                      fontWeight: Platform.OS === "web" ? 0 : "600",
                      fontSize: 16,
                      lineHeight: 24,
                      color: planValue === 1 ? "white" : COLORS.primary,
                    }}
                  >
                    {bulkData[0]?.plan_name}
                  </Text>
                  {currentPlan != bulkData[0]?.plan_price ? (
                    <Pressable
                      onPress={() => {
                        if (planValue == 1) {
                          if (bulkData && bulkData.length > 0 && bulkData[0]) {
                            setPlanPrice(bulkData[0]?.plan_price);
                            setPlanId(bulkData[0]?.id);
                            setSelectedStripeId(bulkData[0]?.stripe_plan_id);
                          }
                        }
                      }}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 7,
                        width: Platform.OS === "web" ? 180 : null,
                      }}
                    >
                      <Image
                        resizeMode="contain"
                        source={
                          planValue !== 1
                            ? graycircle
                            : planPrice === bulkData[0]?.plan_price
                            ? select
                            : unselect
                        }
                        style={{ height: 14, width: 14, marginBottom: 2 }}
                      />
                      <Text
                  allowFontScaling={false}

                        style={{
                          fontSize: 14,
                          color: planValue === 1 ? "white" : COLORS.primary,
                          fontWeight: "500",
                          fontFamily: FONTS.PoppinsRegular,
                          marginLeft: 9,
                          letterSpacing: 0.1,
                        }}
                      >
                        {t("Monthly")} : ${bulkData[0]?.plan_price}
                      </Text>
                    </Pressable>
                  ) : null}

                  {currentPlan != bulkData[2]?.plan_price ? (
                    <Pressable
                      onPress={() => {
                        if (planValue == 1) {
                          setPlanPrice(bulkData[2]?.plan_price),
                            setPlanId(bulkData[2]?.id),
                            setSelectedStripeId(bulkData[2]?.stripe_plan_id);
                        }
                      }}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 2,
                        width: Platform.OS === "web" ? 180 : null,
                      }}
                    >
                      <Image
                        resizeMode="contain"
                        source={
                          planValue !== 1
                            ? graycircle
                            : planPrice === bulkData[2]?.plan_price
                            ? select
                            : unselect
                        }
                        style={{ height: 14, width: 14, marginBottom: 2 }}
                      />
                      <Text
                  allowFontScaling={false}

                        style={{
                          fontSize: 14,
                          color: planValue === 1 ? "white" : COLORS.primary,
                          fontWeight: "500",
                          fontFamily: FONTS.PoppinsRegular,
                          marginLeft: 9,
                        }}
                      >
                        {t("Annual")} : ${bulkData[2]?.plan_price}
                      </Text>
                    </Pressable>
                  ) : null}
                </View>
                <View style={{ width: "50%", justifyContent: "center" }}>
                  <Image
                    resizeMode="contain"
                    source={planValue === 1 ? whiteselectbtn : bluecircle}
                    style={{
                      height: 27,
                      width: 27,
                      alignSelf: "flex-end",
                      marginRight: 9.5,
                    }}
                  />
                </View>
              </Pressable>
              <Pressable
                onPress={() => {
                  setPlanValue(2), setPlanPrice(bulkData[3]?.plan_price);
                }}
                style={[
                  styles.View3,
                  {
                    marginTop: 16,
                    backgroundColor: planValue === 2 ? COLORS.primary : null,
                    borderStyle: planValue === 2 ? null : "dashed",
                    borderWidth: planValue === 2 ? null : 1,
                    padding: 20,
                  },
                ]}
              >
                <View style={{ width: "50%", paddingLeft: 10 }}>
                  <Text
                  allowFontScaling={false}

                    style={{
                      fontFamily: "Poppins-SemiBold",
                      fontWeight: Platform.OS === "web" ? 0 : "600",
                      fontSize: 16,
                      lineHeight: 24,
                      color: planValue === 2 ? "white" : COLORS.primary,
                    }}
                  >
                    {bulkData[3]?.plan_name}
                  </Text>

                  {currentPlan != bulkData[3]?.plan_price ? (
                    <Pressable
                      onPress={() => {
                        if (planValue == 2) {
                          setPlanPrice(bulkData[3]?.plan_price),
                            setPlanId(bulkData[3]?.id);
                          setSelectedStripeId(bulkData[3]?.stripe_plan_id);
                        }
                      }}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 7,
                        width: Platform.OS === "web" ? 180 : null,
                      }}
                    >
                      <Image
                        resizeMode="contain"
                        source={
                          planValue !== 2
                            ? graycircle
                            : planPrice === bulkData[3]?.plan_price
                            ? select
                            : unselect
                        }
                        style={{ height: 14, width: 14, marginBottom: 2 }}
                      />
                      <Text
                  allowFontScaling={false}

                        style={{
                          fontSize: 14,
                          color: planValue === 2 ? "white" : COLORS.primary,
                          fontWeight: "500",
                          fontFamily: FONTS.PoppinsRegular,
                          marginLeft: 9,
                          letterSpacing: 0.1,
                        }}
                      >
                        {t("Monthly")} : ${bulkData[3]?.plan_price}
                      </Text>
                    </Pressable>
                  ) : null}

                  {currentPlan != bulkData[5]?.plan_price ? (
                    <Pressable
                      onPress={() => {
                        if (planValue == 2) {
                          setPlanPrice(bulkData[5]?.plan_price),
                            setPlanId(bulkData[5]?.id);
                          setSelectedStripeId(bulkData[5]?.stripe_plan_id);
                        }
                      }}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 2,
                        width: Platform.OS === "web" ? 180 : null,
                      }}
                    >
                      <Image
                        resizeMode="contain"
                        source={
                          planValue !== 2
                            ? graycircle
                            : planPrice === bulkData[5]?.plan_price
                            ? select
                            : unselect
                        }
                        style={{ height: 14, width: 14, marginBottom: 2 }}
                      />
                      <Text
                  allowFontScaling={false}

                        style={{
                          fontSize: 14,
                          color: planValue === 2 ? "white" : COLORS.primary,
                          fontWeight: "500",
                          fontFamily: FONTS.PoppinsRegular,
                          marginLeft: 9,
                        }}
                      >
                        {t("Annual")} : ${bulkData[5]?.plan_price}
                      </Text>
                    </Pressable>
                  ) : null}
                </View>
                <View style={{ width: "50%", justifyContent: "center" }}>
                  <Image
                    resizeMode="contain"
                    source={planValue === 2 ? whiteselectbtn : bluecircle}
                    style={{
                      height: 27,
                      width: 27,
                      alignSelf: "flex-end",
                      marginRight: 9.5,
                    }}
                  />
                </View>
              </Pressable>
              <Pressable
                onPress={() => {
                  setPlanValue(3), setPlanPrice(bulkData[6]?.plan_price);
                }}
                style={[
                  styles.View4,
                  {
                    marginTop: 16,
                    backgroundColor: planValue === 3 ? COLORS.primary : null,
                    borderStyle: planValue === 3 ? null : "dashed",
                    borderWidth: planValue === 3 ? null : 1,
                    borderColor: COLORS.primary,
                  },
                ]}
              >
                <View style={{ width: "50%", paddingLeft: 10 }}>
                  <Text
                  allowFontScaling={false}

                    style={{
                      fontFamily: "Poppins-SemiBold",
                      fontWeight: Platform.OS === "web" ? 0 : "600",
                      fontSize: 16,
                      lineHeight: 24,
                      color: planValue === 3 ? "white" : COLORS.primary,
                    }}
                  >
                    {bulkData[6]?.plan_name}
                  </Text>
                  {currentPlan != bulkData[6]?.plan_price ? (
                    <Pressable
                      onPress={() => {
                        if (planValue == 3) {
                          setPlanPrice(bulkData[6]?.plan_price),
                            setPlanId(bulkData[6]?.id);
                          setSelectedStripeId(bulkData[6]?.stripe_plan_id);
                        }
                      }}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 7,
                        width: Platform.OS === "web" ? 180 : null,
                      }}
                    >
                      <Image
                        resizeMode="contain"
                        source={
                          planValue !== 3
                            ? graycircle
                            : planPrice === bulkData[6]?.plan_price
                            ? select
                            : unselect
                        }
                        style={{ height: 14, width: 14, marginBottom: 2 }}
                      />
                      <Text
                  allowFontScaling={false}

                        style={{
                          fontSize: 14,
                          color: planValue === 3 ? "white" : COLORS.primary,
                          fontWeight: "500",
                          fontFamily: FONTS.PoppinsRegular,
                          marginLeft: 9,
                          letterSpacing: 0.1,
                        }}
                      >
                        {t("Monthly")} : ${bulkData[6]?.plan_price}
                      </Text>
                    </Pressable>
                  ) : null}

                  {currentPlan != bulkData[8]?.plan_price ? (
                    <Pressable
                      onPress={() => {
                        if (planValue == 3) {
                          setPlanPrice(bulkData[8]?.plan_price),
                            setPlanId(bulkData[8]?.id);
                          setSelectedStripeId(bulkData[8]?.stripe_plan_id);
                        }
                      }}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 2,
                        width: Platform.OS === "web" ? 180 : null,
                      }}
                    >
                      <Image
                        resizeMode="contain"
                        source={
                          planValue !== 3
                            ? graycircle
                            : planPrice === bulkData[8]?.plan_price
                            ? select
                            : unselect
                        }
                        style={{ height: 14, width: 14, marginBottom: 2 }}
                      />
                      <Text
                  allowFontScaling={false}

                        style={{
                          fontSize: 14,
                          color: planValue === 3 ? "white" : COLORS.primary,
                          fontWeight: "500",
                          fontFamily: FONTS.PoppinsRegular,
                          marginLeft: 9,
                        }}
                      >
                        {t("Annual")} : ${bulkData[8]?.plan_price}
                      </Text>
                    </Pressable>
                  ) : null}
                </View>
                <View style={{ width: "50%", justifyContent: "center" }}>
                  <Image
                    resizeMode="contain"
                    source={planValue === 3 ? whiteselectbtn : bluecircle}
                    style={{
                      height: 27,
                      width: 27,
                      alignSelf: "flex-end",
                      marginRight: 9.5,
                    }}
                  />
                </View>
              </Pressable>
              {isActive === true ? (
                <ActivityIndicator
                  size={"large"}
                  style={{ marginTop: 10, marginBottom: 10 }}
                  color={COLORS.primary}
                />
              ) : (
                <ActivityIndicator
                  size={"large"}
                  style={{ marginTop: 10, marginBottom: 10 }}
                  color={COLORS.white}
                />
              )}
              <TouchableOpacity
                onPress={() => handleSubscription()}
                activeOpacity={0.8}
                style={[
                  styles.Signinbtn,
                  { marginTop: 0, alignSelf: "center", marginBottom: 40 },
                ]}
              >
                <Text 
                
                  // allowFontScaling={false}
                
                style={styles.txt5}>{t("Upgrade Now")}</Text>
              </TouchableOpacity>
            </View>
          </>
        ) : (
          <WebView
            style={{ flex: 1, marginTop: 40 }}
            source={{ uri: paymentUrl }}
          />
        )}
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
    padding: 20,
    alignItems: "center",
  },
  headerView: {
    width: "100%",
    flexDirection: "row",
    marginTop: Platform.OS === "web" ? 20 : 60,
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    height: 24,
    width: 24,
    position: "absolute",
    left: 20,
    top: 7,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 22,
    color: COLORS.white,
    fontFamily: "Poppins-SemiBold",
    fontWeight: Platform.OS === "web" ? 0 : "600",
  },
  View2: {
    height: height * 2,
    backgroundColor: "white",
    marginTop: 50,
    borderTopRightRadius: 32,
    borderTopLeftRadius: 32,
    paddingRight: 20,
    paddingLeft: 20,
  },
  txt1: {
    marginTop: Platform.OS === "web" ? 10 : 30,
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.black,
    textAlign: "center",
  },
  View3: {
    paddingLeft: 10,
    paddingRight: 10,
    height: "6.5%",
    borderRadius: 6,
    marginTop: '5%',
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: COLORS.primaryLite,
    width: Platform.OS === "web" ? "50%" : width - 40,
    maxWidth: 436,
    minWidth: 325,
    alignSelf: Platform.OS === "web" ? "center" : "center",
    height: 130,
    borderStyle: "dashed",
  },
  txt2: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "400",
    color: COLORS.primary,
  },
  txt3: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "400",
    color: COLORS.primary,
  },
  txt4: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "400",
    color: COLORS.primarygraycolor,
  },
  View4: {
    paddingLeft: 10,
    paddingRight: 10,
    height: "6.5%",
    borderRadius: 6,
    marginTop: 50,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: COLORS.primaryLite,
    width: Platform.OS === "web" ? "50%" : width - 40,
    maxWidth: 436,
    minWidth: 325,
    alignSelf: Platform.OS === "web" ? "center" : "center",
    height: 130,
  },
  Signinbtn: {
    marginTop: Platform.OS === "web" ? 10 : 85,
    width: Platform.OS === "web" ? "50%" : width - 92,
    maxWidth: 436,
    minWidth: 325,
    borderRadius: 46,
    height: 50,
    backgroundColor: COLORS.primary,
    justifyContent: "center",
    alignItems: "center",
  },
  txt5: {
    fontFamily: FONTS.PoppinsMedium,
    fontSize: 14,
    fontWeight: "500",
    color: COLORS.white,
  },
  txt9: {
    marginTop: 33,
    textDecorationLine: "underline",
    fontFamily: FONTS.PoppinsMedium,
    fontWeight: "300",
    fontSize: 14,
    color: COLORS.primary,
    textAlign: "center",
  },
});
