
import { ColorValue } from "react-native";
import Svg, { G, Path } from "react-native-svg"
interface StyledSvgProps {
    color?: ColorValue
}
export const FAQIcon = (props: StyledSvgProps) => {
    const { color, ...otherProps } = props;
    return (
        <Svg width="256px" height="256px" viewBox="0 0 24 24" fill={color || "#D7D7D7"} {...otherProps}><G id="SVGRepo_bgCarrier" stroke-width="0"></G><G id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></G><G id="SVGRepo_iconCarrier"><Path d="M12,22A10,10,0,1,0,2,12,10,10,0,0,0,12,22Zm0-2a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,20ZM8,8.994a3.907,3.907,0,0,1,2.319-3.645,4.061,4.061,0,0,1,3.889.316,4,4,0,0,1,.294,6.456,3.972,3.972,0,0,0-1.411,2.114,1,1,0,0,1-1.944-.47,5.908,5.908,0,0,1,2.1-3.2,2,2,0,0,0-.146-3.23,2.06,2.06,0,0,0-2.006-.14,1.937,1.937,0,0,0-1.1,1.8A1,1,0,0,1,8,9Z"></Path></G></Svg>
    )
}


