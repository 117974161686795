// import React, { createContext, useState, useEffect } from 'react';
// import { Toast } from "react-native-toast-notifications";
// import { API } from '../../utils';
// import AsyncStorage from '@react-native-async-storage/async-storage';

// export const DataContext = createContext();

// export const DataProvider = ({ children }) => {
//     const [data, setData] = useState([]);
//     const [data2, setData2] = useState([]);
//     const [data3, setData3] = useState([]);
//     const [data4, setData4] = useState([]);
//     useEffect(() => {
//         handlegetData()
//         handleSubscirption()
//         handleBulkSubscirption()
//     }, [])

//     const handlegetData = async () => {
//         try {
//             var myHeaders = new Headers();
//             myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");
//             var formdata = new FormData();
//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 redirect: 'follow',
//                 // mode: ' // mode: 'no-cors''
//             };
//             const response = await fetch(API.baseurl + API.nutriproduct, requestOptions);
//             const result = await response.json();
//             if (result?.status === 200) {
//                 setData2(result?.data);

//             } else if (result?.status === 400) {
//                 Toast.show(result?.message);
//             } else {
//                 Toast.show(result?.message);
//             }
//         } catch (error) {
//             console.log('Error:', error);
//         }
//     };
//     function handleSubscirption(value, id) {
//         var myHeaders = new Headers();
//         myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");

//         var requestOptions = {
//             method: 'GET',
//             headers: myHeaders,
//             redirect: 'follow',
//             // mode: ' // mode: 'no-cors''
//         };

//         fetch(API.baseurl + API.bulksubscription + "?type=1", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result?.status === 200) {
//                     setData3(result);
//                 }
//                 else {
//                     Toast.show(result?.message)

//                 }
//             })
//             .catch(error => { console.log('error', error), Toast.show(error) });
//     }
//     function handleBulkSubscirption(value, id) {
//         console.log(API.baseurl + API.bulksubscription)
//         var myHeaders = new Headers();
//         // myHeaders.append("id", id);
//         // myHeaders.append("session_key", value);
//         myHeaders.append("Authorization", "Basic YWRtaW46MTIzNA==");

//         var requestOptions = {
//             method: 'GET',
//             headers: myHeaders,
//             redirect: 'follow',
//             // mode: ' // mode: 'no-cors''
//         };

//         fetch(API.baseurl + API.bulksubscription + "?type=2", requestOptions)
//             .then(response => response.json())
//             .then(result => {

//                 if (result?.status === 200) {
//                     setData(result);
//                 }
//                 else {
//                     Toast.show(result?.message)

//                 }
//             })
//             .catch(error => { console.log('error', error), Toast.show(error) });
//     }

//     return (
//         <DataContext.Provider value={{ data2, data, data3 }}>
//             {children}
//         </DataContext.Provider>
//     );
// };

import React, { createContext, useState, useEffect } from "react";
import { Toast } from "react-native-toast-notifications";
import { API } from "../../utils";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    handleGetData();
    handleSubscription();
    handleBulkSubscription();
  }, []);

  const handleGetData = async () => {
    try {
      const response = await fetch(API.baseurl + API.nutriproduct, {
        method: "POST",
        headers: {
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
        redirect: "follow",
      });
      const result = await response.json();
      if (result?.status === 200) {
        setData2(result?.data);
      } else {
        // Toast.show(result?.message || "An error occurred");
        setError(result?.message || "An error occurred");
      }
    } catch (error) {
      console.log("Error:", error);
      //   Toast.show("An error occurred while fetching data");
      setError("An error occurred while fetching data");
    }
  };

  const handleSubscription = async () => {
    try {
      const response = await fetch(
        API.baseurl + API.bulksubscription + "?type=1",
        {
          method: "GET",
          headers: {
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
          redirect: "follow",
        }
      );
      const result = await response.json();
      if (result?.status === 200) {
        setData3(result);
      } else {
        // Toast.show(result?.message || "An error occurred");
        setError(result?.message || "An error occurred");
      }
    } catch (error) {
      console.log("Error:", error);
      //   Toast.show("An error occurred while fetching subscription data");
      setError("An error occurred while fetching subscription data");
    }
  };

  const handleBulkSubscription = async () => {
    try {
      const response = await fetch(
        API.baseurl + API.bulksubscription + "?type=2",
        {
          method: "GET",
          headers: {
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
          redirect: "follow",
        }
      );
      const result = await response.json();
      if (result?.status === 200) {
        setData(result);
      } else {
        // Toast.show(result?.message || "An error occurred");
        setError(result?.message || "An error occurred");
      }
    } catch (error) {
      console.log("Error:", error);
      //   Toast.show("An error occurred while fetching bulk subscription data");
      setError("An error occurred while fetching bulk subscription data");
    }
  };

  return (
    <DataContext.Provider value={{ data2, data, data3, error }}>
      {children}
    </DataContext.Provider>
  );
};
