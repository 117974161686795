import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  Image,
  StatusBar,
  TouchableOpacity,
  StyleSheet,
  SafeAreaView,
  Platform,
  Dimensions,
} from "react-native";
import { COLORS, FONTS } from "../../../utils";
import { ReactReader } from "react-reader";
import { Reader, useReader } from "@epubjs-react-native/core";
import { useFileSystem } from "@epubjs-react-native/expo-file-system";
import { ReaderProvider } from "@epubjs-react-native/core";
const { width } = Dimensions.get("window");
export default function Pdfview({ navigation, route }) {
  let v = JSON.stringify(route?.params?.item.title);
  const [pdfUrl, setPdfUrl] = useState("");
  const rendition = useRef(undefined);
  const [location, setLocation] = useState(0);
  const { goToLocation, isLoading, error } = useReader();
  useEffect(() => {
    if (error) {
      console.error("EPUB Reader Error:", error);
    }
  }, [error]);
  useEffect(() => {
    const { bookname, item, pdf } = route?.params;
    setPdfUrl(pdf);

    const loadingTimeout = setTimeout(() => {
    }, 20000);

    return () => clearTimeout(loadingTimeout);
  }, []);
  const applyStyles = (rendition) => {
    // Set custom font size and other styles here
    rendition.themes.default({
      body: {
        "font-size": "90%", // Reduce the font size
        "line-height": "1.5", // Adjust line height for better readability
        color: "black", // Ensure text color is readable
        margin: "0 !important", // Remove default margins
        "padding-left": "0 !important",
        "padding-right": "0 !important",
        padding: "0",
      },
      table: {
        "font-size": width < 550 ? "6px" : "15px",
      },
      ".halftitle-big, .titlepage-big": {
        "font-size": "2.8em !important",
      },
    });

    // You can also add custom classes or ids to target specific elements within the EPUB
    rendition.themes.register({
      custom: {
        "body.custom": {
          "font-size": "80%", // Different font size for elements with this class
          color: "#333", // Darker text color
          "padding-left": "0 !important",
          "padding-right": "0 !important",
        },
      },
    });
    rendition.themes.select("default"); // Apply the default theme
  };
  return (
    <View style={{ flex: 1 }}>
      <StatusBar
        animated
        barStyle={"light-content"}
        backgroundColor={"transparent"}
        translucent
      />
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Image
            source={require("../../assets/images/whiteleftarrow.png")}
            style={styles.backButton}
          />
        </TouchableOpacity>
        <View style={styles.titleContainer}>
          <Text style={styles.titleText} numberOfLines={4}>
            {v}
          </Text>
        </View>
      </View>
      {Platform.OS === "web" ? (
        <View style={styles.webReaderContainer}>
          <ReactReader
            epubOptions={{
              allowPopups: true,
              allowScriptedContent: true,
            }}
            url={pdfUrl}
            location={location}
            locationChanged={(epubcfi) => setLocation(epubcfi)}
            getRendition={(_rendition) => {
              rendition.current = _rendition;
              applyStyles(rendition.current);
            }}
          />
        </View>
      ) : (
        <SafeAreaView style={{ flex: 1 }}>
          <ReaderProvider>
            <Reader
              allowPopups={true}
              src={pdfUrl}
              fileSystem={useFileSystem}
            />
          </ReaderProvider>
        </SafeAreaView>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  header: {
    width: "100%",
    backgroundColor: COLORS.primary,
    paddingTop: "5%",
    paddingLeft: 20,
    paddingBottom: 8,
    minHeight: 120,
    paddingTop: 50,
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
  },
  backButton: {
    height: 23,
    width: 23,
  },
  loadingContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  titleContainer: {
    width: Platform.OS === "web" ? "90%" : "85%",
    justifyContent: "center",
    alignItems: "center",
  },
  titleText: {
    color: "white",
    fontFamily: FONTS.PoppinsSemiBold,
    fontWeight: Platform.OS === "web" ? "normal" : "600",
    fontSize: 14,
    marginLeft: 5,
    textAlign: "center",
  },
  webReaderContainer: {
    flex: 1,
    height: "100vh",
  },
});
